export default {
  Properties: {
    TotalRecord: '0',
    TotalPage: '0',
    PageNumber: '1',
  },
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/milestones/incomplete?dueDate=2021-12-29',
      Rel: 'self',
    },
  ],
};
