import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { formatUrl, isUrlPathMatched } from 'src/utils/url';
import { IBookmarks } from 'src/apis/types/bookmarksAPI';

const BOOKMARKS_URL_PATH = '/api/v2/bookmarks';
export const BOOKMARKS_MSW_STATUS_KEY = 'bookmarksAPI';

export const BOOKMARKS_KEY = 'BOOKMARKS';

const bookmarksFetch = (): Promise<IBookmarks> =>
  getFetch({ path: BOOKMARKS_URL_PATH, key: BOOKMARKS_MSW_STATUS_KEY });

export const useIsBookmarked = (currentUrl: string) => {
  const { data, ...restProps } = useQuery([BOOKMARKS_KEY], bookmarksFetch);

  const { properties, actions } =
    data?.entities?.find(({ properties: { url = '' } = {} }) =>
      isUrlPathMatched({ currentUrl, originUrl: url }),
    ) || {};

  const { href: deleteBookmarkUrl = '' } = actions?.find(({ method }) => method === 'Delete') || {};

  return {
    isBookmarked: !!properties || false,
    deleteBookmarkUrl,
    ...restProps,
  };
};

export default () => {
  const { data, ...restProps } = useQuery([BOOKMARKS_KEY], bookmarksFetch);
  return {
    bookmarks:
      data?.entities?.map(({ properties: { url, ...restProperties } = {} }) => ({
        ...restProperties,
        url: formatUrl(url),
      })) || [],
    ...restProps,
  };
};
