import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';
import { forwardRef } from 'react';

type IconButtonRef = HTMLButtonElement;

export const IconButton = forwardRef<IconButtonRef, MuiIconButtonProps>(({ ...rest }, ref) => (
  <MuiIconButton ref={ref} {...rest} />
));

export type IconButtonProps = MuiIconButtonProps;
