import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IMigrationStatusResponse } from 'src/apis/types/resourcePlannerAPI';

const MIGRATION_STATUS_URL_PATH = '/api/v2/resource-planner/migration-status';
export const MIGRATION_STATUS_MSW_STATUS_KEY = 'GetMigrationStatus';

export const MIGRATION_STATUS_KEY = 'MIGRATION_STATUS';

const resourcePlannerFetch = (): Promise<IMigrationStatusResponse> =>
  getFetch({ path: MIGRATION_STATUS_URL_PATH, key: MIGRATION_STATUS_MSW_STATUS_KEY });

const useGetMigrationStatusQuery = () =>
  useQuery([MIGRATION_STATUS_KEY], resourcePlannerFetch, { refetchOnMount: false });

export const useGetMigrationStatus = () => {
  const { data, ...restProps } = useGetMigrationStatusQuery();

  return {
    tenantId: data?.model?.properties.tenantId ?? '',
    isDataSynchronizationEnabled: data?.model?.properties.isDataSynchronizationEnabled ?? false,
    isResourceMigrated: data?.model?.properties.isResourceMigrated ?? false,
    isProjectMigrated: data?.model?.properties.isProjectMigrated ?? false,
    canTriggerMigration: data?.model?.properties.canTriggerMigration ?? false,
    canAddResourcePlan: data?.model?.properties.canAddResourcePlan ?? false,
    isResourcePlanMigrated: data?.model?.properties.isResourcePlanMigrated ?? false,
    ...restProps,
  };
};
