export default {
  Properties: {
    TotalRecord: '3',
    TotalPage: '3',
    PageNumber: '1',
  },

  Entities: [
    {
      Properties: {
        MilestoneId: 2,
        ProjectId: 2,
        ProjectName: 'Easy TimeLog implementation Test',
        ProjectNo: 'P.0001',
        ProjectNameFormatted: 'Easy implementation of TimeLog Project (P.0001)',
        DueDate: '2021-12-28T00:00:00',
        EmployeeId: 373,
        Name: 'Test milestone 2',
        IsCompleted: false,
        IsRecurring: false,
        Wbs: '3.1',
        MilestoneTypeId: 0,
        DepartmentId: 1,
      },
    },

    {
      Properties: {
        MilestoneId: 6,
        ProjectId: 1,
        ProjectName: 'Easy implementation of TimeLog Project',
        ProjectNo: 'P.0001',
        ProjectNameFormatted: 'Easy implementation of TimeLog Project (P.0001)',
        DueDate: '2022-03-03T00:00:00',
        EmployeeId: 373,
        Name: 'Milestone 1',
        IsCompleted: false,
        IsRecurring: false,
        Wbs: '3',
        MilestoneTypeId: 0,
        DepartmentId: 1,
      },
    },

    {
      Properties: {
        MilestoneId: 7,
        ProjectId: 1,
        ProjectName: 'Easy implementation of TimeLog Project',
        ProjectNo: 'P.0001',
        ProjectNameFormatted: 'Easy implementation of TimeLog Project (P.0001)',
        DueDate: '2026-12-29T00:00:00',
        EmployeeId: 373,
        Name: 'Milestone 2',
        IsCompleted: false,
        IsRecurring: false,
        Wbs: '4',
        MilestoneTypeId: 0,
        DepartmentId: 1,
      },
    },
  ],

  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/milestones/incomplete?dueDate=2021-12-29',

      Rel: 'self',
    },
  ],
};
