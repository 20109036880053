import { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { parseMenu } from 'src/utils/menu';
import { useTranslation } from 'react-i18next';
import { usePageDispatch } from 'src/stores/PageStore';
import { IMenus, IMenusPropertiesWithPagePath } from 'src/apis/types/menusAPI';

const PAGE_SETTINGS_MENU_URL_PATH = '/api/v2/menus/page-settings';
export const PAGE_SETTINGS_MENU_MSW_STATUS_KEY = 'pageSettingsAPI';

export const PAGE_SETTINGS_MENU_KEY = 'PAGE_SETTINGS';

const getPageSettingsMenu = (): Promise<IMenus> =>
  getFetch({ path: PAGE_SETTINGS_MENU_URL_PATH, key: PAGE_SETTINGS_MENU_MSW_STATUS_KEY });

export const usePageSettingsMenu = () => {
  const { data, ...restProps } = useQuery([PAGE_SETTINGS_MENU_KEY], getPageSettingsMenu);
  const { t } = useTranslation('pageSettingsNavigation');
  const pageSettingMenu: IMenusPropertiesWithPagePath[] = useMemo(
    () => parseMenu(data, t),
    [data, t],
  );
  const pageDispatch = usePageDispatch();
  useEffect(() => {
    if (pageDispatch && pageSettingMenu)
      pageDispatch({
        type: 'OBJECTIFY_PAGE_IDENTIFIER_FROM_PAGE_SETTING',
        payload: pageSettingMenu,
      });
  }, [pageSettingMenu, pageDispatch]);
  return {
    pageSettingMenu,
    isPageSettingMenuEmpty: pageSettingMenu.length <= 0,
    data,
    ...restProps,
  };
};
