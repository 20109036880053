import { ReactNode } from 'react';
import styles from './AssignButton.module.scss';

interface AssignButtonProps {
  children: ReactNode;
  onClick: () => void;
}

export const AssignButton = ({ children, onClick }: AssignButtonProps) => (
  <button type="button" className={styles.button} onClick={onClick}>
    {children}
  </button>
);
