import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IRPSignalRConfigResponse } from 'src/apis/types/resourcePlannerAPI';

const SIGNALR_CONFIG_URL_PATH = '/api/v2/resource-planner/signalr-config';
export const SIGNALR_CONFIG_MSW_STATUS_KEY = 'GetSignalRConfig';

export const SIGNALR_CONFIG_KEY = 'SIGNALR_CONFIG';

const resourcePlannerFetch = (): Promise<IRPSignalRConfigResponse> =>
  getFetch({ path: SIGNALR_CONFIG_URL_PATH, key: SIGNALR_CONFIG_MSW_STATUS_KEY });

const useGetRPSignalRConfigQuery = () =>
  useQuery([SIGNALR_CONFIG_KEY], resourcePlannerFetch, { refetchOnMount: false });

export const useGetRPSignalRConfig = () => {
  const { data, ...restProps } = useGetRPSignalRConfigQuery();

  return {
    tenantId: data?.properties.tenantId ?? '',
    serviceUrl: data?.properties.serviceUrl ?? '',
    ...restProps,
  };
};
