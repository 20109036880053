import { createContext, useContext, useReducer } from 'react';
import mainSearchReducer, {
  IMainSearchProps,
  initMainSearchStore,
  TMainSearchActionProps,
} from 'src/reducers/MainSearchReducer';

interface IMainSearchStoreProps {
  children: JSX.Element | JSX.Element[];
}

const MainSearchStoreContext = createContext<IMainSearchProps | undefined>(undefined);
const MainSearchDispatchContext = createContext<React.Dispatch<TMainSearchActionProps> | undefined>(
  undefined,
);
export const MainSearchProviderForTest = MainSearchStoreContext.Provider;

export const useMainSearchDispatch = (): React.Dispatch<TMainSearchActionProps> | undefined =>
  useContext(MainSearchDispatchContext);

export const useMainSearchStore = (): IMainSearchProps | undefined =>
  useContext(MainSearchStoreContext);

export const useIsMainSearchOpen = () =>
  useContext(MainSearchStoreContext)?.isMainSearchOpen || false;

export const useIsCategoryFilterOpen = () =>
  useContext(MainSearchStoreContext)?.isCategoryOpen || false;

export const useCategoriesList = () => useContext(MainSearchStoreContext)?.categoryList || [];

export const useCategoriesString = () =>
  useContext(MainSearchStoreContext)
    ?.categoryList?.map(({ name, isSelected }) => (isSelected ? name : ''))
    .filter((name) => !!name)
    .join(',') || '';

export default ({ children }: IMainSearchStoreProps) => {
  const [mainSearchState, mainSearchDispatch] = useReducer(mainSearchReducer, initMainSearchStore);

  return (
    <MainSearchStoreContext.Provider value={mainSearchState}>
      <MainSearchDispatchContext.Provider value={mainSearchDispatch}>
        {children}
      </MainSearchDispatchContext.Provider>
    </MainSearchStoreContext.Provider>
  );
};
