export default {
  Entities: [
    {
      Properties: {
        DisplayText: 'TimeLog A/S',
        Value: '1',
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/filters/department/content?pageIdentifier=Home',
      Rel: 'self',
    },
  ],
};
