export default {
  Model: {
    class: 'migrationstatusresponse',
    properties: {
      tenantId: '20923',
      isDataSynchronizationEnabled: true,
      isResourceMigrated: true,
      isProjectMigrated: true,
      isResourcePlanMigrated: true,
    },
    links: [{ href: '/migration/getmigrationstatus', rel: 'self' }],
  },
  DeterministicHash: 1803085836,
  StatusCode: 200,
};
