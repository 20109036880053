import { escapeSearchText } from 'src/utils/string';

const createHighlight = (name: string, matchName: string, fuzzy?: boolean) => {
  const matchNameRegExp = new RegExp(
    !fuzzy
      ? `(^${matchName})`
      : `(${matchName
          .split(' ')
          .filter((x) => x)
          .map((x) => escapeSearchText(x))
          .join('|')})`,
    'gi',
  );
  return {
    __html: name.replace(matchNameRegExp, '<b>$1</b>'),
  };
};

export const HighlightMatchingText = ({ name = '', matchName = '', fuzzy = true }) =>
  matchName ? (
    // eslint-disable-next-line react/no-danger
    <span dangerouslySetInnerHTML={createHighlight(name, matchName, fuzzy)} />
  ) : (
    <span>{name}</span>
  );
