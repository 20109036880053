export const getFixedColumnTitleIdentifier = (fixedColumnIdentifier: string) => {
  switch (fixedColumnIdentifier) {
    case 'budget':
      return 'ColumnLabelBudgetTooltip';
    case 'totalBooked':
      return 'ColumnLabelTotalBookedTooltip';
    case 'totalActualWorkload':
      return 'ColumnLabelTotalActualWorkloadTooltip';
    case 'notPlanned':
      return 'ColumnLabelNotPlannedTooltip';
    default:
      return '';
  }
};
