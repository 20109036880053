import { TFunction } from 'react-i18next';
import { Language } from 'src/apis/types/userSettingsAPI';
import { monthShortNames } from 'src/utils/date';
import { stringToPascal } from 'src/utils/string';

export default (
  text: string,
  periodType?: string,
  language?: Language['shortString'],
  translate?: TFunction,
) => {
  if (periodType === 'day') {
    const parsedObject = text.match(/^\d{2}(\d{2})(.{3})(\d*)$/);

    if (!parsedObject) {
      return text;
    }
    return `${parsedObject[3]} ${parsedObject[2]} '${parsedObject[1]}`;
  }
  if (periodType === 'week') {
    const parsedObject = text.match(/^\d{2}(\d{2})W(\d*)$/);

    if (!parsedObject) {
      return text;
    }
    return `${translate ? translate('WeekLabel') : 'Week'} ${parsedObject[2]} '${parsedObject[1]}`;
  }
  if (periodType === 'quarter') {
    const parsedObject = text.match(/^\d{2}(\d{2})Q(\d*)$/);

    if (!parsedObject) {
      return text;
    }
    return `Q${parsedObject[2]} '${parsedObject[1]}`;
  }
  const parsedObject = text.match(/^\d{2}(\d{2})(.{3})$/);

  // We just need the month - so year does not matter.
  // Will not work without year in Firefox
  // Safari only support specific date format
  const dateWithMonth =
    parsedObject && new Date(`2022/${monthShortNames.indexOf(parsedObject[2]) + 1}/1`);

  const translatedShortMonth =
    dateWithMonth &&
    dateWithMonth.toLocaleString(language, {
      month: 'short',
    });

  if (!parsedObject) {
    return text;
  }
  return `${translatedShortMonth && stringToPascal(translatedShortMonth)} '${parsedObject[1]}`;
};
