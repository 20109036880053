import { TTimeFormat } from 'src/apis/timeTrackingSettings';

export const convertTimeToDecimalFormat = (time: string) => {
  const timeFormatted = time.toString();
  if (timeFormatted === '0' || timeFormatted === '') {
    return '0';
  }
  const hoursMinutes = timeFormatted.split(/[:]/);
  // Test if user typed a short version: ':30' without leading zero
  const hours = hoursMinutes[0] === '' ? 0 : parseInt(hoursMinutes[0], 10);
  const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
  return (hours + minutes / 60).toString();
};

export const convertDecimalToTimeFormat = (numberString: string) => {
  // Separate the int from the decimal part
  const formatNumber = numberString.toString().replaceAll(',', '.');
  if (formatNumber === '0' || formatNumber === '') {
    return '0:00';
  }
  const parsedNumber = parseFloat(formatNumber);
  const hour = Math.floor(parsedNumber);
  let decPart = parsedNumber - hour;

  const min = 1 / 60;
  // Round to nearest minute
  decPart = min * Math.round(decPart / min);

  let minute = `${Math.floor(decPart * 60)}`;

  // Add padding if need
  if (minute.length < 2) {
    minute = `0${minute}`;
  }

  // Concatenate hours and minutes
  const time = `${hour}:${minute}`;

  return time;
};

export const validateTimeInput = (
  input: string,
  unitType: string,
  decimalErrorMessage: string,
  colonErrorMessage?: string,
  checkColonFormat: boolean = false,
  delimiter: TTimeFormat = 'DECIMAL',
) => {
  const isWholeNumber = /^[0-9][0-9]*$/.test(input);
  const isDecimalNumber = /^[0-9][0-9]*[,,.][0-9]?[0-9]$/.test(input);
  const isTimeNumber = /^[0-9][0-9]*:[0-5][0-9]$/.test(input);
  const isShortTime = /^:[0-5][0-9]$/.test(input);
  const isShortDecimal = /^[,,.][0-9]?[0-9]$/.test(input);
  const isDelimiterTime = delimiter === 'TIME';
  const isDayUnit = unitType === 'days';
  const isHoursUnit = unitType === 'hours';
  const isPercentagesUnit = unitType === 'percentages';
  const inputFormatted = input.toString();

  if (inputFormatted === '') {
    return { status: 'success', value: '' };
  }

  if (isWholeNumber) {
    return { status: 'success', value: inputFormatted };
  }

  if (isHoursUnit && isDelimiterTime && checkColonFormat && colonErrorMessage) {
    if (isTimeNumber || isShortTime) {
      return { status: 'success', value: convertTimeToDecimalFormat(inputFormatted) };
    }

    return { status: 'error', value: colonErrorMessage };
  }

  if (isDayUnit || isPercentagesUnit || isHoursUnit) {
    if (isDecimalNumber) {
      return { status: 'success', value: inputFormatted.replaceAll(',', '.') };
    }

    if (isShortDecimal) {
      return { status: 'success', value: 0 + inputFormatted.replaceAll(',', '.') };
    }

    return { status: 'error', value: decimalErrorMessage };
  }

  return { status: '', value: '' };
};

export const formatTime = (
  time: string | number,
  locale: string,
  format: TTimeFormat = 'DECIMAL',
  maximumFractionDigits: number = 2,
) => {
  const parsedTime = typeof time === 'string' ? parseFloat(time) : time;
  const decimalFormat = parsedTime.toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits,
  });

  if (format === 'TIME') {
    convertDecimalToTimeFormat(parsedTime.toString());
  }

  return decimalFormat;
};
