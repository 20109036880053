import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { INotificationCenter } from 'src/apis/types/notificationCenter';

const NOTIFICATION_CENTER_URL_PATH = '/api/v2/notification-center';
export const NOTIFICATION_CENTER_MSW_STATUS_KEY = 'notificationCenterAPI';

export const NOTIFICATION_CENTER_KEY = 'NOTIFICATION_CENTER';

const getNotificationCenter = (): Promise<INotificationCenter> =>
  getFetch({ path: NOTIFICATION_CENTER_URL_PATH, key: NOTIFICATION_CENTER_MSW_STATUS_KEY });

const useGetNotificationCenterQuery = () =>
  useQuery([NOTIFICATION_CENTER_KEY], getNotificationCenter);

export const useGetIsApprovalEnabled = () => {
  const { data, ...restProps } = useGetNotificationCenterQuery();
  const isEnabled =
    data?.properties?.isApprovalEnabled &&
    data?.properties?.designSevenNotificationCenterPreviewFeatureEnabled;

  return {
    isApprovalEnabled: isEnabled || false,
    ...restProps,
  };
};

export const useGetNotificationCenter = () => {
  const { data, ...restProps } = useGetNotificationCenterQuery();

  const timeSheetsToApprove = data?.properties?.notificationCenterApprovalItems?.filter(
    (item) => item.navigationItemType === 0,
  );

  const timeSheetsToApproveLength = timeSheetsToApprove?.length || 0;

  const absenceToApprove = data?.properties?.notificationCenterApprovalItems?.filter(
    (item) => item.navigationItemType === 1,
  );

  const absenceToApproveLength = absenceToApprove?.length || 0;
  const timeSheetsToBeClosed = data?.properties?.notificationCenterTimesheetItems || [];
  const timeSheetsToBeClosedLength =
    timeSheetsToBeClosed?.reduce((acc, val) => acc + val.weeklyTimesheetDetails.length, 0) || 0;

  const itemsToTakeActionOn =
    timeSheetsToApproveLength +
    absenceToApproveLength +
    timeSheetsToBeClosedLength +
    (data?.properties?.numberOfMileagePendingApproval || 0) +
    (data?.properties?.numberOfExpensesPendingApproval || 0);

  return {
    ...data?.properties,
    ...restProps,
    isApprovalOfTimeSheetEnabled: data?.properties?.isApprovalOfTimeSheetEnabled || false,
    isApprovalOfExpensesEnabled: data?.properties?.isApprovalOfExpensesEnabled || false,
    timeSheetsToApprove,
    absenceToApprove,
    itemsToTakeActionOn,
    timeSheetsToBeClosedLength,
  };
};

export default getNotificationCenter;
