import { TextFieldProps as MuiTextFieldProps, TextField as TextFieldMui } from '@mui/material';
import cx from 'classnames';
import { TIconName } from 'src/assets/variables/icon';
import { TStatus } from 'src/assets/variables/status';
import { Icon } from 'src/components/ui-components';
import styles from './TextField.module.scss';

type TextFieldProps = MuiTextFieldProps & {
  usePopOutValidation?: boolean;
  errorMessage?: string;
  status?: TStatus;
  ariaLabel: string;
} & (WithLabelProps | WithoutLabelProps);

type WithLabelProps = {
  label: string;
};

type WithoutLabelProps = {
  hiddenLabel?: boolean;
  ariaLabel: string;
};

interface IValidationPopOutProps {
  status: TStatus;
  validationMsg: string;
}

const statusIconMap: Record<TStatus, TIconName> = {
  error: 'error',
  success: 'circleCheck',
  warning: 'warning',
  default: 'info',
};

const ValidationPopOut = ({ validationMsg, status }: IValidationPopOutProps) => (
  <div className={`${styles.helperText}`}>
    <div className={styles.validationContent}>
      <Icon size="small" iconName={statusIconMap[status]} tone={status} />
      <div
        className={cx({
          [styles[`color_${status}`]]: status,
        })}
      >
        {validationMsg}
      </div>
    </div>
  </div>
);

export const TextField = ({
  usePopOutValidation,
  errorMessage,
  status,
  ariaLabel,
  ...rest
}: TextFieldProps) => (
  <>
    <TextFieldMui {...rest} inputProps={{ ...(rest.inputProps || {}), 'aria-label': ariaLabel }} />
    {usePopOutValidation && status && errorMessage && (
      <ValidationPopOut status={status} validationMsg={errorMessage} />
    )}
  </>
);
