import { useTranslation } from 'react-i18next';
import { useUpdatePeriodLock } from 'src/apis/exchangeRateAPI';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'src/components/mui-components';

interface LockPeriodModalProps {
  periodId: number;
  currentPeriodIsLocked: boolean;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const LockPeriodModal: React.FC<LockPeriodModalProps> = ({
  periodId,
  currentPeriodIsLocked,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation('systemAdminExchangeRate');
  const { mutateAsync: updateLockPeriod } = useUpdatePeriodLock();

  const updateLockPeriodAction = async () => {
    try {
      const response = await updateLockPeriod({ periodId, isLockPeriod: !currentPeriodIsLocked });
      if (response) {
        return response;
      }
    } catch (error) {
      // no actions required, error handled
    } finally {
      setIsOpen(false);
    }
    return null;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="dialog-lock-period"
      aria-describedby="dialog-lock-period-desc"
    >
      <DialogTitle id="dialog-lock-period">
        {currentPeriodIsLocked ? t('modals.UnlockPeriodTitle') : t('modals.LockPeriodTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-lock-period-desc">
          {currentPeriodIsLocked
            ? t('modals.UnlockPeriodDescription')
            : t('modals.LockPeriodDescription')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => updateLockPeriodAction()}
          autoFocus
          data-automation-id="LockPeriodModalSaveButton"
        >
          {t('modals.LockPeriodSaveButton')}
        </Button>
        <Button onClick={() => setIsOpen(false)} data-automation-id="LockPeriodModalCancelButton">
          {t('modals.CancelButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LockPeriodModal;
