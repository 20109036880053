import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IUserPreviewFeatures } from 'src/apis/types/userPreviewFeaturesAPI';

const USER_PREVIEW_FEATURES_URL_PATH = '/api/v2/user-preview-features';
export const USER_PREVIEW_FEATURES_MSW_STATUS_KEY = 'userPreviewFeaturesAPI';

export const USER_PREVIEW_FEATURES_KEY = 'USER_PREVIEW_FEATURES';

const getUserPreviewFeatures = (): Promise<IUserPreviewFeatures> =>
  getFetch({ path: USER_PREVIEW_FEATURES_URL_PATH, key: USER_PREVIEW_FEATURES_MSW_STATUS_KEY });

const useGetUserPreviewFeaturesQuery = () =>
  useQuery([USER_PREVIEW_FEATURES_KEY], getUserPreviewFeatures, { refetchOnMount: false });

export const useIsDesignSevenEnabled = () => {
  const { data, ...restProps } = useGetUserPreviewFeaturesQuery();
  const isDesignSevenEnabled = data?.entities?.some(
    (entity) => entity?.properties?.featureIdentifier === 'DesignSeven',
  );
  return {
    isDesignSevenEnabled,
    ...restProps,
  };
};

export const useGetPreviewFeaturesCount = () => {
  const { data, ...restProps } = useGetUserPreviewFeaturesQuery();
  const { activeCount, totalCount } = data?.properties || {};
  const previewFeaturesCount = activeCount && totalCount ? `(${activeCount}/${totalCount})` : '';
  return {
    previewFeaturesCount,
    ...restProps,
  };
};

export default getUserPreviewFeatures;
