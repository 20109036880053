import { RefObject, useEffect } from 'react';

export const useSetSubMenuToActive = ({
  element,
  activeFunction,
  inActiveFunction,
  isSuccess,
}: {
  element: RefObject<HTMLLIElement>;
  activeFunction: () => void;
  inActiveFunction: () => void;
  isSuccess: boolean;
}) => {
  useEffect(() => {
    const menuItem = element.current;
    if (isSuccess && menuItem) {
      ['mouseover', 'focusin'].forEach((e: string) => {
        menuItem?.addEventListener(e, () => {
          activeFunction();
        });
      });
      ['mouseleave', 'focusout'].forEach((e: string) => {
        menuItem?.addEventListener(e, () => {
          inActiveFunction();
        });
      });
    }

    return () => {
      ['mouseover', 'focusin'].forEach((e: string) => {
        menuItem?.removeEventListener(e, () => {
          activeFunction();
        });
      });
      ['mouseleave', 'focusout'].forEach((e: string) => {
        menuItem?.removeEventListener(e, () => {
          inActiveFunction();
        });
      });
    };
  }, [inActiveFunction, activeFunction, element, isSuccess]);
};
