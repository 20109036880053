import { ContextMenuWithListItems, Flex, Text } from 'src/components/ui-components';
import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import DistributeUnplannedWorkloadModalContent from '../DistributeUnplannedWorkloadModalContent';

interface IUnplannedCell {
  children: ReactNode;
  resourceId?: string;
  workItemId?: string;
  hierarchyType: string;
  resourceName: string;
  workItemName?: string;
  isLowestLevel: boolean;
  startsAt?: string;
  endsAt?: string;
  negativeUnplannedHours: number;
  type?: string;
  hasChildWithEndDateOnTodayOrAfter?: boolean;
}

const UnplannedCell = ({
  children,
  resourceId,
  workItemId,
  hierarchyType,
  resourceName,
  workItemName,
  isLowestLevel,
  startsAt,
  endsAt,
  negativeUnplannedHours,
  type,
  hasChildWithEndDateOnTodayOrAfter,
}: IUnplannedCell) => {
  const { t } = useTranslation('resourcePlanner');

  const isResourceRow = hierarchyType.toLowerCase() === 'resource';

  const rowType = type?.toLowerCase();

  const headingText = isResourceRow ? (
    <Trans
      i18nKey="ModalDistributeUnplannedResourceHeader"
      key="ModalDistributeUnplannedResourceHeader"
      defaults={t('ModalDistributeUnplannedResourceHeader')}
      values={{ resourceName }}
      components={[
        <Text semibold inheritSize as="span">
          {resourceName}
        </Text>,
      ]}
    />
  ) : (
    <Trans
      i18nKey="ModalDistributeUnplannedResourceAndWorkItemHeader"
      key="ModalDistributeUnplannedResourceAndWorkItemHeader"
      defaults={t('ModalDistributeUnplannedResourceAndWorkItemHeader')}
      values={{ resourceName, workItemName }}
      components={[
        <Text semibold inheritSize as="span">
          {resourceName}
        </Text>,
        <Text semibold inheritSize as="span">
          {workItemName}
        </Text>,
      ]}
    />
  );

  return (
    <Flex gap="xxSmall" horizontalAlignment="justify">
      <ContextMenuWithListItems
        buttonSize="compact"
        text="Unplanned cell menu - show more"
        tooltipText="Show more"
        data-automation-id="unplanned-menu"
        listItems={[
          {
            type: 'modal',
            size: 'small',
            scrollInContent: !isResourceRow,
            headingText,
            closeText: t('Close'),
            childComponent: (
              <DistributeUnplannedWorkloadModalContent
                isResourceRow={isResourceRow}
                resourceId={resourceId}
                workItemId={workItemId}
                isLowestLevel={isLowestLevel}
                startsAt={startsAt}
                endsAt={endsAt}
                negativeUnplannedHours={negativeUnplannedHours}
                rowType={rowType}
                hasChildWithEndDateOnTodayOrAfter={hasChildWithEndDateOnTodayOrAfter}
              />
            ),
            children: t('ContextMenuDistributeUnplannedWork'),
            'data-automation-id': 'distribute-unplanned-work-modal',
          },
        ]}
      />
      <span>{children}</span>
    </Flex>
  );
};

export default UnplannedCell;
