import { FC, ReactNode, HTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './Heading.module.scss';

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  children: ReactNode;
  /** The semantic correct heading level (http://html5doctor.com/outlines/) */
  level?: 1 | 2 | 3 | 4;
  /** Visual representaion of the heading */
  as?: 1 | 2 | 3 | 4;
  /** Use bold text */
  bold?: boolean;
  /** Use semibold text */
  semibold?: boolean;
  /** Use medium text */
  medium?: boolean;
}

export const Heading: FC<HeadingProps> = ({
  level = 2,
  as = level,
  bold = false,
  semibold = false,
  medium = false,
  children,
  ...props
}) => {
  const HTag = `h${level}` as React.ElementType;
  return (
    <HTag
      className={classNames(
        `${styles.Heading} ${styles[`Heading____${as}` as keyof typeof styles]}`,
        {
          [styles.Heading____bold]: bold,
          [styles.Heading____semibold]: semibold,
          [styles.Heading____medium]: medium,
        },
      )}
      {...props}
    >
      {children}
    </HTag>
  );
};
