export default {
  model: {
    class: 'plan',
    properties: {
      tenantId: '1',
      responseType: 'GroupByEmployee',
      resourceNodes: [],
    },
    links: [{ href: '/plan/groupbyemployee', rel: 'self' }],
  },
  deterministicHash: 1504266620,
  statusCode: 200,
};
