import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IFilterOptions } from 'src/apis/types/dropdownAPI';

interface IGetDropDownAPI {
  path: string;
  MSWKey: string;
  key: string;
}

export const useGetDropdownAPI = ({ key, path, MSWKey }: IGetDropDownAPI) => {
  const dropdownFetch = (): Promise<IFilterOptions> => getFetch({ path, key: MSWKey });
  const { data, ...restProps } = useQuery([`DropDown${key}`], dropdownFetch);
  return {
    dropdownList:
      data?.entities?.map(({ properties: { displayText = '', value = '' } = {} }) => ({
        label: displayText,
        value,
      })) || [],
    dropdownLink: data?.links?.[0]?.href,
    ...restProps,
  };
};
