export default {
  Entities: [
    {
      Properties: {
        DisplayText: 'Peter Summersen',
        Value: '373',
      },
    },
    {
      Properties: {
        DisplayText: 'Bastian Gummersen',
        Value: '374',
      },
    },
    {
      Properties: {
        DisplayText: 'Carsten Nummersen',
        Value: '375',
      },
    },
    {
      Properties: {
        DisplayText: 'Marianne Zummersen',
        Value: '376',
      },
    },
    {
      Properties: {
        DisplayText: 'Ida Bummersen',
        Value: '377',
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/filters/project-manager/content?pageIdentifier=Home',
      Rel: 'self',
    },
  ],
};
