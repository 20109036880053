import { Shimmer } from 'src/components/ui-components/Shimmer';
import { Stack } from 'src/components/ui-components/Stack';

export default () => (
  <Stack verticalMargin="xSmall" data-automation-id="MilestonesLoadMessage">
    <Shimmer width="thirdQuarter" />
    <Shimmer width="full" />
    <Shimmer width="half" />
    <Shimmer width="thirdQuarter" />
  </Stack>
);
