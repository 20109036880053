export default {
  model: {
    properties: {
      resources: [
        {
          id: '1',
          profileImage:
            'http://www.canada-work.com/_/rsrc/1531284300421/assistants/female_generic_profile.png',
          fullName: 'jan Therkeldsen (JTE)',
          position: 'HR Assistant, HR',
          anotherText: 'HR, hiring, employee development',
        },
        {
          id: '2',
          profileImage:
            'http://www.canada-work.com/_/rsrc/1531284300421/assistants/female_generic_profile.png',
          fullName: 'jan Therkeldsen (JTE)',
          position: 'HR Assistant, HR',
          anotherText: 'HR, hiring, employee development',
        },
        {
          id: '3',
          profileImage:
            'http://www.canada-work.com/_/rsrc/1531284300421/assistants/female_generic_profile.png',
          fullName: 'jan Therkeldsen (JTE)',
          position: 'HR Assistant, HR',
          anotherText: 'HR, hiring, employee development',
        },
        {
          id: '4',
          profileImage:
            'http://www.canada-work.com/_/rsrc/1531284300421/assistants/female_generic_profile.png',
          fullName: 'jan Therkeldsen (JTE)',
          position: 'HR Assistant, HR',
          anotherText: 'HR, hiring, employee development',
        },
        {
          id: '5',
          profileImage:
            'http://www.canada-work.com/_/rsrc/1531284300421/assistants/female_generic_profile.png',
          fullName: 'jan Therkeldsen (JTE)',
          position: 'HR Assistant, HR',
          anotherText: 'HR, hiring, employee development',
        },
        {
          id: '6',
          profileImage:
            'http://www.canada-work.com/_/rsrc/1531284300421/assistants/female_generic_profile.png',
          fullName: 'jan Therkeldsen (JTE)',
          position: 'HR Assistant, HR',
          anotherText: 'HR, hiring, employee development',
        },
      ],
      competences: [
        {
          label: 'Javascript',
          resources: [
            {
              id: '3',
              profileImage:
                'http://www.canada-work.com/_/rsrc/1531284300421/assistants/female_generic_profile.png',
              fullName: 'jan Therkeldsen (JTE)',
              position: 'HR Assistant, HR',
              anotherText: 'HR, hiring, employee development',
            },
            {
              id: '4',
              profileImage:
                'http://www.canada-work.com/_/rsrc/1531284300421/assistants/female_generic_profile.png',
              fullName: 'jan Therkeldsen (JTE)',
              position: 'HR Assistant, HR',
              anotherText: 'HR, hiring, employee development',
            },
          ],
        },
        {
          label: 'Japansk',
          resources: [
            {
              id: '3',
              profileImage:
                'http://www.canada-work.com/_/rsrc/1531284300421/assistants/female_generic_profile.png',
              fullName: 'jan Therkeldsen (JTE)',
              position: 'HR Assistant, HR',
              anotherText: 'HR, hiring, employee development',
            },
            {
              id: '4',
              profileImage:
                'http://www.canada-work.com/_/rsrc/1531284300421/assistants/female_generic_profile.png',
              fullName: 'jan Therkeldsen (JTE)',
              position: 'HR Assistant, HR',
              anotherText: 'HR, hiring, employee development',
            },
          ],
        },
      ],
      departments: [
        {
          label: 'Japan Growth Division',
          resources: [
            {
              id: '5',
              profileImage:
                'http://www.canada-work.com/_/rsrc/1531284300421/assistants/female_generic_profile.png',
              fullName: 'jan Therkeldsen (JTE)',
              position: 'HR Assistant, HR',
              anotherText: 'HR, hiring, employee development',
            },
            {
              id: '6',
              profileImage:
                'http://www.canada-work.com/_/rsrc/1531284300421/assistants/female_generic_profile.png',
              fullName: 'jan Therkeldsen (JTE)',
              position: 'HR Assistant, HR',
              anotherText: 'HR, hiring, employee development',
            },
          ],
        },
      ],
    },
  },
};
