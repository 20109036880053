import { createContext, useContext, useReducer } from 'react';
import mobileNavigationReducer, {
  initMobileNavigationStore,
  MobileNavigationStoreProps,
  MobileNavigationActionProps,
} from 'src/reducers/MobileNavigationReducer';

interface IMobileNavigationStoreProps {
  children: JSX.Element | JSX.Element[];
}

const MobileNavigationStoreContext =
  createContext<MobileNavigationStoreProps>(initMobileNavigationStore);

const MobileNavigationDispatchContext = createContext<
  React.Dispatch<MobileNavigationActionProps> | undefined
>(undefined);

export const useMobileNavigationStore = () => useContext(MobileNavigationStoreContext);
export const useMobileNavigationDispatch = () => useContext(MobileNavigationDispatchContext);

export default ({ children }: IMobileNavigationStoreProps) => {
  const [mobileNavigationState, mobileNavigationDispatch] = useReducer(
    mobileNavigationReducer,
    initMobileNavigationStore,
  );

  return (
    <MobileNavigationStoreContext.Provider value={mobileNavigationState}>
      <MobileNavigationDispatchContext.Provider value={mobileNavigationDispatch}>
        {children}
      </MobileNavigationDispatchContext.Provider>
    </MobileNavigationStoreContext.Provider>
  );
};
