export const customerSegmentationObject = {
  UserId: 'local_1222',
  CustomerProductId: 2736,
  FullName: 'Peter Summersen',
  Account: 'local',
  UserName: 'PSU',
  UserIdentifier: 1222,
  UserCreatedYear: 2020,
  UserCreatedMonth: 6,
  UserCreatedDay: 15,
  Email: 'psu@timelog.dk',
  Language: 2057,
  FirstName: 'Peter',
  MajorVersion: 7,
  MinorVersion: 0,
  BuildVersion: 563,
  ProductionStatus: 'Production',
  Product: 'TimeLogPSA',
  Plan: 'EnterprisePSA',
  Database: 'sqlins02.internal.timelog.com',
  UserDepartmentName: '40. R&D',
  SiteCountry: 'DK',
  RoleProject: 1,
  RoleContract: 1,
  RoleCustomer: 1,
  RoleApproval: 0,
  RoleEmployee: 1,
  RoleEmployeeModify: 0,
  RoleExpense: 1,
  RoleSystemAdm: 1,
  RoleInvoice: 1,
  RoleMileage: 1,
  RolePreviewFeature: 1,
  PreviewFeatureDesignSeven: 1,
  PreviewFeatureDesignSevenFrontPage: 1,
  ModuleCrm: 0,
  ModuleOutlook: 0,
  IntEcn: 1,
  IntExact: 0,
  IntFortnox: 0,
  IntVisma: 0,
  IntNav: 0,
  IntDinero: 0,
  IntUniconta: 0,
  IntBjörnLundén: 0,
  IntDanløn: 0,
  IntVismasalary: 0,
  IntHogia: 0,
  IntZenegy: 0,
  IntCrona: 0,
  IntDataloen: 1,
  IntFortnoxLön: 0,
  IntLessorPayroll: 0,
  IntSLS: 0,
  TimeLogResponsible: 0,
  GDPRResponsible: 0,
  RoleMyAccount: 0,
  TimelogResponsibleOnSite: 1,
  CompactTimesheetView: 0,
  DefaultTimesheetView: 'Weekly',
  tandmstandard: 1,
  tandmprepaidhours: 1,
  tandmonaccountendbalancing: 0,
  tandmonaccountperiodicbalancing: 0,
  fpstandard: 1,
  fpcontinuousservice: 1,
  fpcontinuousiteminvoicing: 1,
  fptaskdrivenrevenue: 1,
  MLE: 0,
  ResourcePlanner: 1,
};
