// all test will perform on integration test `openNewTabTest.spec.ts`

export const auxClickPreventNewTabFocus = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
) => event.currentTarget.blur();

export const onClickPreventNewTabFocus = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
) => {
  if (event.ctrlKey || event.shiftKey || event.metaKey || (event.button && event.button === 1)) {
    event.currentTarget.blur();
  }
};
