import { postFetch } from 'src/utils/fetcher';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { POST_TIME_TRACKER_KEY, GET_TIME_TRACKER_KEY } from '../utils';

export const useStopTimeTracker = (
  setIsStopButtonDisable: React.Dispatch<React.SetStateAction<boolean>>,
  stopPath?: string,
) => {
  const stopTimerPost = () => {
    if (stopPath) {
      return postFetch({ path: stopPath, key: 'stopTimeTrackerPath' });
    }
    return postFetch({ path: '', key: '' });
  };
  const qc = useQueryClient();

  return useMutation(stopTimerPost, {
    onSuccess: () => {
      qc.invalidateQueries([GET_TIME_TRACKER_KEY]);
      setIsStopButtonDisable(false);
      if (
        window.location.pathname === '/Registration/TimeTracking' ||
        window.location.pathname === '/Registration/TimeTracking/Index'
      )
        window.location.reload();
    },
    onMutate: () => {
      setIsStopButtonDisable(true);
    },
    onError: () => {
      setIsStopButtonDisable(false);
    },
    mutationKey: [POST_TIME_TRACKER_KEY],
  });
};
