import specifyDropdownMapper from './specifyDropdown';

const dropdownBuilder = (name: string, count: number, searchText?: string) => {
  if (specifyDropdownMapper?.[name]) {
    return specifyDropdownMapper?.[name];
  }

  let options = Array.from(Array(count).keys()).map((number) => ({
    Properties: {
      Value: `${name}Option${number + 1}`,
      DisplayText: `${name} ${number + 1}`,
    },
  }));

  if (searchText) {
    options = options.filter((item) =>
      item.Properties.DisplayText.toLowerCase().includes(searchText.toLowerCase()),
    );
  }

  return {
    Entities: options,
    Links: [
      {
        Href: 'http://localhost/tlp/api/v2/filter/dropdown',
        Rel: 'self',
      },
    ],
  };
};

export default dropdownBuilder;
