import { forwardRef, useContext, ForwardRefExoticComponent, RefAttributes } from 'react';
import '@reach/menu-button/styles.css';
import cx from 'classnames';
import { Menu, MenuList, MenuButton, MenuItem, MenuLink } from '@reach/menu-button';

import { Flex } from 'src/components/ui-components/Flex';
import { Icon } from 'src/components/ui-components/Icon';
import { Modal, ModalContext } from 'src/components/ui-components/Modal';
import {
  ContextMenuInterface,
  ContextMenuItemInnerInterface,
  ContextMenuModalInterface,
} from './ContextMenuType';

import styles from './ContextMenu.module.scss';

export const ContextMenu: ContextMenuInterface = (props) => <Menu {...props} />;

ContextMenu.List = (props) => <MenuList className={styles.ContextMenu__list} {...props} />;

ContextMenu.Content = (props) => <div className={styles.ContextMenu__content} {...props} />;

ContextMenu.Trigger = forwardRef((props, ref) => <MenuButton ref={ref} {...props} />);

const ItemInner = ({ iconName, position = 'left', ...props }: ContextMenuItemInnerInterface) => (
  // Need to use span as div is not allowed inside button element
  <Flex
    gap="xxSmall"
    as="span"
    direction={position === 'right' ? 'rowReverse' : 'row'}
    horizontalAlignment={position === 'right' ? 'right' : 'left'}
  >
    {iconName && (
      <Icon
        iconName={iconName}
        aria-hidden="true"
        data-automation-id={`ContextMenuItemIcon${
          position.charAt(0).toUpperCase() + position.slice(1)
        }`}
      />
    )}
    <Flex.Item as="span">{props.children}</Flex.Item>
  </Flex>
);
ContextMenu.Item = forwardRef(({ iconName, position, ...props }, ref) => (
  <MenuItem as="button" className={styles.ContextMenu__link} ref={ref} {...props}>
    <ItemInner iconName={iconName} position={position}>
      {props.children}
    </ItemInner>
  </MenuItem>
));

ContextMenu.Link = forwardRef(({ iconName, position, wrapText, ...props }, ref) => (
  <MenuLink
    className={cx(styles.ContextMenu__link, { [styles.ContextMenu__link____wrapText]: wrapText })}
    ref={ref}
    {...props}
  >
    <ItemInner iconName={iconName} position={position}>
      {props.children}
    </ItemInner>
  </MenuLink>
));

ContextMenu.Divider = (props) => <hr className={styles.ContextMenu__divider} {...props} />;

const ModalMenuItem: ForwardRefExoticComponent<
  ContextMenuModalInterface & RefAttributes<HTMLButtonElement>
> = forwardRef(
  (
    {
      iconName,
      position,
      size,
      headingText,
      closeText,
      headingComponent,
      childComponent,
      children,
      ...props
    },
    ref,
  ) => {
    const { openModal } = useContext(ModalContext);
    return (
      <>
        <MenuItem
          as="button"
          className={styles.ContextMenu__link}
          ref={ref}
          {...props}
          onSelect={openModal}
        >
          <ItemInner iconName={iconName} position={position}>
            {children}
          </ItemInner>
        </MenuItem>

        <Modal.Dialog size={size}>
          <Modal.Header heading={headingText} closeText={closeText}>
            {headingComponent}
          </Modal.Header>
          {/* Needs to have <Modal.Content> or <Modal.Footer> for spacing */}
          {childComponent}
        </Modal.Dialog>
      </>
    );
  },
);

ContextMenu.Modal = forwardRef((props, ref) => (
  <Modal>
    <ModalMenuItem {...props} ref={ref} />
  </Modal>
));
