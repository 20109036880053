import { IValueText } from 'src/screens/Home/HomeType';

type IconNameResult = 'trendingUp' | 'trendingDown';

export const getIconName = ({
  trending,
  trendingDirection,
}: Pick<IValueText, 'trending' | 'trendingDirection'>): IconNameResult => {
  if (trendingDirection) {
    return trendingDirection === 'up' ? 'trendingUp' : 'trendingDown';
  }
  return trending === 'Yes' ? 'trendingUp' : 'trendingDown';
};
