import { IMenusProperties } from 'src/apis/types/menusAPI';
import { formatUrl } from 'src/utils/url';

type TPageIdentifier = string;
type TPageId = string;
type TPageUrl = string;

interface LastPageProps {
  pageUrl: TPageUrl;
  pageSettingUrl: TPageUrl;
}

interface CurrentPageProps {
  pageId: TPageId;
  pageIdentifier: TPageIdentifier;
  pageTitle: string;
}

interface PageSettingProps {
  pageSettingUrl: TPageUrl;
  pageId: TPageId;
}

export interface PageProps {
  lastPage: LastPageProps;
  currentPage: CurrentPageProps;
  currentModalPage: CurrentPageProps;
  pageSettings: Record<TPageIdentifier, PageSettingProps>;
}

interface IPageIdentifierProps extends Pick<CurrentPageProps, 'pageIdentifier'> {
  pagePath: string;
}

interface IPageStatusProps extends CurrentPageProps {}

export type PageActionProps =
  | {
      type: 'OBJECTIFY_PAGE_IDENTIFIER_FROM_PAGE_SETTING';
      payload: IMenusProperties[];
    }
  | { type: 'SET_LAST_VISITED_PAGE'; payload: IPageIdentifierProps }
  | {
      type: 'SET_CURRENT_PAGE' | 'SET_CURRENT_MODAL_PAGE';
      payload: IPageStatusProps;
    };

const pageSettingMapping = (menuItems: IMenusProperties[]): Pick<PageProps, 'pageSettings'> => {
  const defaultValue: Pick<PageProps, 'pageSettings'> = {
    pageSettings: {},
  };
  menuItems.forEach(({ subMenus = [] }) => {
    subMenus?.forEach(({ pageIdentifier, pageUrl, pageId }) => {
      defaultValue.pageSettings[pageIdentifier] = { pageSettingUrl: formatUrl(pageUrl), pageId };
    });
  });
  return defaultValue;
};

export const pageSettingMappingForTest = pageSettingMapping;

export const initPageStore: PageProps = {
  lastPage: { pageUrl: '/', pageSettingUrl: '' },
  currentPage: { pageIdentifier: '', pageId: '', pageTitle: '' },
  pageSettings: {},
  currentModalPage: { pageIdentifier: '', pageId: '', pageTitle: '' },
};

export const PageReducer = (state: PageProps, action: PageActionProps): PageProps => {
  switch (action.type) {
    case 'OBJECTIFY_PAGE_IDENTIFIER_FROM_PAGE_SETTING': {
      const { pageSettings } = pageSettingMapping(action.payload);

      return action.payload.length > 0
        ? {
            ...state,
            pageSettings,
            lastPage: {
              ...state.lastPage,
              pageSettingUrl:
                state.lastPage.pageSettingUrl === ''
                  ? pageSettings?.[Object.keys(pageSettings)[0]]?.pageSettingUrl
                  : state.lastPage.pageSettingUrl,
            },
          }
        : state;
    }
    case 'SET_LAST_VISITED_PAGE': {
      const { pagePath, pageIdentifier } = action.payload;
      const { pageSettings } = state;
      return {
        ...state,
        lastPage: {
          pageUrl: pagePath || '/',
          pageSettingUrl:
            pageSettings?.[pageIdentifier]?.pageSettingUrl ||
            pageSettings?.[Object.keys(pageSettings)[0]]?.pageSettingUrl ||
            '',
        },
      };
    }
    case 'SET_CURRENT_PAGE': {
      const { pageIdentifier, pageId, pageTitle } = action.payload;
      return {
        ...state,
        currentPage: {
          pageTitle: pageTitle || '',
          pageIdentifier: pageIdentifier || '',
          pageId,
        },
      };
    }
    case 'SET_CURRENT_MODAL_PAGE': {
      return {
        ...state,
        currentModalPage: {
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};
