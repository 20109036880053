const timeRegistrationAccuracyResult = {
  Properties: {
    UserId: 1,
    StartDate: '2022-02-23T00:00:00',
    EndDate: '2022-03-01T00:00:00',
    LastRegistrationDate: '2022-02-25T11:27:28.9',
    AccuracyCurrentPeriod: 2.67,
    AccuracyPreviousPeriod: 8,
  },
  Links: [
    {
      Href: 'http://localhost/timelog/api/v2/widget-data/time-registration-accuracy?startDate=2022-02-23&endDate=2022-03-01',
      Rel: 'self',
    },
  ],
};

export default timeRegistrationAccuracyResult;
