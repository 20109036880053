import { useTranslation } from 'react-i18next';
import { useGetCurrentHelpPageUrl } from 'src/stores/PageStore';
import { IconButtonAnchor, IconButtonAnchorProps } from 'src/components/ui-components/IconButton';

interface HelpButtonProps extends Pick<IconButtonAnchorProps, 'size' | 'iconSize'> {
  'data-automation-id': string;
}

export default ({ size, iconSize, ...props }: HelpButtonProps) => {
  const { t } = useTranslation('header');
  const helpUrl = useGetCurrentHelpPageUrl();
  return (
    <IconButtonAnchor
      iconName="help"
      size={size}
      iconSize={iconSize}
      href={helpUrl}
      data-automation-id={props['data-automation-id']}
      target="_blank"
      rel="noopener"
      tooltipText={t('HelpSectionLink')}
    />
  );
};
