import {
  ButtonGhost,
  SearchInput,
  Stack,
  Text,
  Shimmer,
  Icon,
  Flex,
} from 'src/components/ui-components';
import { DateTime } from 'luxon';
import { useState, ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostCreateWorkItemRelationship } from 'src/apis/resourcePlannerAPI';
import { ISearchTask } from 'src/apis/types/resourcePlannerAPI';
import { useGetSearchTaskByNameAPI } from 'src/apis/resourcePlannerAPI/get/getSearchTaskByNameAPI';
import { useDebounce } from 'use-debounce';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { AssignAction } from '../AssignAction';
import AssignItem from '../AssignItem';
import { ScrollContainer } from '../ScrollContainer';
import { AssignButton } from '../AssignButton';
import { AssignmentSelectorFittedProps } from '../../types';

export const TaskSelector = ({
  itemId,
  itemSourceReferenceId,
  itemType,
  itemTitle,
  ...props
}: AssignmentSelectorFittedProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedInputValue] = useDebounce(searchValue, 100);
  const [selectedStartDate] = useState<DateTime | null>(null);
  const [selectedEndDate] = useState<DateTime | null>(null);
  const [totalHours] = useState('');
  const [selectedTask, setSelectedTask] = useState<ISearchTask>();
  const { t } = useTranslation('resourcePlanner');
  const { isInitialLoading, isError, isSuccess, workItems } = useGetSearchTaskByNameAPI(
    itemId,
    debouncedInputValue,
  );
  const searchInputElement = useRef<HTMLInputElement>(null);
  const changeButton = useRef<HTMLButtonElement>(null);

  const reset = () => {
    setSearchValue('');
    setSelectedTask(undefined);
    setModalIsOpen(false);
  };

  const select = (item: ISearchTask) => {
    setSelectedTask(item);

    // this is to bring focus to the view where you can see
    // the chosen task. Otherwise Escape wouldn't work
    setTimeout(() => changeButton.current?.focus(), 100);
  };

  const { mutate: postAssignTaskToResource } = usePostCreateWorkItemRelationship();
  const doAssign = () => {
    if (selectedTask) {
      postAssignTaskToResource({
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        totalHours,
        resourceId: itemId,
        resourceSourceReferenceId: itemSourceReferenceId,
        resourceType: itemType,
        workItemId: selectedTask?.id,
        workItemSourceReferenceId: selectedTask?.sourceReferenceId,
        workItemType: selectedTask?.type,
      });
      reset();
    }
  };

  return (
    <AssignAction
      {...props}
      heading={
        <>
          <Text as="span" inheritSize>
            {t('Assign')}
          </Text>{' '}
          <Text as="span" emphasize inheritSize semibold>
            {itemTitle}
          </Text>{' '}
          {selectedTask && (
            <Text as="span" inheritSize>
              to
            </Text>
          )}
        </>
      }
      initialFocusRef={searchInputElement}
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      onDismiss={reset}
      footerComponent={
        selectedTask && (
          <>
            <ButtonGhost onClick={reset}>{t('Cancel')}</ButtonGhost>
            <ButtonGhost onClick={doAssign}>{t('Assign')}</ButtonGhost>
          </>
        )
      }
    >
      {selectedTask ? (
        <Stack verticalMargin="small">
          <AssignItem
            mainText={selectedTask.taskName}
            secondaryText={selectedTask.customerName}
            tertiaryText={selectedTask.projectName}
            type={selectedTask.type}
          />
          <ButtonGhost onClick={() => setSelectedTask(undefined)} ref={changeButton}>
            {t('ChangeTask')}
          </ButtonGhost>
        </Stack>
      ) : (
        <Stack verticalMargin="medium">
          <SearchInput
            clearInput={() => setSearchValue('')}
            ref={searchInputElement}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
            value={searchValue}
            clearInputText=""
            label={t('SearchATaskOrProduct')}
            hiddenLabel
            placeholder={t('SearchATaskOrProduct')}
            autoComplete="off"
          />
          <Flex>
            <Icon iconName="info" />
            <Text size="tips">{t('SplitSearchHelpText')}</Text>
          </Flex>
          {searchValue && (
            <ResponseHandler
              isLoading={isInitialLoading}
              isError={isError}
              isEmpty={isSuccess && workItems.length === 0}
              LoadingComponent={
                <ScrollContainer>
                  <Stack verticalMargin="xxSmall">
                    <div>
                      <Shimmer minHeight="subHeading" width="quarter" />
                    </div>
                    <Shimmer minHeight="body" width="thirdQuarter" />
                  </Stack>
                </ScrollContainer>
              }
              ErrorComponent={
                <ScrollContainer>
                  <Text>{t('SearchATaskErrorMessage')}</Text>
                </ScrollContainer>
              }
              EmptyComponent={
                <ScrollContainer>
                  <Text>{t('SearchATaskNoResultsMessage')}</Text>
                </ScrollContainer>
              }
            >
              <ScrollContainer>
                {workItems.map((workItem: ISearchTask) => (
                  <AssignButton onClick={() => select(workItem)} key={workItem.id}>
                    <AssignItem
                      mainText={workItem.taskName}
                      secondaryText={workItem.customerName}
                      tertiaryText={workItem.projectName}
                      type={workItem.type}
                      searchText={searchValue}
                    />
                  </AssignButton>
                ))}
              </ScrollContainer>
            </ResponseHandler>
          )}
        </Stack>
      )}
    </AssignAction>
  );
};
