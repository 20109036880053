export default {
  Entities: [
    {
      Properties: {
        Name: 'Customer',
        IsSelected: true,
      },
    },
    {
      Properties: {
        Name: 'Contact',
        IsSelected: true,
      },
    },
    {
      Properties: {
        Name: 'Project',
        IsSelected: true,
      },
    },
    {
      Properties: {
        Name: 'Invoice',
        IsSelected: false,
      },
    },
    {
      Properties: {
        Name: 'Employee',
        IsSelected: false,
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/search/categories',
      Rel: 'self',
    },
  ],
};
