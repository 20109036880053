import { HTMLAttributes, useRef, useEffect } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  useMobileNavigationStore,
  useMobileNavigationDispatch,
} from 'src/stores/MobileNavigationStore';
import FeatureControl from 'src/components/utils/FeatureControl';
import ToggleNavigation from './components/ToggleNavigation';
import BookmarkNav from './components/BookmarkNav';
import MenuItems from './components/MenuItems';
import CreateSubNav from './components/CreateSubNav';
import styles from './Navigation.module.scss';

export const Navigation = (props: HTMLAttributes<HTMLElement>) => {
  const mobileNavigationDispatch = useMobileNavigationDispatch();
  const mobileNavigationState = useMobileNavigationStore();
  const mobileNavIsOpen = mobileNavigationState?.isMobileNavigationOpen;
  const { t } = useTranslation('header');
  const TriggerRef = useRef<HTMLButtonElement>(null);
  const WrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mobileNavIsOpen && WrapperRef) {
      if (WrapperRef.current) {
        WrapperRef.current.addEventListener('focusout', (event) => {
          if (!WrapperRef.current?.contains(event.relatedTarget as HTMLElement)) {
            if (TriggerRef && TriggerRef.current) {
              TriggerRef.current.focus();
            }

            // Focus has left the element
            return (
              mobileNavigationDispatch &&
              mobileNavigationDispatch({
                type: 'CLOSED',
              })
            );
          }

          return false;
        });

        WrapperRef.current.addEventListener('keydown', (event) => {
          if (event.key === 'Escape') {
            if (TriggerRef && TriggerRef.current) {
              TriggerRef.current.focus();
            }
            return (
              mobileNavigationDispatch &&
              mobileNavigationDispatch({
                type: 'CLOSED',
              })
            );
          }

          return false;
        });
      }
    }
  }, [mobileNavIsOpen, mobileNavigationDispatch]);

  return (
    <>
      {/* The navID and id of the nav must be equal */}
      <ToggleNavigation navId="main-navigation" ref={TriggerRef} />
      <div
        className={cx(styles.Navigation, {
          [styles.MobileNavIsOpen]: mobileNavIsOpen,
        })}
        ref={WrapperRef}
        data-automation-id="MainNavigationWrapper"
      >
        <nav
          id="main-navigation"
          className={styles.Wrapper}
          aria-label={t('PrimaryNavigationName')}
          {...props}
        >
          <ul className={styles.ParentList}>
            <MenuItems />
            <FeatureControl indexControlName="Bookmarks">
              <BookmarkNav />
            </FeatureControl>
            <CreateSubNav />
          </ul>
        </nav>
      </div>
    </>
  );
};
