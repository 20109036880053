import { toCamel } from 'src/utils/object';

export const searchEmptyResult = {
  Properties: {},
  Links: [
    {
      Href: 'https://app8.timelog.com/localarena/api/v2/search?searchtext=123',
      Rel: 'self',
    },
  ],
};

export const searchEmptyResultCameCase = toCamel(searchEmptyResult);

export default searchEmptyResult;
