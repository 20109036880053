import { useTranslation } from 'react-i18next';

export const useGreeting = (isLoading: boolean) => {
  const myDate = new Date();
  const hrs = myDate.getHours();
  const { t } = useTranslation('frontpage');

  const greet = () => {
    if (hrs >= 12 && hrs <= 17) return t('GreetingGoodAfternoon');
    if (hrs >= 17 && hrs <= 24) return t('GreetingGoodEvening');

    return t('GreetingGoodMorning');
  };

  return `${greet()}${!isLoading ? ', ' : ''}`;
};
