import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Paper,
  PaperProps,
} from 'src/components/mui-components';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import Draggable from 'react-draggable';

interface IReportingWidgetInfoModal {
  id: string;
  title: string;
  infoMessage: string;
  openState: boolean;
  setOpenState: React.Dispatch<React.SetStateAction<boolean>>;
}

const DraggablePaperComponent = ({ id, ...rest }: PaperProps) => {
  const nodeRef = useRef(null);
  return (
    <Draggable
      nodeRef={nodeRef}
      handle={`#InfoDialog-Title-${id}`}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <div ref={nodeRef}>
        <Paper {...rest} />
      </div>
    </Draggable>
  );
};
export const ReportingWidgetInfoModal = ({
  id,
  title,
  infoMessage,
  openState,
  setOpenState,
}: IReportingWidgetInfoModal) => {
  const { t } = useTranslation('reportingWidgets');

  return (
    <Dialog
      open={openState}
      onClose={() => setOpenState(false)}
      PaperComponent={DraggablePaperComponent}
      PaperProps={{ id }}
      aria-labelledby={`InfoDialog-Title-${id}`}
      aria-describedby={`InfoDialog-Description-${id}`}
      data-automation-id={`InfoDialog-${id}`}
    >
      <DialogTitle style={{ cursor: 'move' }} id={`InfoDialog-Title-${id}`}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={`InfoDialog-Description-${id}`}>{infoMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpenState(false)}
          data-automation-id={`${id}InfoModalCloseButton`}
        >
          <Typography>{t('ModalCloseButton').toUpperCase()}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ReportingWidgetInfoModal;
