export const newsFeedFullResult = `<?xml version="1.0" encoding="UTF-8" ?>
<rss version="2.0">
  <channel>
    <title>TimeLog Help RSS Feed</title>
    <link>http://help.timelog.com/en/feed/</link>
    <item>
      <title>TimeLog preview features: Put your mark on TimeLog&#39;s development</title>
      <link>https://www.timelog.com/en/about/preview-features/</link>
      <description>In TimeLog, we would like to involve you in our development process to give you the best release experience whenever we make new features. Preview features are your option to provide early feedback and input to our major features while we develop them. Preview features also give you the opportunity to start using the new features whenever you are ready for it. You can read more about preview features by clicking at the link in the headline.</description>
    </item>
    <item>
      <title>Free training: Get 20 min. introductions to the TimeLog system!</title>
      <link>https://www.timelog.com/en/events/power-sessions/</link>
      <description>Have you heard of our Power Sessions? Skilled TimeLoggers give you introductions to different functions in TimeLog directly in the system - in only 15-20 minutes. We have had sessions about e.g. TimeLog Mobile and One-Click Invoicing.  Click on the headline and see the different videos.</description>
    </item>
    <item>
      <title>Sign up to our newsletter</title>
      <link>https://info.timelog.com/en/sign-up-to-our-newsletter</link>
      <description>Stay tuned on everything from our webinars and Power Sessions to our exciting blog posts and tips and tricks from skilled TimeLoggers. Click at the headline to sign up to our newsletter.</description>
    </item>
    <item>
      <title>TimeLog has just obtained the two independent auditor statements ISAE 3000 and ISAE 3402</title>
      <link>https://www.timelog.com/en/about/isae-faq/</link>
      <description>At TimeLog, we believe that we as a service provider must ensure our customers that they don’t take any extra risks by giving us the responsibility for parts of their business and data. Therefore, we’re committed to work with a certified auditor to obtain both the ISAE 3000 GDPR and ISAE 3402 declarations as a continuous, yearly target in our compliance and information security work. You can get a hold of our reports by clicking on the headline.</description>
    </item>
    <item>
      <title>Have you seen our video guides on YouTube?</title>
      <link>https://www.youtube.com/channel/UCjLbuOIIvMPsBWglI0wjlFg</link>
      <description>On our YouTube channel, we have a lot of different how to videos that help you with everything from time tracking to creating new projects and how to use our TimeLog Mobile. Visit our YouTube channel by clicking at the link in the headline.</description>
    </item>
    <item>
      <title>Do you want to be one step ahead of TimeLog&#39;s development?</title>
      <link>https://timelog.zendesk.com/hc/da/categories/115000738969-Release-notes</link>
      <description>By signing up to our release notes, you are always updated on our latest products news and planned features. Would you like to sign up? Then click at the links in the headline.</description>
    </item>
  </channel>
</rss>`;
