import { Text, Stack } from 'src/components/ui-components';
import { useTranslation } from 'react-i18next';
import { translationAnyText } from 'src/utils/translation';
import WidgetHeader from 'src/screens/Home/components/WidgetHeader';
import WidgetWithoutChartShimmer from '../WidgetWithoutChartShimmer';
import WidgetError from '../WidgetError';
import { IWidgetWithoutChart } from '../../HomeType';
import { ValueText } from './components/ValueText';
import styles from './WidgetWithoutChart.module.scss';

export default ({
  queryKey,
  informationList,
  tips,
  relId,
  isLoading = false,
  isError = false,
}: IWidgetWithoutChart) => {
  const { t } = useTranslation('frontpage');

  if (isLoading) {
    return <WidgetWithoutChartShimmer />;
  }
  if (isError) {
    return <WidgetError queryKey={queryKey} />;
  }

  return (
    <div className={styles.widgetWrapper}>
      <Stack>
        <WidgetHeader widgetKey={relId} heading={t(`WidgetTitle${relId}`)} />

        <Stack as="dl">
          {informationList.map(
            ({ value, trending = null, trendingDirection, key, abbrTitle, abbrText }) => (
              <div className={styles.defWrapper} key={`stack${key}`}>
                {translationAnyText(t, `Widget${relId}${key}Name`) !==
                  `Widget${relId}${key}Name` && (
                  <Text as="dt" size="tips" data-automation-id={`Widget${relId}${key}Name`}>
                    {translationAnyText(t, `Widget${relId}${key}Name`)}
                  </Text>
                )}
                <ValueText
                  value={value}
                  trending={trending}
                  trendingDirection={trendingDirection}
                  key={key}
                  data-automation-id={`Widget${relId}${key}Value`}
                  translationKey={`Widget${relId}${key}Value`}
                  abbrText={abbrText}
                  abbrTitle={abbrTitle}
                />
              </div>
            ),
          )}
        </Stack>
      </Stack>
      <div className={styles.tips}>{tips}</div>
    </div>
  );
};
