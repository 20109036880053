export default {
  Properties: {
    activeCount: 4,
    totalCount: 10,
  },
  Entities: [
    {
      Properties: {
        Name: 'DesignSeven',
        FeatureIdentifier: 'DesignSeven',
        FeatureStage: 'SneakPeek',
        ReleaseNumber: '',
      },
    },
    {
      Properties: {
        Name: 'ProjectPlanAmountNew',
        FeatureIdentifier: 'ProjectPlanAmountNew',
        FeatureStage: 'Beta',
        ReleaseNumber: '',
      },
    },
  ],
  Links: [{ Href: 'http://localhost/timelog/api/v2/user-preview-features', Rel: 'self' }],
};
