import { useEffect, RefObject, Dispatch, SetStateAction } from 'react';

export const useIsThereRoomForTheText = (
  textRef: RefObject<HTMLAnchorElement>,
  ellipsisIsActive: boolean,
  setEllipsisIsActive: Dispatch<SetStateAction<boolean>>,
) =>
  useEffect(() => {
    if (textRef.current) {
      setEllipsisIsActive(textRef.current.scrollWidth > textRef.current.offsetWidth);
    }
  }, [ellipsisIsActive, textRef, setEllipsisIsActive]);
