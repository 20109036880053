import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { getFetch } from 'src/utils/fetcher';
import { useMainSearchDispatch } from 'src/stores/MainSearchStore';
import { ISearchCategories } from 'src/apis/types/searchCategoriesAPI';

const SEARCH_CATEGORIES_URL_PATH = '/api/v2/search/categories';
export const GET_SEARCH_CATEGORIES_MSW_STATUS_KEY = 'getSearchCategoriesAPI';
export const GET_SEARCH_CATEGORIES_KEY = 'GET_SEARCH_CATEGORIES_KEY';

const getSearchCategory = (): Promise<ISearchCategories> =>
  getFetch({
    path: SEARCH_CATEGORIES_URL_PATH,
    key: GET_SEARCH_CATEGORIES_MSW_STATUS_KEY,
  });

export const useGetSearchCategory = () => {
  const mainSearchDispatch = useMainSearchDispatch();
  const { data, ...restProps } = useQuery([GET_SEARCH_CATEGORIES_KEY], () => getSearchCategory());
  useEffect(() => {
    if (mainSearchDispatch) {
      mainSearchDispatch({
        type: 'UPDATE_CATEGORY_LIST',
        payload: data?.entities?.map(({ properties: { ...properties } }) => properties) || [],
      });
    }
  }, [mainSearchDispatch, data]);

  if (data) {
    return {
      searchCategories:
        data?.entities?.map(({ properties: { ...properties } }) => properties) || [],
      isSearchCategoryListEmpty: (data?.entities?.length || 0) <= 0,
      ...restProps,
    };
  }
  return {
    searchCategories: [],
    isSearchCategoryListEmpty: true,
    ...restProps,
  };
};
