import { toCamel } from 'src/utils/object';
import { escapeSearchText } from 'src/utils/string';

export const searchFullResult = {
  Properties: {
    Customers: [
      {
        Id: 123,
        Name: 'CustomerName (1123)',
        ImageUrl: 'https://i.pravatar.cc/40',
        PageUrl: 'http://localhost/tlp/CRM/Customer/Edit/671?order=0',
      },
      {
        Id: 14,
        Name: 'CustomerName (1456)',
        ImageUrl: 'https://i.pravatar.cc/41',
        PageUrl: 'http://localhost/tlp/CRM/Customer/Edit/672?order=0',
      },
      {
        Id: 16,
        Name: 'CustomerName (1234)',
        ImageUrl: 'https://i.pravatar.cc/42',
        PageUrl: 'http://localhost/tlp/CRM/Customer/Edit/673?order=0',
      },
      {
        Id: 18,
        Name: 'CustomerName (1345)',
        ImageUrl: 'https://i.pravatar.cc/43',
        PageUrl: 'http://localhost/tlp/CRM/Customer/Edit/674?order=0',
      },
      {
        Id: 20,
        Name: 'CustomerName (1678)',
        ImageUrl: 'https://i.pravatar.cc/44',
        PageUrl: 'http://localhost/tlp/CRM/Customer/Edit/675?order=0',
      },
    ],
    Contacts: [
      {
        Id: 123,
        Name: 'Contacts (1123)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/Organization/Employee/EditEmployee/1',
      },
      {
        Id: 14,
        Name: 'Contacts (1234)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/Organization/Employee/EditEmployee/2',
      },
      {
        Id: 16,
        Name: 'Contacts (1345)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/Organization/Employee/EditEmployee/3',
      },
      {
        Id: 18,
        Name: 'Contacts (1456)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/Organization/Employee/EditEmployee/4',
      },
      {
        Id: 20,
        Name: 'Contacts (1567)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/Organization/Employee/EditEmployee/5',
      },
    ],
    Projects: [
      {
        Id: 123,
        Name: 'ProjectName (1123)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/ProjectManagement/Plan/Task/1',
      },
      {
        Id: 14,
        Name: 'ProjectName (1456)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/ProjectManagement/Plan/Task/2',
      },
      {
        Id: 16,
        Name: 'ProjectName (1789)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/ProjectManagement/Plan/Task/3',
      },
      {
        Id: 18,
        Name: 'ProjectName (1234)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/ProjectManagement/Plan/Task/4',
      },
      {
        Id: 20,
        Name: 'ProjectName (1567)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/ProjectManagement/Plan/Task/5',
      },
    ],
    Invoices: [
      {
        Id: 123,
        Name: 'Invoices (1123)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/Financial/Invoicing/NewInvoice',
      },
      {
        Id: 14,
        Name: 'Invoices (1789)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/Financial/Invoicing/NewInvoice',
      },
      {
        Id: 16,
        Name: 'Invoices (1234)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/Financial/Invoicing/NewInvoice',
      },
      {
        Id: 18,
        Name: 'Invoices (1345)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/Financial/Invoicing/NewInvoice',
      },
      {
        Id: 20,
        Name: 'Invoices (1567)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/Financial/Invoicing/NewInvoice',
      },
    ],
    Employees: [
      {
        Id: 123,
        Name: 'Employees (1123)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/Organization/Employee/EditEmployee/1',
      },
      {
        Id: 14,
        Name: 'Employees (1456)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/Organization/Employee/EditEmployee/2',
      },
      {
        Id: 16,
        Name: 'Employees (1789)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/Organization/Employee/EditEmployee/3',
      },
      {
        Id: 18,
        Name: 'Employees (1936)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/Organization/Employee/EditEmployee/4',
      },
      {
        Id: 20,
        Name: 'Employees (1852)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/Organization/Employee/EditEmployee/5',
      },
    ],
  },
  Links: [
    {
      Href: 'https://app8.timelog.com/localarena/api/v2/search?searchtext=123',
      Rel: 'self',
    },
  ],
};
interface IFilterSearchProps {
  Name: string;
}

export const searchFilterResult = (searchText: string, searchCategory = '') => {
  const searchRegExp = new RegExp(escapeSearchText(searchText), 'i');

  const filterSearch = ({ Name }: IFilterSearchProps) => searchRegExp.test(Name);
  const getFilterCategoryResult = (category: string, array: any[]) => {
    if (!searchCategory) return array;
    return searchCategory.indexOf(category) >= 0 ? array : [];
  };
  const {
    Properties: { Customers, Contacts, Invoices, Projects, Employees },
    Links,
  } = searchFullResult;
  return {
    Properties: {
      Customers: getFilterCategoryResult('Customer', Customers.filter(filterSearch) || []),
      Contacts: getFilterCategoryResult('Contact', Contacts.filter(filterSearch) || []),
      Invoices: getFilterCategoryResult('Invoice', Invoices.filter(filterSearch) || []),
      Projects: getFilterCategoryResult('Project', Projects.filter(filterSearch) || []),
      Employees: getFilterCategoryResult('Employee', Employees.filter(filterSearch) || []),
    },
    Links,
  };
};

export const searchFullResultCameCase = toCamel(searchFullResult);

export default searchFullResult;
