import { useMemo } from 'react';

const OS = {
  WINDOWS: 'windows',
  MAC: 'mac',
  LINUX: 'linux',
  UNKNOWN: 'unknown',
};

const getOS = (): string => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.includes('win')) {
    return OS.WINDOWS;
  }
  if (userAgent.includes('mac')) {
    return OS.MAC;
  }
  if (userAgent.includes('linux')) {
    return OS.LINUX;
  }
  return OS.UNKNOWN;
};

export const getOSControlKey = () => {
  const os = getOS();

  switch (os) {
    case OS.WINDOWS:
      return 'Ctrl';
    case OS.MAC:
      return '⌘';
    default:
      return 'Ctrl';
  }
};

export const useBuildShortcutString = () => {
  const controlKey = useMemo(getOSControlKey, []);

  const shortcutString = (description: string, shortcutKey?: string) => {
    if (shortcutKey) {
      return `${description} (${controlKey} + ${shortcutKey})`;
    }
    return description;
  };
  return shortcutString;
};
