import { createContext, AnchorHTMLAttributes, useContext, ElementType } from 'react';

interface IDefaultLink extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: JSX.Element;
}

export const LinkContext = createContext<ElementType>(({ children, ...props }: IDefaultLink) => (
  <a {...props}>{children}</a>
));

export const useGetLink = (): ElementType => useContext(LinkContext);
export const LinkProviderForTest = LinkContext.Provider;

interface IPageStoreProps {
  children: JSX.Element | JSX.Element[];
  value: ElementType;
}

export const LinkProvider = ({ children, value }: IPageStoreProps) => (
  <LinkContext.Provider value={value}>{children}</LinkContext.Provider>
);
