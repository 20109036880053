import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { ITlpCountResponse } from 'src/apis/types/resourcePlannerAPI';

const TLP_PROJECT_COUNT_URL_PATH = '/api/v2/resource-planner/tlp-all-project-count';
export const TLP_PROJECT_COUNT_MSW_STATUS_KEY = 'GetTlpProjectCount';

export const TLP_PROJECT_COUNT_KEY = 'TLP_PROJECT_COUNT';
const oneSecond = 1000;

const resourcePlannerFetch = (): Promise<ITlpCountResponse> =>
  getFetch({ path: TLP_PROJECT_COUNT_URL_PATH, key: TLP_PROJECT_COUNT_MSW_STATUS_KEY });

const useGetTlpProjectCountQuery = (refetch: boolean) =>
  useQuery([TLP_PROJECT_COUNT_KEY], resourcePlannerFetch, {
    refetchInterval: refetch ? oneSecond : undefined,
  });

export const useGetTlpProjectCount = (refetch: boolean) => {
  const { data, ...restProps } = useGetTlpProjectCountQuery(refetch);

  return {
    count: data?.properties.count ?? 0,
    ...restProps,
  };
};
