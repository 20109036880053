import { getFetch } from 'src/utils/fetcher';
import { useQuery } from '@tanstack/react-query';
import { formatUrl } from 'src/utils/url';
import ITimeTracker from 'src/apis/types/timeTrackerAPI';
import { useGetServerTimeZoneId } from 'src/apis/indexAPI';
import { getHourAndMinFromTimeTrackerResult } from 'src/utils/time/timeTrackerTime';
import {
  TIME_TRACKER_MENU_URL_PATH,
  GET_TIME_TRACKER_MSW_STATUS_KEY,
  GET_TIME_TRACKER_KEY,
} from '../utils';

const getTimeTracker = (): Promise<ITimeTracker> =>
  getFetch({ path: TIME_TRACKER_MENU_URL_PATH, key: GET_TIME_TRACKER_MSW_STATUS_KEY });

const fifteenMin = 1000 * 60 * 15;

export const useGetTimeTracker = () => {
  const { data, ...restProps } = useQuery([GET_TIME_TRACKER_KEY], getTimeTracker, {
    refetchInterval: fifteenMin,
    refetchIntervalInBackground: true,
  });
  const { serverTimeZoneId } = useGetServerTimeZoneId();
  const { properties: timeTrackerData, actions: timeTrackerAction } = data || {};
  const stopPath = timeTrackerAction?.find((action) => action.name === 'stop')?.href;

  return {
    data,
    serverTimeZoneId,
    time: getHourAndMinFromTimeTrackerResult(data, serverTimeZoneId),
    stopPath: formatUrl(stopPath),
    details: timeTrackerData,
    ...restProps,
  };
};
