import { ReactNode, RefObject, useEffect, useState } from 'react';
import { Box } from 'src/components/ui-components/Box';
import { ButtonGhost } from 'src/components/ui-components/Button';
import { Flex } from 'src/components/ui-components/Flex';
import { Stack } from 'src/components/ui-components/Stack';
import { Text } from 'src/components/ui-components/Text';

interface FilterContainerProps {
  openFilterPanel: () => void;
  activateSidePanelButtonRef?: RefObject<HTMLButtonElement>;
  activeFilters: number;
  clearAllFilters?: () => void;
  children?: ReactNode;
  searchInput?: ReactNode;
  secondaryActions?: ReactNode;
  filterButtonText?: string;
  noActiveFiltersText?: string;
  addFilterButtonText?: string;
  clearAllFiltersButtonText?: string;
  initialActivePanelIsOpen?: boolean;
  localStorageNamePrefix: string;
}

export const FilterContainer = ({
  openFilterPanel,
  activateSidePanelButtonRef,
  clearAllFilters,
  children,
  searchInput,
  activeFilters,
  secondaryActions,
  filterButtonText = 'Filter',
  noActiveFiltersText = 'Add filters to narrow down results',
  addFilterButtonText = 'Add filter',
  clearAllFiltersButtonText = 'Clear filters',
  initialActivePanelIsOpen = false,
  localStorageNamePrefix = '',
}: FilterContainerProps) => {
  const localStoragePrefix = `${localStorageNamePrefix}-`;
  const filterIsOpenLocal = localStorage.getItem(`${localStoragePrefix}filter-is-open`) === 'true';
  const [filterIsOpen, setfilterisOpen] = useState<boolean>(filterIsOpenLocal || false);
  useEffect(() => {
    localStorage.setItem(`${localStoragePrefix}filter-is-open`, JSON.stringify(filterIsOpen));
  }, [filterIsOpen, localStoragePrefix]);

  const [activeFiltersPanelIsOpen, setActiveFiltersPanelIsOpen] = useState(
    filterIsOpenLocal || initialActivePanelIsOpen,
  );
  const showResetButton = clearAllFilters && activeFilters;

  const toggleFilterPanel = () => {
    setfilterisOpen(!filterIsOpen);
    setActiveFiltersPanelIsOpen((prev) => !prev);
  };

  return (
    <Stack verticalMargin="medium">
      <Flex horizontalAlignment={searchInput ? 'justify' : 'right'}>
        {searchInput}

        <Flex>
          <ButtonGhost
            btnIconLeft="filter"
            size="compact"
            onClick={() => toggleFilterPanel()}
            aria-controls="active-filters-container"
            aria-expanded={activeFiltersPanelIsOpen}
            data-automation-id="FilterContainerToggleFilter"
          >
            {filterButtonText}
            {activeFilters > 0 && (
              <Text as="span" bold data-automation-id="FilterContainerAddFilterCount">
                ({activeFilters})
              </Text>
            )}
          </ButtonGhost>
          {secondaryActions}
        </Flex>
      </Flex>

      <Box
        id="active-filters-container"
        hidden={!activeFiltersPanelIsOpen}
        data-automation-id="FilterContainerActiveFiltersContainer"
      >
        <Box.Item compact>
          <Flex verticalAlignment={activeFilters ? 'top' : 'center'} horizontalAlignment="justify">
            <Flex verticalAlignment="center" wrap>
              {activeFilters ? (
                children
              ) : (
                <Text italic data-automation-id="FilterContainerNoResultsText">
                  {noActiveFiltersText}
                </Text>
              )}
            </Flex>

            <Flex gap="large">
              <ButtonGhost
                btnIconLeft="plus"
                size="compact"
                onClick={openFilterPanel}
                data-automation-id="FilterContainerAddFilter"
                ref={activateSidePanelButtonRef}
              >
                {addFilterButtonText}
              </ButtonGhost>
              {showResetButton ? (
                <ButtonGhost
                  btnIconLeft="cross"
                  size="compact"
                  onClick={clearAllFilters}
                  data-automation-id="FilterContainerRemoveFilters"
                >
                  {clearAllFiltersButtonText}
                </ButtonGhost>
              ) : null}
            </Flex>
          </Flex>
        </Box.Item>
      </Box>
    </Stack>
  );
};

export default FilterContainer;
