import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { parseMenu } from 'src/utils/menu';
import { formatUrl } from 'src/utils/url';
import { IMenus } from 'src/apis/types/menusAPI';
import { useTranslation } from 'react-i18next';
import { designSevenPagePath } from 'src/designSevenPagePath';

const MAIN_MENU_URL_PATH = '/api/v2/menus/main';
export const MAIN_MENU_MSW_STATUS_KEY = 'menuAPI';

export const MAIN_MENU_KEY = 'MAIN';

const mainMenuFetch = (): Promise<IMenus> =>
  getFetch({ path: MAIN_MENU_URL_PATH, key: MAIN_MENU_MSW_STATUS_KEY });

const useGetMainQuery = () => useQuery([MAIN_MENU_KEY], mainMenuFetch, { refetchOnMount: true });

export const useGetMainMenu = () => {
  const { data, ...restProps } = useGetMainQuery();
  const { t } = useTranslation('navigation');

  return {
    menuData: parseMenu(data, t),
    ...restProps,
  };
};

export const useGetHomePageUrl = () => {
  const { data, ...restProps } = useGetMainQuery();

  const home =
    data?.entities?.find(
      (item) =>
        item?.properties?.pageIdentifier === 'DesignSevenFrontPage' ||
        item?.properties?.pageIdentifier === 'Home',
    ) || {};

  const homeUrl =
    home?.properties?.pageIdentifier === 'DesignSevenFrontPage'
      ? designSevenPagePath.home
      : formatUrl(home?.properties?.pageUrl);

  return {
    homeUrl,
    ...restProps,
  };
};
