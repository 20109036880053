import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps,
  AccordionSummaryProps,
  AccordionDetailsProps,
  SxProps,
} from '@mui/material';

// Make it separate style due to the design not using
// MUI pattern, will change in future
const borderlessStyles: SxProps = {
  boxShadow: 'none',
  paddingBottom: '8px',
  '.MuiButtonBase-root': {
    padding: 0,
    borderBottom: '1px solid #dedede',
  },
  '&.MuiPaper-root:before': {
    display: 'none',
  },
  '.MuiTypography-root': {
    fontSize: 'inherit',
    fontWeight: '700',
    marginBottom: 0,
  },
  '.MuiAccordionDetails-root': {
    padding: '8px 0',
  },
};

interface IAccordionProps extends AccordionProps {
  borderless?: boolean;
}

export const Accordion = ({ borderless = false, sx, ...rest }: IAccordionProps) => (
  <MuiAccordion sx={{ ...(borderless && borderlessStyles), ...sx }} {...rest} />
);
export const AccordionSummary = ({ ...rest }: AccordionSummaryProps) => (
  <MuiAccordionSummary {...rest} />
);
export const AccordionDetails = ({ ...rest }: AccordionDetailsProps) => (
  <MuiAccordionDetails {...rest} />
);
