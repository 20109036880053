import { ButtonHTMLAttributes } from 'react';
import { TPreselectValues } from '../../types';

import styles from './PreselectButton.module.scss';

interface PreselectButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  value: TPreselectValues;
}

export const PreselectButton = ({ type, value, children, ...props }: PreselectButtonProps) => (
  <button type="button" className={styles.preselectButton} value={value} {...props}>
    {children}
  </button>
);
