import { toCamel } from 'src/utils/object';

const pageSettings = {
  Entities: [
    {
      Properties: {
        Name: 'Register',
        Type: 'PageSetting',
        IsParent: true,
        SubMenus: [
          {
            PageId: 'f0dec4f8-8d4a-4b7f-8021-cedd68f1dbbb',
            Name: 'Time',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/TimeTracking/AllViews?id=361',
            PageIdentifier: 'TimeTrackingBase',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        Name: 'Reports',
        Type: 'PageSetting',
        IsParent: true,
        SubMenus: [
          {
            PageId: '2fdfedd6-d5cf-45ed-b37f-ea2866f7c110',
            Name: 'Absence calendar',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/PageFilters/FilterSettings?id=1059',
            PageIdentifier: 'AbsenceCalendar',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '03ea89be-5c8b-4710-b385-982ffb7f306d',
            Name: 'Data extraction: Absence and salary codes',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/PageFilters/FilterSettings?id=151',
            PageIdentifier: 'DataExtractionAbsenceAndSalaryCodes',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'd28a53ec-09ba-43a1-8270-f6b03d4ea6fc',
            Name: 'Data extraction: Contract baselines',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/PageFilters/FilterSettings?id=350',
            PageIdentifier: 'DataExtractionProjectContractBaseline',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '9c86cb80-54cf-43be-adca-a5f795953e3b',
            Name: 'Data extraction: Contracts',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/PageFilters/FilterSettings?id=358',
            PageIdentifier: 'DataExtractionProjectSubContract',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '925804c4-06c4-44a5-b489-d43203899fc5',
            Name: 'Expense overview',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/PageFilters/FilterSettings?id=1093',
            PageIdentifier: 'ExpenseOverviewReport',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '05da75b8-c8a2-47b5-b6d1-82f4dba7e971',
            Name: 'Holiday calendar',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/PageFilters/FilterSettings?id=1060',
            PageIdentifier: 'CorporateHolidayCalendar',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '62dba897-b03c-4f33-9c3a-ce1c124b1663',
            Name: 'Intercompany analysis',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/PageFilters/FilterSettings?id=117',
            PageIdentifier: 'DepartmentProjectsEmployees',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '1edd51f0-e090-4c5c-99d2-91f5b85dda7b',
            Name: 'Milestones',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/PageFilters/FilterSettings?id=1070',
            PageIdentifier: 'MilestonesReport',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '61c4ac37-2c94-4373-92da-064b9320587a',
            Name: 'Reports – list view',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/Reports/AllViews?id=60',
            PageIdentifier: 'ReportBoxView',
            PageType: 'LinkToPage',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'eb2c13d5-6428-4013-9a7b-25f103648388',
            Name: 'Reports – table view',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/Reports/AllViews?id=59',
            PageIdentifier: 'ReportTableView',
            PageType: 'LinkToPage',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'f9ad7798-63ca-4d41-b635-b918b6f5e68c',
            Name: 'Salary account specification',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/PageFilters/FilterSettings?id=385',
            PageIdentifier: 'SalaryAccountSpecification',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '266bdedf-9e70-4095-b7c4-d7daf8d3e901',
            Name: 'Salary specification (all employees)',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl:
              'http://localhost/tlp/PersonalPageSettings/Reports/SalaryAccountSpecificationAll?id=1076',
            PageIdentifier: 'SalaryAccountSpecificationAll',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '26539faa-9951-4170-b761-3cdb07105845',
            Name: 'Submit/print personal expenses',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/PageFilters/FilterSettings?id=61',
            PageIdentifier: 'ExpenseReportSubmitPrint',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '8d90180d-f7c8-4399-849a-addd449c591a',
            Name: 'Weekly timesheet status',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/PageFilters/FilterSettings?id=121',
            PageIdentifier: 'StatusForTimeReports',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        Name: 'Projects',
        Type: 'PageSetting',
        IsParent: true,
        SubMenus: [
          {
            PageId: 'a0570ad2-c4bd-4815-bdb3-bdd421dd4d21',
            Name: 'Search projects',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/PageFilters/FilterSettings?id=16',
            PageIdentifier: 'SearchProject',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'eaa42259-93a5-40be-b142-f99de32be7b4',
            Name: 'Adjust project payments',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/Adjustment/AllViews?id=356',
            PageIdentifier: 'AdjustProjectPayments',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'b04a1291-20c9-471b-8963-18f04529c63e',
            Name: 'Index hourly rates',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/PageFilters/FilterSettings?id=1122',
            PageIdentifier: 'IndexationOfHourlyRateByPercentage',
            PageType: 'LinkToPage',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '85d44583-73c1-4ca5-97d8-bdc629d93fd8',
            Name: 'Index hourly rates',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/PageFilters/FilterSettings?id=1123',
            PageIdentifier: 'IndexationOfPriceLists',
            PageType: 'LinkToPage',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '73308b42-60c9-4277-ab72-2144b09ac828',
            Name: 'Indexation overview',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/PageFilters/FilterSettings?id=1131',
            PageIdentifier: 'HistoricalIndexedHourlyRates',
            PageType: 'LinkToPage',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        Name: 'Employees',
        Type: 'PageSetting',
        IsParent: true,
        SubMenus: [
          {
            PageId: '466d91d9-be4f-45ae-8653-af733a491c47',
            Name: 'Employee overview',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/EmployeeOverview/AllViews?id=162',
            PageIdentifier: 'EmployeeOverviewTableView',
            PageType: 'LinkToPage',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '3c2ea1f0-1871-4316-80cd-dd7118abce13',
            Name: 'Salary management',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/PageFilters/FilterSettings?id=382',
            PageIdentifier: 'SalaryManagementOverview',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        Name: 'Approval',
        Type: 'PageSetting',
        IsParent: true,
        SubMenus: [
          {
            PageId: 'afb84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'Approve time',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/Approval/AllViews?id=29',
            PageIdentifier: 'ApproveTimeReports',
            PageType: 'ConnectedPage',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        Name: 'Invoices',
        Type: 'PageSetting',
        IsParent: true,
        SubMenus: [
          {
            PageId: 'a0b84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'Invoice drafts',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/PageFilters/FilterSettings?id=22',
            PageIdentifier: 'SearchInvoice',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '35ea40f3-3e65-4c59-aa46-6c4891ce10f8',
            Name: 'Invoice archive',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/PageFilters/FilterSettings?id=352',
            PageIdentifier: 'InvoiceArchive',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'cad065b6-336b-44c4-8bbf-d8a307836d28',
            Name: 'Debtor list – Invoices',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/PageFilters/FilterSettings?id=1071',
            PageIdentifier: 'NewDebtorListInvoice',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '20a1d221-78bd-41a4-aea4-1f7bdc9a46ef',
            Name: 'Debtor list – Vouchers',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/PageFilters/FilterSettings?id=1072',
            PageIdentifier: 'NewDebtorListVoucher',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'a3b83567-5c4b-e221-9d7f-9c1f9fd22d51',
            Name: 'Accounting periods',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/AccountingPeriod?id=348',
            PageIdentifier: 'InvoiceAccountingPeriod',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        Name: 'Create',
        Type: 'PageSetting',
        IsParent: true,
        SubMenus: [
          {
            PageId: '3edd0f43-2040-44c6-a9e6-9e6bf614dd9f',
            Name: 'Import personal expenses',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/ImportExpenses/Index?id=8',
            PageIdentifier: 'ImportEmployeeExpenses',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'cd7456b5-2fb0-4160-9f58-14288c8ca763',
            Name: 'Import project expenses',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl:
              'http://localhost/tlp/PersonalPageSettings/ImportProjectExpenses/Index?id=1089',
            PageIdentifier: 'ImportProjectExpenses',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '8eb84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'New customer',
            Type: 'PageSetting',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/PersonalPageSettings/Customer/AllViews?id=14',
            PageIdentifier: 'CRMCustomerNew',
            PageType: 'ConnectedPage',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
  ],
  Links: [{ Href: 'http://localhost/tlp/api/v2/menus/page-settings', Rel: 'self' }],
};

export const pageSettingsCamelCase = toCamel(
  pageSettings.Entities?.map(({ Properties: { ...restProps } }) => restProps),
);

export default pageSettings;
