export const themeColors = {
  Blue: 'ThemeBlue',
  Green: 'ThemeGreen',
  Red: 'ThemeRed',
  Purple: 'ThemePurple',
  Orange: 'ThemeOrange',
  Turquoise: 'ThemeTurquoise',
  Pink: 'ThemePink',
} as const;
export type ThemeColors = typeof themeColors[keyof typeof themeColors];

export type TBackgroundColors =
  | 'bgGray'
  | 'bgLightGray'
  | 'bgPink'
  | 'bgAlert'
  | 'bgWarning'
  | 'bgSuccess'
  | 'bgWhite';
