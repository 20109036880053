export default {
  Entities: [
    {
      Properties: {
        Id: 19,
        Name: 'Submit/print personal expenses',
        Url: 'http://localhost/tlp/Reports/Personal/ExpenseReportSubmitPrint',
      },
      Actions: [
        {
          Name: 'create',
          Title: 'Create bookmark',
          Method: 'Post',
          Href: 'http://localhost/timelog/api/v2/bookmarks',
          Type: 'application/json',
        },
        {
          Name: 'delete',
          Title: 'Delete bookmark',
          Method: 'Delete',
          Href: 'http://localhost/timelog/api/v2/bookmarks/19',
          Type: 'application/json',
        },
      ],
    },
    {
      Properties: {
        Id: 21,
        Name: 'Time registrations – summarised',
        Url: 'http://localhost/tlp/Reports/Employee/TimeRegistrationsSummarised',
      },
      Actions: [
        {
          Name: 'create',
          Title: 'Create bookmark',
          Method: 'Post',
          Href: 'http://localhost/timelog/api/v2/bookmarks',
          Type: 'application/json',
        },
        {
          Name: 'delete',
          Title: 'Delete bookmark',
          Method: 'Delete',
          Href: 'http://localhost/timelog/api/v2/bookmarks/21',
          Type: 'application/json',
        },
      ],
    },
    {
      Properties: {
        Id: 22,
        Name: 'Work – project types',
        Url: 'http://localhost/tlp/Reports/Analysis/WorkProjectTypes',
      },
      Actions: [
        {
          Name: 'create',
          Title: 'Create bookmark',
          Method: 'Post',
          Href: 'http://localhost/timelog/api/v2/bookmarks',
          Type: 'application/json',
        },
        {
          Name: 'delete',
          Title: 'Delete bookmark',
          Method: 'Delete',
          Href: 'http://localhost/timelog/api/v2/bookmarks/22',
          Type: 'application/json',
        },
      ],
    },
    {
      Properties: {
        Id: 23,
        Name: 'Internal/external – analysis',
        Url: 'http://localhost/tlp/Reports/KeyFigure/InternalExternalAnalysis',
      },
      Actions: [
        {
          Name: 'create',
          Title: 'Create bookmark',
          Method: 'Post',
          Href: 'http://localhost/timelog/api/v2/bookmarks',
          Type: 'application/json',
        },
        {
          Name: 'delete',
          Title: 'Delete bookmark',
          Method: 'Delete',
          Href: 'http://localhost/timelog/api/v2/bookmarks/23',
          Type: 'application/json',
        },
      ],
    },
    {
      Properties: {
        Id: 24,
        Name: 'Invoicing percentage',
        Url: 'http://localhost/tlp/Reports/KeyFigure/InvoicingPercentage',
      },
      Actions: [
        {
          Name: 'create',
          Title: 'Create bookmark',
          Method: 'Post',
          Href: 'http://localhost/timelog/api/v2/bookmarks',
          Type: 'application/json',
        },
        {
          Name: 'delete',
          Title: 'Delete bookmark',
          Method: 'Delete',
          Href: 'http://localhost/timelog/api/v2/bookmarks/24',
          Type: 'application/json',
        },
      ],
    },
    {
      Properties: {
        Id: 25,
        Name: 'Full project overview',
        Url: 'http://localhost/tlp/Reports/Production/ProjectOverview',
      },
      Actions: [
        {
          Name: 'create',
          Title: 'Create bookmark',
          Method: 'Post',
          Href: 'http://localhost/timelog/api/v2/bookmarks',
          Type: 'application/json',
        },
        {
          Name: 'delete',
          Title: 'Delete bookmark',
          Method: 'Delete',
          Href: 'http://localhost/timelog/api/v2/bookmarks/25',
          Type: 'application/json',
        },
      ],
    },
    {
      Properties: {
        Id: 27,
        Name: 'Payment plans – overview',
        Url: 'http://localhost/tlp/Reports/Production/PaymentPlansOverview',
      },
      Actions: [
        {
          Name: 'create',
          Title: 'Create bookmark',
          Method: 'Post',
          Href: 'http://localhost/timelog/api/v2/bookmarks',
          Type: 'application/json',
        },
        {
          Name: 'delete',
          Title: 'Delete bookmark',
          Method: 'Delete',
          Href: 'http://localhost/timelog/api/v2/bookmarks/27',
          Type: 'application/json',
        },
      ],
    },
    {
      Properties: {
        Id: 28,
        Name: 'Allocated/registered time',
        Url: 'http://localhost/tlp/Reports/Production/AllocatedActualWork',
      },
      Actions: [
        {
          Name: 'create',
          Title: 'Create bookmark',
          Method: 'Post',
          Href: 'http://localhost/timelog/api/v2/bookmarks',
          Type: 'application/json',
        },
        {
          Name: 'delete',
          Title: 'Delete bookmark',
          Method: 'Delete',
          Href: 'http://localhost/timelog/api/v2/bookmarks/28',
          Type: 'application/json',
        },
      ],
    },
    {
      Properties: {
        Id: 29,
        Name: 'Front page',
        Url: 'http://localhost/tlp/Home/FrontPage',
      },
      Actions: [
        {
          Name: 'create',
          Title: 'Create bookmark',
          Method: 'Post',
          Href: 'http://localhost/timelog/api/v2/bookmarks',
          Type: 'application/json',
        },
        {
          Name: 'delete',
          Title: 'Delete bookmark',
          Method: 'Delete',
          Href: 'http://localhost/timelog/api/v2/bookmarks/29',
          Type: 'application/json',
        },
      ],
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/bookmarks',
      Rel: 'self',
    },
  ],
};
