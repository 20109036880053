export const getKeyboardFocusableElements = (element: HTMLElement | null): Element[] => {
  if (element) {
    const elements = element.querySelectorAll(
      "a:not([hidden]), button, input, textarea, select, details,[tabindex]:not([tabindex='-1'])",
    );

    return Array.from(elements).filter((el) => !el.hasAttribute('disabled'));
  }
  return [];
};

export const userHasAskedToReduceMotion =
  window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;
