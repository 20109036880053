import { Text } from 'src/components/ui-components/Text';

interface FilterFeedbackProps {
  matchChildren: string[] | undefined;
}

export const FilterFeedback = ({ matchChildren }: FilterFeedbackProps) =>
  matchChildren && matchChildren.length > 0 ? (
    <Text as="p" tone="error" data-automation-id="WarningMessage">
      The following filter{' '}
      <Text as="span" bold data-automation-id="WarningFilterID">
        {matchChildren.join(', ')}
      </Text>{' '}
      will be reset when this drop down changing value
    </Text>
  ) : null;
