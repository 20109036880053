type TMobileNavigationStage = 'CLOSED' | 'OPEN';

export interface MobileNavigationStoreProps {
  isMobileNavigationOpen: boolean;
}

export interface MobileNavigationActionProps {
  type: TMobileNavigationStage;
}

const MobileNavigationReducer = (
  state: MobileNavigationStoreProps,
  action: MobileNavigationActionProps,
): MobileNavigationStoreProps => {
  switch (action.type) {
    case 'CLOSED':
      return {
        isMobileNavigationOpen: false,
      };
    case 'OPEN':
      return {
        isMobileNavigationOpen: true,
      };
    default:
      return state;
  }
};

export const initMobileNavigationStore: MobileNavigationStoreProps = {
  isMobileNavigationOpen: false,
};

export default MobileNavigationReducer;
