import {
  FC,
  useState,
  ReactNode,
  createContext,
  useContext,
  ButtonHTMLAttributes,
  HTMLAttributes,
  useMemo,
  useRef,
} from 'react';
import { Dialog, DialogProps } from '@reach/dialog';
import cx from 'classnames';
import '@reach/dialog/styles.css';

import { TModalSizes } from 'src/assets/variables/size';
import { ComponentWithAs, PropsWithAs, forwardRefWithAs } from 'src/utils/ts-utils';

import { Heading } from 'src/components/ui-components/Heading';
import { IconButton } from 'src/components/ui-components/IconButton';
import { Flex } from 'src/components/ui-components/Flex';
import { useAutoId } from 'src/hooks/useAutoId';
import styles from './Modal.module.scss';

interface ModalContextInterface {
  showModal: boolean;
  openModal(): void;
  closeModal(): void;
  id: string;
}

export const ModalContext = createContext<ModalContextInterface>({
  showModal: false,
  id: '',
  openModal: () => false,
  closeModal: () => true,
});

export interface ModalInterface {
  (props: ModalProps): JSX.Element;
  Trigger: ComponentWithAs<ModalTriggerProps, 'button'>;
  Dialog: FC<ModalDialogProps>;
  Header: FC<ModalHeaderInterface>;
  Content: FC<HTMLAttributes<HTMLDivElement>>;
  Footer: FC<HTMLAttributes<HTMLDivElement>>;
}

// Component for showing static content
interface ModalProps {
  children: ReactNode;
}

export const Modal: ModalInterface = (props) => {
  const [showModal, setShowModal] = useState(false);

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  const id = useAutoId();

  const defaultValue = useMemo(() => ({ showModal, openModal, closeModal, id }), [showModal, id]);

  return <ModalContext.Provider value={defaultValue} {...props} />;
};

type ModalTriggerProps = ButtonHTMLAttributes<HTMLButtonElement>;

function ModalTrigger(
  props: PropsWithAs<ModalTriggerProps, 'button'>,
  ref: React.Ref<HTMLButtonElement>,
) {
  const { openModal } = useContext(ModalContext);
  const { as: Type = 'button', ...rest } = props;
  return <Type onClick={openModal} ref={ref} {...rest} />;
}

Modal.Trigger = forwardRefWithAs<ModalTriggerProps, 'button'>(ModalTrigger);

// Building blocks for modal
interface ModalHeaderInterface {
  heading: string | ReactNode;
  closeText: string;
  children?: ReactNode;
  closeModal?: () => void;
}

const ModalHeader: FC<ModalHeaderInterface> = ({
  heading,
  closeText,
  children,
  closeModal,
  ...restProps
}) => {
  const { closeModal: closeModalLocal, id } = useContext(ModalContext);

  return (
    <div className={styles.modalHeader}>
      <Flex horizontalAlignment="justify" verticalAlignment="center" {...restProps}>
        <Heading id={id} level={2}>
          {heading}
        </Heading>

        <Flex verticalAlignment="center" noGap>
          {/* Here we can put other buttons to interact with. Eg. Help button */}
          {children}
          <IconButton
            iconName="cross"
            onClick={closeModal || closeModalLocal}
            data-automation-id="ModalCloseButton"
            tooltipText={closeText}
          />
        </Flex>
      </Flex>
    </div>
  );
};

Modal.Header = ModalHeader;

const ModalContent: FC<HTMLAttributes<HTMLDivElement>> = ({ ...props }) => (
  <div className={styles.modalContent} {...props} />
);

Modal.Content = ModalContent;

const ModalFooter: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => (
  <Flex horizontalAlignment="right" gap="large" className={styles.modalFooter} {...props}>
    {children}
  </Flex>
);

Modal.Footer = ModalFooter;

interface ModalDialogProps extends DialogProps {
  children: ReactNode;
  size?: TModalSizes;
  scrollInContent?: boolean;
}

const ModalDialog: FC<ModalDialogProps> = ({
  children,
  size = 'medium',
  scrollInContent,
  ...props
}) => {
  const { showModal, closeModal, id } = useContext(ModalContext);
  const modalRef = useRef<HTMLDivElement>(null);
  return (
    <Dialog
      className={cx(styles.wrapper, {
        [styles[size]]: size,
        [styles.scrollInContent]: scrollInContent,
      })}
      isOpen={showModal}
      onDismiss={closeModal}
      aria-labelledby={id}
      initialFocusRef={modalRef}
      ref={modalRef}
      {...props}
    >
      {children}
    </Dialog>
  );
};

Modal.Dialog = ModalDialog;
