import { IValueText } from 'src/screens/Home/HomeType';

interface IReplaceAbbrTag extends Pick<IValueText, 'abbrText' | 'abbrTitle'> {
  children: string;
}

export const ReplaceAbbrTag = ({ children, abbrText, abbrTitle }: IReplaceAbbrTag) => {
  if (children && abbrText && abbrTitle) {
    const textPart = children.split(abbrText);

    return (
      <span data-automation-id={`abbrSpan${abbrText}`}>
        {textPart?.map(
          (text, textIndex) =>
            text?.replaceAll(' ', '') && (
              <span
                data-automation-id={`abbrSpanSubPart${textIndex}`}
                key={`abbrSpan${text?.replaceAll(' ', '')}`}
              >
                {text}{' '}
                {textIndex < textPart.length - 1 && (
                  <abbr title={abbrTitle} data-automation-id={`abbrTag${textIndex}`}>
                    {abbrText}
                  </abbr>
                )}
              </span>
            ),
        )}
      </span>
    );
  }
  return <span data-automation-id="spanWithoutAbbr">{children}</span>;
};
