import { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';
import { IWidget } from './types';
import styles from './WidgetGrid.module.scss';

export interface WidgetGridInterface {
  (props: HTMLAttributes<HTMLDivElement>): JSX.Element;
  Item: FC<IWidget>;
}

export const WidgetGrid: WidgetGridInterface = ({ children, ...props }) => (
  <div className={classNames(styles.widgetDashboard)} {...props}>
    {children}
  </div>
);

WidgetGrid.Item = ({ columns, rows = '1', children, ...props }) => (
  <div className={classNames(styles.widget, styles?.[`widget${columns}by${rows}`], {})} {...props}>
    {children}
  </div>
);
