import { DateTime } from 'luxon';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postFetch } from 'src/utils/fetcher';
import { formatUrl } from 'src/utils/url';
import { GROUP_BY_PROJECT_KEY } from '../get/getGroupByProjectAPI';
import { GROUP_BY_RESOURCE_KEY } from '../get/getGroupByResourceAPI';

const POST_CREATE_WORK_ITEM_RELATIONSHIP_URL_PATH =
  '/api/v2/resource-planner/create-work-item-relationship';

export const POST_CREATE_WORK_ITEM_RELATIONSHIP_MSW_STATUS_KEY = 'CreateWorkItemRelationship';

export default () => {
  const path = formatUrl(POST_CREATE_WORK_ITEM_RELATIONSHIP_URL_PATH);
  const postCreateWorkItemRelationship = ({
    workItemId,
    workItemSourceReferenceId,
    workItemType,
    resourceId,
    resourceSourceReferenceId,
    resourceType,
    startDate,
    endDate,
    totalHours,
  }: {
    workItemId: string;
    workItemSourceReferenceId: string;
    workItemType: string;
    resourceId: string;
    resourceSourceReferenceId: string;
    resourceType: string;
    startDate: DateTime | null | undefined;
    endDate: DateTime | null | undefined;
    totalHours: string;
  }) =>
    postFetch({
      path,
      key: POST_CREATE_WORK_ITEM_RELATIONSHIP_MSW_STATUS_KEY,
      body: JSON.stringify({
        WorkItemId: workItemId,
        WorkItemSourceReferenceId: workItemSourceReferenceId,
        WorkItemType: workItemType,
        ResourceId: resourceId,
        ResourceSourceReferenceId: resourceSourceReferenceId,
        ResourceType: resourceType,
        DistributionStartsAt: startDate,
        DistributionEndsAt: endDate,
        Hours: totalHours,
      }),
    });
  const qc = useQueryClient();

  return useMutation(postCreateWorkItemRelationship, {
    onSuccess: () => {
      qc.invalidateQueries([GROUP_BY_RESOURCE_KEY]);
      qc.invalidateQueries([GROUP_BY_PROJECT_KEY]);
    },
  });
};
