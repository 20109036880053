import { useQuery } from '@tanstack/react-query';
import { IResourcePlannerModelResult } from 'src/apis/types/resourcePlannerAPI';
import { getFetch } from 'src/utils/fetcher';

const RESOURCE_VIEW_OPTIONS_URL_PATH = '/api/v2/resource-planner/view-options';
export const RESOURCE_VIEW_OPTIONS_MSW_STATUS_KEY = 'GetResourceViewOptionsAPI';

export const RESOURCE_VIEW_OPTIONS_KEY = 'RESOURCE_VIEW_OPTIONS';

const resourceViewOptionsFetch = (): Promise<IResourcePlannerModelResult> =>
  getFetch({ path: RESOURCE_VIEW_OPTIONS_URL_PATH, key: RESOURCE_VIEW_OPTIONS_MSW_STATUS_KEY });

export const useGetResourceViewOptions = () => {
  const { data: { model: { entities = [] } = {} } = {}, ...restProps } = useQuery(
    [RESOURCE_VIEW_OPTIONS_KEY],
    resourceViewOptionsFetch,
  );
  return {
    fields: entities.map(({ properties: { name = '', options = [] } = {} }) => ({
      name,
      value: options.find(({ selected }) => selected)?.value,
      options: options.map(({ value }) => ({
        value,
      })),
    })),
    ...restProps,
  };
};
