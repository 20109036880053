import { parse } from 'date-fns';
import { TDateFormats } from '../types';

export const formatDate = (value: string, dateFormat: TDateFormats): Date => {
  const dateFormatHasYearFirst = dateFormat.startsWith('y');
  const dateFormatHasDotNotation = dateFormat.startsWith('.', dateFormatHasYearFirst ? 4 : 2);
  const dateFormatHasSlashNotation = dateFormat.startsWith('/', dateFormatHasYearFirst ? 4 : 2);

  let formattedDate = '';
  if (dateFormatHasDotNotation) {
    formattedDate = value.replace(/[/-]/g, '.');
  } else if (dateFormatHasSlashNotation) {
    formattedDate = value.replace(/[.-]/g, '/');
  } else {
    formattedDate = value.replace(/[./]/g, '-');
  }

  return parse(formattedDate, dateFormat, new Date());
};
