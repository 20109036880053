export const systemAdministrationRoutes = [
  '/Settings/*',
  '/Employee/EmployeeType',
  '/Employee/EmployeeType/Index',
  '/Financial/ProductNumber',
  '/Financial/ProductNumber/Index',
];
export const personalSettingsRoutes = ['/Settings/UserSettings/*'];
export const pageSettingsRoutes = ['/PersonalPageSettings/*'];
export const editProjectTemplateRoute = '/Settings/ProjectTemplate/EditProjectTemplate/';
