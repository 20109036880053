import { toCamel } from 'src/utils/object';

export const personalSettings = {
  Entities: [
    {
      Properties: {
        Name: 'Personal settings',
        Type: 'PersonalSettings',
        IsParent: true,
        SubMenus: [
          {
            PageId: '5a36eeba-7b0e-4c15-8281-b0a1001b142b',
            Name: 'Personal settings',
            Type: 'PersonalSettings',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/UserSettings/PersonalSettings',
            PageIdentifier: 'PersonalPreference',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '7584750e-c164-44d8-88ae-a51a7a37ea82',
            Name: 'Personal information',
            Type: 'PersonalSettings',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/UserSettings/UserProfile',
            PageIdentifier: 'PersonalProfile',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '13a275bd-5153-4fff-944b-0298c8791d19',
            Name: 'Change password',
            Type: 'PersonalSettings',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/UserSettings/ChangePassword',
            PageIdentifier: 'ChangePassword',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '6ac75110-1e71-e311-8bf6-d28551383fc4',
            Name: 'CV',
            Type: 'PersonalSettings',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Organization/Employee/EditCV',
            PageIdentifier: 'EditEmployeeCV',
            PageType: 'ConnectedPage',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
  ],
  Links: [{ Href: 'http://localhost/tlp/api/v2/menus/personal-settings', Rel: 'self' }],
};

export const personalSettingsCamelCase = toCamel(
  personalSettings.Entities?.map(({ Properties: { ...restProps } }) => restProps),
);

export default personalSettings;
