import { Flex, Icon, Stack } from 'src/components/ui-components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from 'src/components/mui-components';
import { HTMLAttributes, ReactNode } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface AccordionItemProps extends HTMLAttributes<HTMLElement> {
  buttonAutomationId: string;
  dataAutomationId: string;
  heading: string;
  children?: ReactNode;
  counter?: number;
  expanded: boolean;
  handleChange: (event: React.SyntheticEvent, expanded: boolean) => void;
}

export const AccordionItem = ({
  buttonAutomationId,
  dataAutomationId,
  children,
  heading,
  counter = 0,
  expanded,
  handleChange,
}: AccordionItemProps) => {
  const hasItems = counter > 0;
  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      data-automation-id={dataAutomationId}
      disableGutters
      square
      borderless
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${buttonAutomationId}-content`}
        id={`${buttonAutomationId}-header`}
        data-automation-id={buttonAutomationId}
      >
        <Flex>
          <Icon
            iconName={hasItems ? 'warning' : 'circleCheck'}
            tone={hasItems ? 'warning' : 'success'}
          />
          <Typography component="h3" gutterBottom>{`${heading}${
            !hasItems ? '' : ` (${counter})`
          }`}</Typography>
        </Flex>
      </AccordionSummary>
      <AccordionDetails>
        <Stack verticalMargin="xSmall">{children}</Stack>
      </AccordionDetails>
    </Accordion>
  );
};
