import { useGetDropdownWithSearchAPI } from 'src/apis/dropdownAPI/get';
import { FilterInput } from 'src/components/ui-components/FilterInput';
import { Stack } from 'src/components/ui-components/Stack';
import {
  useGetFilterValuesById,
  useMatchSelectedFilterKey,
  useGetParentQueryString,
} from 'src/stores/FilterStore';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import HighlightMatchingText from 'src/components/utils/HighlightMatchingText';
import { IChildInputComponent, IOnChangeInput } from '../../InputContainerTypes';
import { useOnChangeHandler } from '../helpers';
import { FilterFeedback } from '../FilterFeedback';

const SingleDropdownWithSearch = ({
  contentUrl,
  filterId,
  fieldLabel,
  fieldPlaceholder,
  filterType,
  childFilters = [],
  parentFilters = [],
  activateInputContainerRef,
}: IChildInputComponent<'SingleSelect' | 'SingleSelectTypeSearch'>) => {
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue] = useDebounce(inputValue, 500);
  const pageIdentifier = useGetCurrentPageIdentifier();
  const defaultValue = useGetFilterValuesById(fieldLabel, filterType);
  const matchChild = useMatchSelectedFilterKey(childFilters);
  const queryString = useGetParentQueryString(parentFilters);

  const { dropdownList, isError, isFetching, isLoading } = useGetDropdownWithSearchAPI({
    key: `filterInput${filterId}`,
    MSWKey: `FILTER_INPUT_${filterId}`,
    path: `/${contentUrl}?pageIdentifier=${pageIdentifier}${queryString}` || '',
    searchText: debouncedInputValue,
  });

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
  };

  const onChangeHandler = useOnChangeHandler({
    contentUrl,
    fieldLabel,
    filterType,
    childFilters,
    activateInputContainerRef,
  });

  interface TestProps {
    label: string;
  }

  const formatOptionLabel = ({ label }: TestProps) => (
    <HighlightMatchingText name={label} matchName={inputValue} />
  );

  return (
    <ResponseHandler isError={isError}>
      <Stack>
        <FilterInput
          filterId={filterId}
          filterType={filterType}
          fieldLabel={fieldLabel}
          placeholder={fieldPlaceholder}
          data-automation-id={`SingleSelect${filterId}`}
          addFilterItem={() => {}}
          onChange={onChangeHandler}
          defaultValue={defaultValue as IOnChangeInput}
          options={dropdownList}
          autoFocus
          openMenuOnFocus={matchChild.length <= 0}
          tabSelectsValue={false}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          isLoading={isFetching || isLoading}
          formatOptionLabel={formatOptionLabel}
        />

        <FilterFeedback matchChildren={matchChild} />
      </Stack>
    </ResponseHandler>
  );
};

export default SingleDropdownWithSearch;
