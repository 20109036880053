import { forwardRef } from 'react';
import { ButtonBase, ButtonBaseProps, ButtonRef } from './ButtonBase';

type ButtonPropsWithoutStyleOptions = Omit<ButtonBaseProps, 'kind' | 'className' | 'style'>;

export const ButtonPrimary = forwardRef<ButtonRef, ButtonPropsWithoutStyleOptions>((props, ref) => (
  <ButtonBase {...props} kind="primary" ref={ref} />
));

export const ButtonSecondary = forwardRef<ButtonRef, ButtonPropsWithoutStyleOptions>(
  (props, ref) => <ButtonBase {...props} kind="secondary" ref={ref} />,
);

export const ButtonGhost = forwardRef<ButtonRef, ButtonPropsWithoutStyleOptions>((props, ref) => (
  <ButtonBase {...props} kind="ghost" ref={ref} />
));

export const ButtonDanger = forwardRef<ButtonRef, ButtonPropsWithoutStyleOptions>((props, ref) => (
  <ButtonBase {...props} kind="danger" ref={ref} />
));

export const ButtonDangerGhost = forwardRef<ButtonRef, ButtonPropsWithoutStyleOptions>(
  (props, ref) => <ButtonBase {...props} kind="dangerGhost" ref={ref} />,
);

export const ButtonDangerSecondary = forwardRef<ButtonRef, ButtonPropsWithoutStyleOptions>(
  (props, ref) => <ButtonBase {...props} kind="dangerSecondary" ref={ref} />,
);
