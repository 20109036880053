import IndexControl from './components/IndexControl';
import UserFeatureControl from './components/UserFeatureControl';
import { IFeatureControl } from './types';

export default ({
  indexControlName,
  children,
  pageIdentifierKey,
  userFeatureKey,
  actionName,
  idForFalseScenario,
}: IFeatureControl) => (
  <IndexControl
    indexControlName={indexControlName}
    actionName={actionName}
    idForFalseScenario={idForFalseScenario}
  >
    {pageIdentifierKey && userFeatureKey ? (
      <UserFeatureControl
        pageIdentifierKey={pageIdentifierKey}
        userFeatureKey={userFeatureKey}
        idForFalseScenario={idForFalseScenario}
      >
        {children}
      </UserFeatureControl>
    ) : (
      children
    )}
  </IndexControl>
);
