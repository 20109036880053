import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { capitalize } from 'src/utils/string';
import { formatUrl, concatQuerys } from 'src/utils/url';
import { ISearch, ISearchItem, ISearchResult } from 'src/apis/types/searchAPI';

const SEARCH_URL_PATH = '/api/v2/search';
export const SEARCH_MSW_STATUS_KEY = 'searchAPI';

export const SEARCH_KEY = 'SEARCH_KEY';

const getSearch = (searchValue: string, searchCategory?: string): Promise<ISearch> =>
  getFetch({
    path: `${SEARCH_URL_PATH}${concatQuerys([
      { param: 'searchtext', value: searchValue },
      { param: 'categories', value: searchCategory },
    ])}`,
    key: SEARCH_MSW_STATUS_KEY,
  });

const mapSearchItem = ({ name, pageUrl, ...restProps }: ISearchItem) => ({
  name: capitalize(name),
  pageUrl: formatUrl(pageUrl),
  ...restProps,
});

export const useSearch = (searchValue: string, searchCategory?: string, pauseSearch = false) => {
  const { data, ...restProps } = useQuery(
    [SEARCH_KEY, { searchValue, searchCategory }],
    () => getSearch(searchValue, searchCategory),
    {
      keepPreviousData: true,
      staleTime: 5000,
      enabled: !pauseSearch && searchValue !== '',
    },
  );
  if (data) {
    const {
      contacts = [],
      customers = [],
      projects = [],
      invoices = [],
      employees = [],
    } = data?.properties || {};
    const isCustomersEmpty = customers?.length <= 0;
    const isContactsEmpty = contacts?.length <= 0;
    const isProjectsEmpty = projects?.length <= 0;
    const isInvoicesEmpty = invoices?.length <= 0;
    const isEmployeesEmpty = employees?.length <= 0;
    const searchResult: ISearchResult[] = [
      {
        category: 'Customers',
        list: customers?.map(mapSearchItem),
        isEmpty: isCustomersEmpty,
      },
      {
        category: 'Contacts',
        list: contacts?.map(mapSearchItem),
        isEmpty: isContactsEmpty,
      },
      {
        category: 'Projects',
        list: projects?.map(mapSearchItem),
        isEmpty: isProjectsEmpty,
      },
      {
        category: 'Invoices',
        list: invoices?.map(mapSearchItem),
        isEmpty: isInvoicesEmpty,
      },
      {
        category: 'Employees',
        list: employees?.map(mapSearchItem),
        isEmpty: isEmployeesEmpty,
      },
    ];
    return {
      searchResult,
      isSearchResultEmpty:
        isCustomersEmpty &&
        isContactsEmpty &&
        isProjectsEmpty &&
        isInvoicesEmpty &&
        isEmployeesEmpty,
      ...restProps,
    };
  }
  return {
    searchResult: [],
    isSearchResultEmpty: true,
    ...restProps,
  };
};
