import { IconButton } from 'src/components/ui-components/IconButton';
import { Text } from 'src/components/ui-components/Text';
import { Flex } from 'src/components/ui-components/Flex';
import { HTMLAttributes, ReactNode } from 'react';
import { useAutoId } from 'src/hooks/useAutoId';
import styles from './FilterTag.module.scss';

export interface FilterTagProps extends Omit<HTMLAttributes<HTMLDivElement>, 'style'> {
  title: string;
  items: FilterTagItemProp[];
  defaultFilterItemValueLabel: string;
  moreFilterItemLabel: string;
  toolTipTextCrossButton: string;
  children: ReactNode;
  removeFilterTag?: () => void;
  panelIsOpen?: boolean;
  onClick: () => void;
}

export interface FilterTagItemProp {
  name: string;
}

export const FilterTag = ({
  title,
  items,
  toolTipTextCrossButton,
  defaultFilterItemValueLabel,
  moreFilterItemLabel,
  removeFilterTag,
  children,
  panelIsOpen = false,
  onClick,
  ...props
}: FilterTagProps) => {
  const autoId = `filter-${useAutoId()}`;
  return (
    <div className={styles.tagContainer} {...props}>
      <button
        type="button"
        className={styles.tagButton}
        onClick={onClick}
        data-automation-id="FilterTagOpenPanelButton"
        aria-controls={autoId}
        aria-expanded={panelIsOpen}
      >
        <Flex as="span">
          <Text as="span" bold>
            {title}:
          </Text>
          <span>
            <Text as="span" data-automation-id="FilterTagOpenPanelButtonValue">
              {items.length > 0 ? items[0].name : defaultFilterItemValueLabel}
            </Text>
            {items.length > 1 && (
              <Text as="span" bold>{` (+${items.length - 1}) ${moreFilterItemLabel}`}</Text>
            )}
          </span>
        </Flex>
      </button>
      {removeFilterTag ? (
        <IconButton
          size="compact"
          className={styles.removeTagButton}
          iconName="cross"
          tooltipText={toolTipTextCrossButton}
          onClick={removeFilterTag}
        />
      ) : null}
      <div
        id={autoId}
        className={styles.panelContent}
        data-automation-id="FilterTagPanelContent"
        hidden={!panelIsOpen}
      >
        {children}
      </div>
    </div>
  );
};

export default FilterTag;
