import { Grid } from 'src/components/mui-components';
import AverageHourlyRateReportingWidget from '../AverageHourlyRateReportingWidget';
import InvoicedPerMonthReportingWidget from '../InvoicedPerMonthReportingWidget';
import OverdueInvoicesReportingWidget from '../OverdueInvoicesReportingWidget';
import PlannedAbsenceReportingWidget from '../PlannedAbsenceReportingWidget';
import ProjectEconomyHealthReportingWidget from '../ProjectEconomyHealthReportingWidget';
import ReportingWidgetControl from './components/ReportingWidgetControl';

export const ReportingWidgetContainer = () => (
  <div data-automation-id="ReportWidgetContainer">
    <Grid container>
      <ReportingWidgetControl widgetTypeName="InvoicedPerMonth">
        <Grid item xs={12} md={12} lg={12}>
          <InvoicedPerMonthReportingWidget />
        </Grid>
      </ReportingWidgetControl>

      <ReportingWidgetControl widgetTypeName="AverageHourlyRate">
        <Grid item xs={12} md={12} lg={12}>
          <AverageHourlyRateReportingWidget />
        </Grid>
      </ReportingWidgetControl>

      <ReportingWidgetControl widgetTypeName="PlannedAbsence">
        <Grid item xs={12} md={12} lg={12}>
          <PlannedAbsenceReportingWidget />
        </Grid>
      </ReportingWidgetControl>

      <ReportingWidgetControl widgetTypeName="ProjectEconomyHealth">
        <Grid item xs={12} md={12} lg={12}>
          <ProjectEconomyHealthReportingWidget />
        </Grid>
      </ReportingWidgetControl>

      <ReportingWidgetControl widgetTypeName="OverdueInvoices">
        <Grid item xs={12} md={12} lg={12}>
          <OverdueInvoicesReportingWidget />
        </Grid>
      </ReportingWidgetControl>
    </Grid>
  </div>
);

export default ReportingWidgetContainer;
