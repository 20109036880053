import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { parseMenu } from 'src/utils/menu';
import { useTranslation } from 'react-i18next';
import { IMenus, IMenusPropertiesWithPagePath } from 'src/apis/types/menusAPI';

const PERSONAL_SETTINGS_MENU_URL_PATH = '/api/v2/menus/personal-settings';
export const PERSONAL_SETTINGS_MENU_MSW_STATUS_KEY = 'personalSettingsMenuAPI';

export const PERSONAL_SETTINGS_MENU_KEY = 'PERSONAL_SETTINGS_MENU';

const getPersonalSettingsMenu = (): Promise<IMenus> =>
  getFetch({ path: PERSONAL_SETTINGS_MENU_URL_PATH, key: PERSONAL_SETTINGS_MENU_MSW_STATUS_KEY });

export const usePersonalSettingsMenu = () => {
  const { t } = useTranslation('personalSettingsNavigation');
  const { data, ...restProps } = useQuery([PERSONAL_SETTINGS_MENU_KEY], getPersonalSettingsMenu);

  const personalSettingsMenu: IMenusPropertiesWithPagePath[] = parseMenu(data, t);
  const [firstMenuItem] = personalSettingsMenu || [];
  const [firstSubMenuItem] = firstMenuItem?.subMenus || [];
  const personalInformationItem = firstMenuItem?.subMenus?.find(
    ({ pageIdentifier }) => pageIdentifier === 'PersonalProfile',
  );

  return {
    personalSettingsMenu: firstMenuItem?.subMenus || [],
    isPersonalSettingMenuEmpty: personalSettingsMenu.length <= 0,
    defaultPersonalSettingUrl: firstSubMenuItem?.pagePath,
    personalInformationUrl: personalInformationItem?.pagePath,
    ...restProps,
  };
};
