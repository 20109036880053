import { useMutation } from '@tanstack/react-query';
import { signalRConnectionIdKey } from 'src/screens/ResourcePlanner/sessionStorageKeys';
import { postFetch } from 'src/utils/fetcher';
import { formatUrl } from 'src/utils/url';

const POST_RESOURCE_PLANNER_CHANGE_URL_PATH = '/api/v2/resource-planner/book-hours';
export const POST_RESOURCE_PLANNER_CHANGE_MSW_STATUS_KEY = 'PostResourcePlannerChange';

export const POST_RESOURCE_PLANNER_CHANGE_KEY = 'POST_RESOURCE_PLANNER_CHANGE';

export interface PostResourcePlannerChangeData {
  resourceId: string | undefined;
  workItemId: string | undefined;
  unitType: string;
  value: string;
  startsAt: string;
  endsAt: string;
}

export default () => {
  const path = formatUrl(POST_RESOURCE_PLANNER_CHANGE_URL_PATH);
  const hubConnectionId = sessionStorage.getItem(signalRConnectionIdKey);
  const postResourcePlannerChange = ({
    resourceId,
    workItemId,
    unitType,
    value,
    startsAt,
    endsAt,
  }: PostResourcePlannerChangeData) =>
    postFetch({
      path,
      key: POST_RESOURCE_PLANNER_CHANGE_MSW_STATUS_KEY,
      body: JSON.stringify({
        resourceId,
        workItemId,
        unitType,
        value,
        startsAt,
        endsAt,
        hubConnectionId,
      }),
    });

  return useMutation(postResourcePlannerChange, {
    onMutate: async (variables) => variables,
  });
};
