import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IExternalPercentage, IExternalPercentageItem } from 'src/apis/types/externalPercentageAPI';
import { DateTime } from 'luxon';
import { IInformation } from 'src/screens/Home/HomeType';

const EXTERNAL_PERCENTAGE_URL_PATH = '/api/v2/widget-data/external-percentage';
export const GET_EXTERNAL_PERCENTAGE_MSW_STATUS_KEY = 'getExternalPercentageAPI';
export const GET_EXTERNAL_PERCENTAGE_KEY = 'GET_EXTERNAL_PERCENTAGE_KEY';

const getExternalPercentageUrl = () => {
  const today = DateTime.now().toFormat('yyyy-MM-dd');
  return `${EXTERNAL_PERCENTAGE_URL_PATH}?currentDate=${today}`;
};

export const getExternalPercentageUrlForTest = getExternalPercentageUrl;

const getWidgetInformationList = ({
  currentMonthPercentage,
  lastMonthPercentage,
  lastThreeMonthsPercentage,
}: Pick<
  IExternalPercentageItem,
  'currentMonthPercentage' | 'lastMonthPercentage' | 'lastThreeMonthsPercentage'
>): Array<IInformation> => [
  {
    value: currentMonthPercentage?.toString(),
    key: 'MonthToDate',
    trending: lastMonthPercentage <= currentMonthPercentage ? 'Yes' : 'No',
  },
  { value: lastMonthPercentage?.toString(), key: 'Last30Days' },
  { value: lastThreeMonthsPercentage?.toString(), key: 'Last90Days' },
];

export const getWidgetInformationListForTest = getWidgetInformationList;

const getExternalPercentage = (): Promise<IExternalPercentage> =>
  getFetch({
    path: getExternalPercentageUrl(),
    key: GET_EXTERNAL_PERCENTAGE_MSW_STATUS_KEY,
  });

export const useGetExternalPercentageForHomeWidget = () => {
  const { data, ...restProps } = useQuery([GET_EXTERNAL_PERCENTAGE_KEY], () =>
    getExternalPercentage(),
  );

  if (data?.properties) {
    return {
      informationList: getWidgetInformationList(data.properties),
      ...restProps,
    };
  }
  return {
    informationList: [],
    ...restProps,
  };
};
