import { Box, Stack } from 'src/components/ui-components';
import { ReactNode } from 'react';

import styles from './ScrollContainer.module.scss';

interface ScrollContainerProps {
  children: ReactNode;
}
export const ScrollContainer = ({ children }: ScrollContainerProps) => (
  <div className={styles.scrollContainer}>
    <Box>
      <Box.Item compact>
        <Stack verticalMargin="small">{children}</Stack>
      </Box.Item>
    </Box>
  </div>
);
