import {
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
  RadioProps as MuiRadioProps,
} from '@mui/material';

export const Radio = ({ ...rest }: MuiRadioProps) => <MuiRadio {...rest} />;

export const RadioGroup = ({ ...rest }: MuiRadioGroupProps) => <MuiRadioGroup {...rest} />;

export type RadioProps = MuiRadioProps;
