import { useQuery } from '@tanstack/react-query';
import IPageSetting from 'src/apis/types/pageSettingAPI';
import { getFetch } from 'src/utils/fetcher';
import { parseBooleanFromString } from 'src/utils/boolean';

const PAGE_SETTINGS_URL_PATH = (pageIdentifier: string) =>
  `/api/v2/page-settings/${pageIdentifier}`;
export const PAGE_SETTINGS_MSW_STATUS_KEY = 'userFeatureAPI';

export const PAGE_SETTINGS_KEY = 'USER_FEATURE_SETTING';

export const useIsFeatureEnable = (pageIdentifierKey: string, userFeatureKey: string) => {
  const apiCall = (): Promise<IPageSetting> =>
    getFetch({
      path: PAGE_SETTINGS_URL_PATH(pageIdentifierKey),
      key: PAGE_SETTINGS_MSW_STATUS_KEY,
    });
  const { data, ...restProps } = useQuery([`${PAGE_SETTINGS_KEY}_${pageIdentifierKey}`], apiCall);
  return {
    isFeatureEnable: parseBooleanFromString(data?.properties?.[userFeatureKey] || ''),
    ...restProps,
  };
};
