export default {
  Entities: [
    {
      Properties: {
        DisplayText: '0002 The implementation of Daniel test project',
        Value: '2',
      },
    },
    {
      Properties: {
        DisplayText: '0003 Daniel from scratch',
        Value: '3',
      },
    },
    {
      Properties: {
        DisplayText: "0004 Baker Tilly's Test",
        Value: '4',
      },
    },
    {
      Properties: {
        DisplayText: '0006 API Project From Template BT',
        Value: '5',
      },
    },
    {
      Properties: {
        DisplayText: '0007 Project Creation From Template via API',
        Value: '6',
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/filters/project/content?pageIdentifier=Home&projectManagerIds=1&isProjectManagerInclusive=true',
      Rel: 'self',
    },
  ],
};
