import { DateTime } from 'luxon';
import { TTimeDuration } from './dateType';

interface IGetTimeRegistrationAccuracy {
  startDate: string;
  endDate: string;
}

export const getStartAndEndDateRange = (
  timeRegisterType: TTimeDuration,
): IGetTimeRegistrationAccuracy => {
  const today = DateTime.now().toFormat('yyyy-MM-dd');

  switch (timeRegisterType) {
    case 'weekly':
      return {
        startDate: DateTime.now().minus({ days: 6 }).toFormat('yyyy-MM-dd'),
        endDate: today,
      };
    case 'biWeekly':
      return {
        startDate: DateTime.now().minus({ days: 13 }).toFormat('yyyy-MM-dd'),
        endDate: today,
      };
    case 'monthly':
      return {
        startDate: DateTime.now().minus({ days: 29 }).toFormat('yyyy-MM-dd'),
        endDate: today,
      };
    default:
      return { startDate: DateTime.now().plus({ days: 7 }).toFormat('yyyy-MM-dd'), endDate: today };
  }
};
