import { Text } from 'src/components/ui-components/Text';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation('frontpage');
  return (
    <Text italic data-automation-id="MilestonesNoResultsMessage">
      {t('MilestonesNoResultsText')}
    </Text>
  );
};
