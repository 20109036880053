import { createContext, useContext } from 'react';

interface ILocale {
  getLocale: () => string;
}

export const LocaleContext = createContext<ILocale>({ getLocale: () => 'en-GB' });

export const useGetLocaleContext = () => useContext(LocaleContext);
export const useGetLocale = () => useContext(LocaleContext).getLocale();

export const LocaleProviderForTest = LocaleContext.Provider;

interface ILocaleStoreProps {
  children: JSX.Element | JSX.Element[];
  value: ILocale;
}

export const LocaleProvider = ({ children, value }: ILocaleStoreProps) => (
  <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
);
