import { FilterSelect } from 'src/components/ui-components/FilterSelect';
import { TextInput } from 'src/components/ui-components/TextInput';
import { Checkbox } from 'src/components/ui-components/Checkbox';
import { RadioButton } from 'src/components/ui-components/RadioButton';

interface IAddFilterItemProps {
  label: string;
  values: Array<string>;
  isInclusive?: boolean;
}

type TAddFilterItem = ({ label, values, isInclusive }: IAddFilterItemProps) => void;
export interface IFilterSelectOnChangeEvent {
  label?: string;
  value: string;
}
export type TFilterType =
  | 'SingleSelect'
  | 'MultiSelect'
  | 'SingleSelectTypeSearch'
  | 'MultiSelectTypeSearch'
  | 'Checkbox'
  | 'RadioButton'
  | 'Textbox';

export interface IFilterItemProperties<T, U>
  extends Partial<Omit<HTMLFormElement, 'contentEditable'>> {
  fieldLabel: string;
  filterId: string;
  filterType: T;
  options?: Array<IFilterSelectOnChangeEvent>;
  defaultValue?: U;
  hiddenLabel?: boolean;
}

export interface IFilterItemWithCustomFunction
  extends IFilterItemProperties<'Textbox' | 'Checkbox' | 'RadioButton', string> {
  addFilterItem: TAddFilterItem;
}

export type TFilterItemProperties =
  | IFilterItemProperties<'SingleSelect' | 'SingleSelectTypeSearch', IFilterSelectOnChangeEvent>
  | IFilterItemProperties<
      'MultiSelect' | 'MultiSelectTypeSearch',
      Array<IFilterSelectOnChangeEvent>
    >
  | IFilterItemWithCustomFunction;

const FilterInput = ({
  fieldLabel,
  filterId,
  filterType,
  addFilterItem,
  hiddenLabel = true,
  options = [],
  defaultValue,
  ...restProps
}: TFilterItemProperties) => {
  switch (filterType) {
    case 'SingleSelect':
      return (
        <FilterSelect
          hiddenLabel={hiddenLabel}
          label={fieldLabel}
          options={options}
          isSearchable={false}
          isClearable
          defaultValue={defaultValue}
          {...restProps}
        />
      );
    case 'MultiSelect':
      return (
        <FilterSelect
          hiddenLabel={hiddenLabel}
          label={fieldLabel}
          options={options}
          isSearchable={false}
          isMulti
          isClearable
          defaultValue={defaultValue}
          {...restProps}
        />
      );
    case 'SingleSelectTypeSearch':
      return (
        <FilterSelect
          hiddenLabel={hiddenLabel}
          label={fieldLabel}
          options={options}
          isSearchable
          isClearable
          defaultValue={defaultValue}
          {...restProps}
        />
      );
    case 'MultiSelectTypeSearch':
      return (
        <FilterSelect
          hiddenLabel={hiddenLabel}
          label={fieldLabel}
          options={options}
          isSearchable
          isClearable
          isMulti
          defaultValue={defaultValue}
          {...restProps}
        />
      );
    case 'Textbox':
      return (
        <TextInput
          data-automation-id={`FilterInputTextBox${filterId}`}
          name={`FilterTextInput${filterId}`}
          label={fieldLabel}
          hiddenLabel={hiddenLabel}
          onChange={(event) => {
            addFilterItem({ label: fieldLabel, values: [event.currentTarget.value] });
          }}
          {...restProps}
        />
      );
    case 'Checkbox':
      return (
        <>
          <Checkbox
            data-automation-id={`FilterInputCheckBox${filterId}Option1`}
            name={`FilterCheckBox${filterId}1`}
            label="Option 1"
            onChange={() => {
              addFilterItem({
                label: fieldLabel,
                values: ['Option 1'],
              });
            }}
          />
          <Checkbox
            data-automation-id={`FilterInputCheckBox${filterId}Option2`}
            name={`FilterCheckBox${filterId}2`}
            label="Option 2"
            onChange={() => {
              addFilterItem({
                label: fieldLabel,
                values: ['Option 2'],
              });
            }}
          />
        </>
      );
    case 'RadioButton':
      return (
        <>
          <RadioButton
            data-automation-id={`FilterInputRadioButton${filterId}Option1`}
            label="Male"
            name="radio-group"
            onChange={() => {
              addFilterItem({
                label: fieldLabel,
                values: ['Option 1'],
              });
            }}
          />
          <RadioButton
            data-automation-id={`FilterInputRadioButton${filterId}Option2`}
            label="Female"
            name="radio-group"
            onChange={() => {
              addFilterItem({
                label: fieldLabel,
                values: ['Option 2'],
              });
            }}
          />
        </>
      );
    default:
      return null;
  }
};

export default FilterInput;
