import { toCamel } from 'src/utils/object';

const createData = {
  Entities: [
    {
      Properties: {
        Name: 'Register',
        Type: 'Main',
        IsParent: true,
        SubMenus: [
          {
            PageId: '62c37936-5a57-46a8-885e-fc0ddecd5361',
            Name: 'New personal expense',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Registration/EmployeeExpense/Index',
            PageIdentifier: 'EmployeeExpense',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'a8daa068-aab4-449e-b640-a4e43daf04c5',
            Name: 'New mileage registration',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Registration/Mileage/NewMileageRegistration',
            PageIdentifier: 'MileageRegistration',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '3edd0f43-2040-44c6-a9e6-9e6bf614dd9f',
            Name: 'Import personal expenses',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Registration/Timesheet/ImportEmployeeExpenses',
            PageIdentifier: 'ImportEmployeeExpenses',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        Name: 'Projects',
        Type: 'Main',
        IsParent: true,
        SubMenus: [
          {
            PageId: '95b84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'New project',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/ProjectManagement/Project/New',
            PageIdentifier: 'NewProject',
            PageType: 'ConnectedPage',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '97b84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'New project (QuickCreate)',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/ProjectManagement/Project/NewQuick',
            PageIdentifier: 'NewQuickProject',
            PageType: 'ConnectedPage',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'fa5e6154-35b4-4c33-94c5-277cec643cdf',
            Name: 'New project expense',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/ProjectManagement/ProjectExpense/Index',
            PageIdentifier: 'ProjectExpense',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'cd7456b5-2fb0-4160-9f58-14288c8ca763',
            Name: 'Import project expenses',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/ProjectManagement/ProjectExpense/ImportProjectExpenses',
            PageIdentifier: 'ImportProjectExpenses',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '99b84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'Import from MS Project',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/ProjectManagement/Import/MSProject',
            PageIdentifier: 'ImportFromMSProject',
            PageType: 'Normal',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        Name: 'Employees',
        Type: 'Main',
        IsParent: true,
        SubMenus: [
          {
            PageId: 'b5b84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'New employee',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Organization/Employee/NewEmployee',
            PageIdentifier: 'NewEmployee',
            PageType: 'ConnectedPage',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'b9b84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'New CV',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Organization/Employee/NewCV',
            PageIdentifier: 'NewCV',
            PageType: 'ConnectedPage',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        Name: 'Invoices',
        Type: 'Main',
        IsParent: true,
        SubMenus: [
          {
            PageId: 'a4b84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'New invoice',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Financial/Invoicing/NewInvoice',
            PageIdentifier: 'InvoiceNew',
            PageType: 'ConnectedPage',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'a6b84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'New voucher',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Financial/Invoicing/NewVoucher',
            PageIdentifier: 'VoucherNew',
            PageType: 'ConnectedPage',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '515b7688-5f51-4688-9499-dd22d909906a',
            Name: 'New credit note',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Financial/Invoicing/NewCreditNote',
            PageIdentifier: 'CreditNoteNew',
            PageType: 'ConnectedPage',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        Name: 'Customers',
        Type: 'Main',
        IsParent: true,
        SubMenus: [
          {
            PageId: '8eb84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'New customer',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/CRM/Customer/New',
            PageIdentifier: 'CRMCustomerNew',
            PageType: 'ConnectedPage',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '90b84567-5a4b-e311-9d8f-9c1f9ef22d51',
            Name: 'New contact',
            Type: 'Main',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/CRM/Contact/New',
            PageIdentifier: 'CRMContactNew',
            PageType: 'ConnectedPage',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
  ],
  Links: [{ Href: 'http://localhost/tlp/api/v2/menus/create', Rel: 'self' }],
};

export const createDataCamelCase = toCamel(
  createData.Entities?.map(({ Properties: { ...restProps } }) => restProps),
);
export default createData;
