import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { ITlpCountResponse } from 'src/apis/types/resourcePlannerAPI';

const TLP_PLAN_COUNT_URL_PATH = '/api/v2/resource-planner/tlp-plan-count';
export const TLP_PLAN_COUNT_MSW_STATUS_KEY = 'GetTlpPlanCount';

export const TLP_PLAN_COUNT_KEY = 'TLP_PLAN_COUNT';
const oneSecond = 1000;

const resourcePlannerFetch = (): Promise<ITlpCountResponse> =>
  getFetch({ path: TLP_PLAN_COUNT_URL_PATH, key: TLP_PLAN_COUNT_MSW_STATUS_KEY });

const useGetTlpPlanCountQuery = (refetch: boolean) =>
  useQuery([TLP_PLAN_COUNT_KEY], resourcePlannerFetch, {
    refetchInterval: refetch ? oneSecond : undefined,
  });

export const useGetTlpPlanCount = (refetch: boolean) => {
  const { data, ...restProps } = useGetTlpPlanCountQuery(refetch);

  return {
    count: data?.properties.count ?? 0,
    ...restProps,
  };
};
