import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IFilterStoreProps } from 'src/reducers/FilterReducer';
import { postFetch } from 'src/utils/fetcher';
import { IEntitiesResponse } from 'src/apis/types/responseBase';

const TIME_REGISTRATIONS_URL_PATH = '/api/v2/report-project/results/table';

export const POST_TIME_REGISTRATIONS_MSW_STATUS_KEY = 'postTimeRegistrations';
export const POST_TIME_REGISTRATIONS_KEY = 'POST_TIME_REGISTRATIONS';

interface BodyProps {
  ContractTypeIds?: string[];
  IsContractTypeInclusive?: boolean;
  EmployeeIds?: string[];
  IsEmployeeInclusive?: boolean;
  ProjectCategoryIds?: string[];
  IsProjectCategoryInclusive?: boolean;
  ProjectManagerIds?: string[];
  IsProjectManagerInclusive?: boolean;
}

interface EntitiesAndLinks extends IEntitiesResponse<BodyProps[]> {}

export default ({ selectedFilterList }: Pick<IFilterStoreProps, 'selectedFilterList'>) => {
  const items = Object.keys(selectedFilterList);
  // TODO: Figure out how to fix "any"
  const [modifiedData, setModifiedData] = useState<any>(undefined);

  const body = items.map((item) => {
    const ids = selectedFilterList[item].values.map((value) => value.value);

    return {
      [`${item}Ids`]: ids.toString(),
      [`Is${item}Inclusive`]: selectedFilterList[item].isInclude,
    };
  });

  const flatBody: BodyProps = Object.assign({}, ...body);

  const postTimeRegistrations = () =>
    postFetch({
      path: TIME_REGISTRATIONS_URL_PATH,
      key: POST_TIME_REGISTRATIONS_MSW_STATUS_KEY,
      body: JSON.stringify(flatBody),
    });

  const qc = useQueryClient();

  const returnValue = [
    useMutation(postTimeRegistrations, {
      onSuccess: (data: EntitiesAndLinks) => {
        setModifiedData(data?.entities?.map((item) => item.properties));
        qc.invalidateQueries([POST_TIME_REGISTRATIONS_KEY]);
      },
    }),
    modifiedData,
  ];

  return returnValue;
};
