export const monthShortNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const monthInNumber = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
