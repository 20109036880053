import { useMutation, useQueryClient } from '@tanstack/react-query';
import { formatUrl } from 'src/utils/url';
import { deleteFetch } from 'src/utils/fetcher';
import { BOOKMARKS_KEY } from '../get';

export const DELETE_BOOKMARKS_MSW_STATUS_KEY = 'deleteBookmark';

export const DELETE_BOOKMARKS_KEY = 'DELETE_BOOKMARKS';

export default (deleteBookmarkUrl: string) => {
  const path = formatUrl(deleteBookmarkUrl);
  const deleteBookmark = () => deleteFetch({ path, key: DELETE_BOOKMARKS_MSW_STATUS_KEY });
  const qc = useQueryClient();

  return useMutation(deleteBookmark, {
    onSuccess: () => {
      qc.invalidateQueries([BOOKMARKS_KEY]);
    },
  });
};
