import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePageDispatch } from 'src/stores/PageStore';

export const useIframeEvent = () => {
  const pageDispatch = usePageDispatch();
  const { pathname, search } = useLocation();

  useEffect(() => {
    const handleIframeEvent = (event: MessageEvent) => {
      const {
        data: { pageIdentifier, eventId, pageId, pageTitle },
      } = event;
      if (eventId === 'pageIdentifier' && pageDispatch) {
        pageDispatch({
          type: 'SET_CURRENT_PAGE',
          payload: { pageIdentifier, pageId, pageTitle },
        });
        pageDispatch({
          type: 'SET_LAST_VISITED_PAGE',
          payload: { pagePath: pathname + search, pageIdentifier },
        });
      }
    };

    window?.addEventListener('message', handleIframeEvent as EventListener);

    return () => {
      window?.removeEventListener('message', handleIframeEvent as EventListener);
    };
  }, [pageDispatch, pathname, search]);
};
