import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IRpCountResponse } from 'src/apis/types/resourcePlannerAPI';

const RP_EMPLOYEE_COUNT_URL_PATH = '/api/v2/resource-planner/rp-active-employee-count';
export const RP_EMPLOYEE_COUNT_MSW_STATUS_KEY = 'GetRpEmployeeCount';

export const RP_EMPLOYEE_COUNT_KEY = 'RP_EMPLOYEE_COUNT';
const oneSecond = 1000;

const resourcePlannerFetch = (): Promise<IRpCountResponse> =>
  getFetch({ path: RP_EMPLOYEE_COUNT_URL_PATH, key: RP_EMPLOYEE_COUNT_MSW_STATUS_KEY });

const useGetRpEmployeeCountQuery = (refetch: boolean) =>
  useQuery([RP_EMPLOYEE_COUNT_KEY], resourcePlannerFetch, {
    refetchInterval: refetch ? oneSecond : undefined,
  });

export const useGetRpEmployeeCount = (refetch: boolean) => {
  const { data, ...restProps } = useGetRpEmployeeCountQuery(refetch);

  return {
    count: data?.model?.properties.count ?? 0,
    ...restProps,
  };
};
