import { useMutation } from '@tanstack/react-query';
import { putFetch } from 'src/utils/fetcher';
import { ISearchCategoryItem } from 'src/apis/types/searchCategoriesAPI';

const SEARCH_CATEGORIES_URL_PATH = '/api/v2/search/categories';
export const PUT_SEARCH_CATEGORIES_MSW_STATUS_KEY = 'putSearchCategoriesAPI';
export const PUT_SEARCH_CATEGORIES_KEY = 'PUT_SEARCH_CATEGORIES_KEY';

const updateSearchCategory = (searchCategories: ISearchCategoryItem[] = []) =>
  putFetch({
    path: SEARCH_CATEGORIES_URL_PATH,
    key: PUT_SEARCH_CATEGORIES_MSW_STATUS_KEY,
    body: JSON.stringify({
      categories: searchCategories,
    }),
  });

export const useUpdateSearchCategory = () =>
  useMutation([PUT_SEARCH_CATEGORIES_KEY], (searchCategories: ISearchCategoryItem[]) =>
    updateSearchCategory(searchCategories),
  );
