import { Flex } from 'src/components/ui-components/Flex';
import { IconButton } from 'src/components/ui-components/IconButton';
import { Row } from '@tanstack/react-table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RPRow, RTRow } from '../../types/resourcePlanner';
import { getExpandedRowOriginalIds, TCollapseExpandState } from '../../helper/expandedRow';
import {
  RPEmployeeViewDisableExpandAllCount,
  RPProjectViewDisableExpandAllCount,
} from '../../constants';

export interface ToggleRowsButtonProps {
  rows: Row<RPRow>[];
  setExpandedRows?: Function;
  viewStorageKey: string;
  handleOnToggleRow?: (row: RTRow) => void;
  disableExpandAll?: boolean;
  isGroupedByProject: boolean;
}

const getMinimumDepthToExpand = (rows: Row<RPRow>[], tableExpandedDepth: number) => {
  let minimumDepthToExpand = tableExpandedDepth;

  if (tableExpandedDepth > 0) {
    rows.forEach((row) => {
      if (row.depth < tableExpandedDepth && row.getCanExpand() && !row.getIsExpanded()) {
        if (row.depth < minimumDepthToExpand) {
          minimumDepthToExpand = row.depth;
        }
      }
    });
  }
  return minimumDepthToExpand;
};

const getMaximumExpandedDepthInTable = (rows: Row<RPRow>[]) => {
  let maxParentDepth = 0;
  rows.forEach((row) => {
    if (row.getCanExpand() && row.getIsExpanded()) {
      if (row.depth > maxParentDepth) {
        maxParentDepth = row.depth;
      }
    }
  });
  const childDepth = maxParentDepth + 1;
  return childDepth;
};

const removeNonExpandedStateFromStorage = (state: TCollapseExpandState[]) =>
  state.reduce<TCollapseExpandState[]>((previous, current) => {
    const obj: TCollapseExpandState[] = [];

    if (current !== undefined && current.isExpanded) {
      obj.push({
        tableRowId: current.tableRowId,
        originalId: current.originalId,
        isExpanded: current.isExpanded,
      });
    }

    return [...previous, ...obj];
  }, []);

export const ToggleRowsButton = ({
  rows,
  setExpandedRows,
  viewStorageKey,
  handleOnToggleRow,
  disableExpandAll = false,
  isGroupedByProject,
}: ToggleRowsButtonProps) => {
  const { t } = useTranslation('resourcePlanner');
  const [storageKey] = useState(viewStorageKey);
  const maxTableExpandedDepth = getMaximumExpandedDepthInTable(rows);

  const onExpandCallback = () => {
    let expandedRows: TCollapseExpandState[] = [];
    const rowsWithChange: Row<RPRow>[] = [];
    const minimumDepthToExpand = getMinimumDepthToExpand(rows, maxTableExpandedDepth);

    let isAllSameDepthRowCollapsed = true;

    rows.every((row) => {
      if (row.depth === minimumDepthToExpand && row.getCanExpand() && row.getIsExpanded()) {
        isAllSameDepthRowCollapsed = false;
        return false;
      }
      return true;
    });

    if (isAllSameDepthRowCollapsed) {
      rows.forEach((row) => {
        if (row.depth > minimumDepthToExpand && row.getCanExpand() && row.getIsExpanded()) {
          if (handleOnToggleRow) {
            handleOnToggleRow(row);
          } else {
            const currentRowState = {
              tableRowId: row.id,
              originalId: row.original.id,
              isExpanded: false,
            };
            rowsWithChange.push(row);
            expandedRows.push(currentRowState);
            row.toggleExpanded(false);
          }
        }
      });
    }

    const updatedState: TCollapseExpandState[] = [];

    rows.forEach((row) => {
      if (row.depth === minimumDepthToExpand && row.getCanExpand() && !row.getIsExpanded()) {
        if (handleOnToggleRow) {
          handleOnToggleRow(row);
        } else {
          updatedState.push({
            tableRowId: row.id,
            originalId: row.original.id,
            isExpanded: true,
          });
          rowsWithChange.push(row);
          row.toggleExpanded(true);
        }
      }
    });

    expandedRows = updatedState;

    if (expandedRows.length === 0) {
      return;
    }

    const rowExpandedStateString = localStorage.getItem(storageKey);

    if (rowExpandedStateString) {
      const rowExpandedState = JSON.parse(rowExpandedStateString) ?? [];

      expandedRows = [...rowExpandedState, ...expandedRows];
    }

    expandedRows = removeNonExpandedStateFromStorage(expandedRows);

    localStorage.setItem(storageKey, JSON.stringify(expandedRows));
    if (setExpandedRows) {
      setExpandedRows(getExpandedRowOriginalIds(storageKey), rowsWithChange);
    }
  };

  const onCollapseCallback = () => {
    const collapsedRows: TCollapseExpandState[] = [];

    rows.forEach((row) => {
      if (row.depth === maxTableExpandedDepth - 1 && row.getCanExpand() && row.getIsExpanded()) {
        if (handleOnToggleRow) {
          handleOnToggleRow(row);
        } else {
          row.toggleExpanded(false);
          collapsedRows.push({
            tableRowId: row.id,
            originalId: row.original.id,
            isExpanded: false,
          });
        }
      }
    });

    if (collapsedRows.length === 0) {
      return;
    }

    const state: TCollapseExpandState[] = [];

    const rowExpandedStateString = localStorage.getItem(storageKey);

    if (rowExpandedStateString) {
      const rowExpandedState: TCollapseExpandState[] = JSON.parse(rowExpandedStateString) ?? [];

      const collapsedRowStateMap = new Map<string, boolean>();
      collapsedRows.forEach((collapsedRow) => {
        collapsedRowStateMap.set(collapsedRow.tableRowId, collapsedRow.isExpanded);
      });

      rowExpandedState.forEach((row) => {
        if (!collapsedRowStateMap.has(row.tableRowId)) {
          state.push(row);
        }
      });
    }

    localStorage.setItem(storageKey, JSON.stringify(state));
    if (setExpandedRows) {
      setExpandedRows(getExpandedRowOriginalIds(storageKey));
    }
  };

  const disabledTooltipText = isGroupedByProject
    ? t('ExpandAllDisabledTooltipProject', { maxCount: RPProjectViewDisableExpandAllCount })
    : t('ExpandAllDisabledTooltipEmployee', { maxCount: RPEmployeeViewDisableExpandAllCount });

  return (
    <Flex noGap>
      <IconButton
        tooltipText={disableExpandAll ? disabledTooltipText : t('UnfoldMore')}
        iconSize="compact"
        iconName="unfoldMore"
        onClick={onExpandCallback}
        disabled={disableExpandAll}
      />
      <IconButton
        tooltipText={t('UnfoldLess')}
        iconSize="compact"
        iconName="unfoldLess"
        onClick={onCollapseCallback}
      />
    </Flex>
  );
};
