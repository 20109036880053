import { forwardRef } from 'react';
import {
  ListItemButton as MuiListItemButton,
  ListItemButtonProps as MuiListItemButtonProps,
} from '@mui/material';

// MUI is using div[role="button"] for ListItemButton
type DivRef = HTMLDivElement;

export const ListItemButton = forwardRef<DivRef, MuiListItemButtonProps>(({ sx, ...rest }, ref) => (
  <MuiListItemButton ref={ref} sx={{ width: '100%', ...sx }} {...rest} />
));
