import { ISearchCategoryItem } from 'src/apis/types/searchCategoriesAPI';

export interface IMainSearchProps {
  isMainSearchOpen: boolean;
  isCategoryOpen: boolean;
  categoryList: ISearchCategoryItem[];
}

export type TMainSearchActionProps =
  | {
      type: 'TOGGLE_SEARCH' | 'TOGGLE_CATEGORY' | 'RESET_SEARCH' | 'CLOSE_CATEGORY';
    }
  | { type: 'UPDATE_CATEGORY_LIST'; payload: ISearchCategoryItem[] };

const MainSearchReducer = (
  state: IMainSearchProps,
  action: TMainSearchActionProps,
): IMainSearchProps => {
  switch (action.type) {
    case 'RESET_SEARCH':
      return {
        ...state,
        isMainSearchOpen: false,
        isCategoryOpen: false,
      };
    case 'TOGGLE_SEARCH':
      return {
        ...state,
        isMainSearchOpen: !state.isMainSearchOpen,
        isCategoryOpen: !state.isMainSearchOpen === false ? false : state.isCategoryOpen,
      };
    case 'TOGGLE_CATEGORY':
      return {
        ...state,
        isCategoryOpen: !state.isCategoryOpen,
      };
    case 'CLOSE_CATEGORY':
      return {
        ...state,
        isCategoryOpen: false,
      };
    case 'UPDATE_CATEGORY_LIST':
      return {
        ...state,
        categoryList: action.payload,
      };
    default:
      return state;
  }
};

export const initMainSearchStore: IMainSearchProps = {
  isMainSearchOpen: false,
  isCategoryOpen: false,
  categoryList: [],
};

export default MainSearchReducer;
