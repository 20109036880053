export default {
  Entities: [
    {
      Properties: {
        ProjectID: 3556,
        ProjectNo: 'P13.0007',
        ProjectName: ' _TIM - Kurser Q1 2013',
        ProjectManagerID: 48,
        ProjectManagerName: 'Thomas S. Gudmandsen',
        ProjectManagerInitials: 'TSG',
        CustomerID: 15433,
        CustomerName: 'Kursus',
        DepartmentID: 31,
        DepartmentName: 'TimeLog More',
      },
    },
    {
      Properties: {
        ProjectID: 13304,
        ProjectNo: 'P17.0166',
        ProjectName: ' _TIMJenm019EURE',
        ProjectManagerID: 79,
        ProjectManagerName: 'Lee 1234567890 Jenn Woei 1234567890',
        ProjectManagerInitials: 'JWL',
        CustomerID: 221,
        CustomerName: '1508 A/S',
        DepartmentID: 49,
        DepartmentName: '46. KL Development',
      },
    },
    {
      Properties: {
        ProjectID: 17521,
        ProjectNo: 'P18.0024',
        ProjectName: ' _TIMJenm079EURE',
        ProjectManagerID: 79,
        ProjectManagerName: 'Lee 1234567890 Jenn Woei 1234567890',
        ProjectManagerInitials: 'JWL',
        CustomerID: 221,
        CustomerName: '1508 A/S',
        DepartmentID: 49,
        DepartmentName: '46. KL Development',
      },
    },
    {
      Properties: {
        ProjectID: 18544,
        ProjectNo: 'P18.0046',
        ProjectName: ' _TIMJenm084EURE',
        ProjectManagerID: 79,
        ProjectManagerName: 'Lee 1234567890 Jenn Woei 1234567890',
        ProjectManagerInitials: 'JWL',
        CustomerID: 221,
        CustomerName: '1508 A/S',
        DepartmentID: 49,
        DepartmentName: '46. KL Development',
      },
    },
    {
      Properties: {
        ProjectID: 20738,
        ProjectNo: 'P19.0013',
        ProjectName: ' _TIMJenm132EURT',
        ProjectManagerID: 79,
        ProjectManagerName: 'Lee 1234567890 Jenn Woei 1234567890',
        ProjectManagerInitials: 'JWL',
        CustomerID: 221,
        CustomerName: '1508 A/S',
        DepartmentID: 49,
        DepartmentName: '46. KL Development',
      },
    },
    {
      Properties: {
        ProjectID: 27082,
        ProjectNo: 'P20.0119',
        ProjectName: ' _TIMJenm62055EURAAA',
        ProjectManagerID: 79,
        ProjectManagerName: 'Lee 1234567890 Jenn Woei 1234567890',
        ProjectManagerInitials: 'JWL',
        CustomerID: 41727,
        CustomerName: 'AllUnite A/S',
        DepartmentID: 49,
        DepartmentName: '46. KL Development',
      },
    },
    {
      Properties: {
        ProjectID: 11947,
        ProjectNo: 'P16.0312',
        ProjectName: ' ANO - Data import',
        ProjectManagerID: 48,
        ProjectManagerName: 'Thomas S. Gudmandsen',
        ProjectManagerInitials: 'TSG',
        CustomerID: 24794,
        CustomerName: 'Anodius a. s.',
        DepartmentID: 51,
        DepartmentName: '50. TL Consulting',
      },
    },
    {
      Properties: {
        ProjectID: 746,
        ProjectNo: 'P07.0090',
        ProjectName: '_ TIM - Webinar',
        ProjectManagerID: 20,
        ProjectManagerName: 'Peder Wuth Pedersen',
        ProjectManagerInitials: 'PWP',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 23,
        DepartmentName: 'Services',
      },
    },
    {
      Properties: {
        ProjectID: 432,
        ProjectNo: '06.0002',
        ProjectName: '_Administration 2006',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 5,
        DepartmentName: 'Administration',
      },
    },
    {
      Properties: {
        ProjectID: 513,
        ProjectNo: '06.0082',
        ProjectName: '_DM - kampagne TLP konsulenter',
        ProjectManagerID: 26,
        ProjectManagerName: 'Bjørn Herbst',
        ProjectManagerInitials: 'BHE',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 4,
        DepartmentName: 'TimeLog DK',
      },
    },
    {
      Properties: {
        ProjectID: 1941,
        ProjectNo: 'P11.0107',
        ProjectName: '_JAM testprojekt iPhone dev',
        ProjectManagerID: 4,
        ProjectManagerName: 'Jakob Mikkelsen',
        ProjectManagerInitials: 'JAM',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 443,
        ProjectNo: '06.0013',
        ProjectName: '_Ledelse 2006',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 4,
        DepartmentName: 'TimeLog DK',
      },
    },
    {
      Properties: {
        ProjectID: 433,
        ProjectNo: '06.0003',
        ProjectName: '_Marketing 2006',
        ProjectManagerID: 14,
        ProjectManagerName: 'Anders Hahn Brandstrup',
        ProjectManagerInitials: 'AEB',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 1,
        DepartmentName: 'Product',
      },
    },
    {
      Properties: {
        ProjectID: 434,
        ProjectNo: '06.0004',
        ProjectName: '_Partnere 2006',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 4,
        DepartmentName: 'TimeLog DK',
      },
    },
    {
      Properties: {
        ProjectID: 435,
        ProjectNo: '06.0005',
        ProjectName: '_QA 2006',
        ProjectManagerID: 4,
        ProjectManagerName: 'Jakob Mikkelsen',
        ProjectManagerInitials: 'JAM',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 4,
        DepartmentName: 'TimeLog DK',
      },
    },
    {
      Properties: {
        ProjectID: 543,
        ProjectNo: '06.0111',
        ProjectName: '_Sales',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 2,
        DepartmentName: '25. SE Sales',
      },
    },
    {
      Properties: {
        ProjectID: 436,
        ProjectNo: '06.0006',
        ProjectName: '_Salg 2006',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 2,
        DepartmentName: '25. SE Sales',
      },
    },
    {
      Properties: {
        ProjectID: 377,
        ProjectNo: '05.0070',
        ProjectName: '_Support',
        ProjectManagerID: 4,
        ProjectManagerName: 'Jakob Mikkelsen',
        ProjectManagerInitials: 'JAM',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 1,
        DepartmentName: 'Product',
      },
    },
    {
      Properties: {
        ProjectID: 438,
        ProjectNo: '06.0008',
        ProjectName: '_Teknisk Drift 2006',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 4,
        DepartmentName: 'TimeLog DK',
      },
    },
    {
      Properties: {
        ProjectID: 1469,
        ProjectNo: 'P09.0256',
        ProjectName: '_TIM - Account management  1:1',
        ProjectManagerID: 155,
        ProjectManagerName: 'Farooq Rashid',
        ProjectManagerInitials: 'FRA',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 43,
        DepartmentName: '33. Customer Services',
      },
    },
    {
      Properties: {
        ProjectID: 1474,
        ProjectNo: 'P09.0261',
        ProjectName: '_TIM - Administration',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 32,
        DepartmentName: '10. Stab',
      },
    },
    {
      Properties: {
        ProjectID: 5967,
        ProjectNo: 'P14.0022',
        ProjectName: '_TIM - AdWords - Novicell',
        ProjectManagerID: 1163,
        ProjectManagerName: 'Melanie Smith Nielsen',
        ProjectManagerInitials: 'MNI',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 48,
        DepartmentName: '30. Marketing & Customer Relations',
      },
    },
    {
      Properties: {
        ProjectID: 871,
        ProjectNo: 'P07.0215',
        ProjectName: '_TIM - API eksempelapplikationer',
        ProjectManagerID: 20,
        ProjectManagerName: 'Peder Wuth Pedersen',
        ProjectManagerInitials: 'PWP',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 23,
        DepartmentName: 'Services',
      },
    },
    {
      Properties: {
        ProjectID: 1379,
        ProjectNo: 'P09.0167',
        ProjectName: '_TIM - Arbejdsmiljø',
        ProjectManagerID: 59,
        ProjectManagerName: 'Thomas Ljungqvist',
        ProjectManagerInitials: 'TLJ',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 26,
        DepartmentName: '36. Support',
      },
    },
    {
      Properties: {
        ProjectID: 1475,
        ProjectNo: 'P09.0262',
        ProjectName: '_TIM - Bogholderi',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 30,
        DepartmentName: 'Management',
      },
    },
    {
      Properties: {
        ProjectID: 10432,
        ProjectNo: 'P15.0194',
        ProjectName: '_TIM - Consulting documents',
        ProjectManagerID: 48,
        ProjectManagerName: 'Thomas S. Gudmandsen',
        ProjectManagerInitials: 'TSG',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 51,
        DepartmentName: '50. TL Consulting',
      },
    },
    {
      Properties: {
        ProjectID: 11894,
        ProjectNo: 'P16.0258',
        ProjectName: '_TIM - Customer 10 years anniversary',
        ProjectManagerID: 101,
        ProjectManagerName: 'Pernille Wilken Gaustad',
        ProjectManagerInitials: 'PGA',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 51,
        DepartmentName: '50. TL Consulting',
      },
    },
    {
      Properties: {
        ProjectID: 1823,
        ProjectNo: 'P10.0274',
        ProjectName: '_TIM - Customer Events',
        ProjectManagerID: 53,
        ProjectManagerName: 'Sascha Elizabeth Houlberg Skydsgaard',
        ProjectManagerInitials: 'SES',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 48,
        DepartmentName: '30. Marketing & Customer Relations',
      },
    },
    {
      Properties: {
        ProjectID: 1470,
        ProjectNo: 'P09.0257',
        ProjectName: '_TIM - Customer service (1 to many)',
        ProjectManagerID: 155,
        ProjectManagerName: 'Farooq Rashid',
        ProjectManagerInitials: 'FRA',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 43,
        DepartmentName: '33. Customer Services',
      },
    },
    {
      Properties: {
        ProjectID: 2314,
        ProjectNo: 'P12.0131',
        ProjectName: '_TIM - Customer service (adm)',
        ProjectManagerID: 155,
        ProjectManagerName: 'Farooq Rashid',
        ProjectManagerInitials: 'FRA',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 43,
        DepartmentName: '33. Customer Services',
      },
    },
    {
      Properties: {
        ProjectID: 11789,
        ProjectNo: 'P16.0152',
        ProjectName: '_TIM - Customizations',
        ProjectManagerID: 48,
        ProjectManagerName: 'Thomas S. Gudmandsen',
        ProjectManagerInitials: 'TSG',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 51,
        DepartmentName: '50. TL Consulting',
      },
    },
    {
      Properties: {
        ProjectID: 4871,
        ProjectNo: 'P13.0322',
        ProjectName: '_TIM - Development of product test',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 34,
        DepartmentName: '20. DK Sales',
      },
    },
    {
      Properties: {
        ProjectID: 6004,
        ProjectNo: 'P14.0059',
        ProjectName: '_TIM - Education',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 34,
        DepartmentName: '20. DK Sales',
      },
    },
    {
      Properties: {
        ProjectID: 1200,
        ProjectNo: 'P08.0254',
        ProjectName: '_TIM - EU',
        ProjectManagerID: 20,
        ProjectManagerName: 'Peder Wuth Pedersen',
        ProjectManagerInitials: 'PWP',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 23,
        DepartmentName: 'Services',
      },
    },
    {
      Properties: {
        ProjectID: 12184,
        ProjectNo: 'P17.0046',
        ProjectName: '_TIM - Financial Integration maintenance',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 5922,
        ProjectNo: 'P13.0375',
        ProjectName: '_TIM - Foredrag (ADDA)',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 34,
        DepartmentName: '20. DK Sales',
      },
    },
    {
      Properties: {
        ProjectID: 2346,
        ProjectNo: 'P12.0163',
        ProjectName: '_TIM - General documentation',
        ProjectManagerID: 53,
        ProjectManagerName: 'Sascha Elizabeth Houlberg Skydsgaard',
        ProjectManagerInitials: 'SES',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 48,
        DepartmentName: '30. Marketing & Customer Relations',
      },
    },
    {
      Properties: {
        ProjectID: 2461,
        ProjectNo: 'P12.0278',
        ProjectName: '_TIM - Globase drift',
        ProjectManagerID: 53,
        ProjectManagerName: 'Sascha Elizabeth Houlberg Skydsgaard',
        ProjectManagerInitials: 'SES',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 48,
        DepartmentName: '30. Marketing & Customer Relations',
      },
    },
    {
      Properties: {
        ProjectID: 5945,
        ProjectNo: 'P13.0398',
        ProjectName: '_TIM - Help Desk / Support management',
        ProjectManagerID: 53,
        ProjectManagerName: 'Sascha Elizabeth Houlberg Skydsgaard',
        ProjectManagerInitials: 'SES',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 48,
        DepartmentName: '30. Marketing & Customer Relations',
      },
    },
    {
      Properties: {
        ProjectID: 3564,
        ProjectNo: 'P13.0015',
        ProjectName: '_TIM - Help Desk 2013',
        ProjectManagerID: 53,
        ProjectManagerName: 'Sascha Elizabeth Houlberg Skydsgaard',
        ProjectManagerInitials: 'SES',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 48,
        DepartmentName: '30. Marketing & Customer Relations',
      },
    },
    {
      Properties: {
        ProjectID: 8244,
        ProjectNo: 'P15.0004',
        ProjectName: '_TIM - Hosted Patching',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 7154,
        ProjectNo: 'P14.0213',
        ProjectName: '_TIM - HR',
        ProjectManagerID: 53,
        ProjectManagerName: 'Sascha Elizabeth Houlberg Skydsgaard',
        ProjectManagerInitials: 'SES',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 32,
        DepartmentName: '10. Stab',
      },
    },
    {
      Properties: {
        ProjectID: 9399,
        ProjectNo: 'P15.0160',
        ProjectName: '_TIM - Improved Expense Import',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 51,
        DepartmentName: '50. TL Consulting',
      },
    },
    {
      Properties: {
        ProjectID: 1479,
        ProjectNo: 'P09.0266',
        ProjectName: '_TIM - Intern IT 2009',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 1712,
        ProjectNo: 'P10.0163',
        ProjectName: '_TIM - Internal IT 2010',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 1814,
        ProjectNo: 'P10.0265',
        ProjectName: '_TIM - Internal IT 2011',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 2193,
        ProjectNo: 'P12.0010',
        ProjectName: '_TIM - Internal IT 2012',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 3551,
        ProjectNo: 'P13.0002',
        ProjectName: '_TIM - Internal IT 2013',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 5948,
        ProjectNo: 'P14.0003',
        ProjectName: '_TIM - Internal IT 2014',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 8243,
        ProjectNo: 'P15.0003',
        ProjectName: '_TIM - Internal IT services 2015',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 1473,
        ProjectNo: 'P09.0260',
        ProjectName: '_TIM - Internal meetings',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 32,
        DepartmentName: '10. Stab',
      },
    },
    {
      Properties: {
        ProjectID: 8210,
        ProjectNo: 'P14.0270',
        ProjectName: '_TIM - Konsulentopgaver UB',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 20619,
        CustomerName: 'TimeLog Customer Cases',
        DepartmentID: 51,
        DepartmentName: '50. TL Consulting',
      },
    },
    {
      Properties: {
        ProjectID: 1757,
        ProjectNo: 'P10.0208',
        ProjectName: '_TIM - KPI-insight support',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 34,
        DepartmentName: '20. DK Sales',
      },
    },
    {
      Properties: {
        ProjectID: 8234,
        ProjectNo: 'P14.0298',
        ProjectName: '_TIM - Kurser H1, 2015',
        ProjectManagerID: 48,
        ProjectManagerName: 'Thomas S. Gudmandsen',
        ProjectManagerInitials: 'TSG',
        CustomerID: 15433,
        CustomerName: 'Kursus',
        DepartmentID: 51,
        DepartmentName: '50. TL Consulting',
      },
    },
    {
      Properties: {
        ProjectID: 3557,
        ProjectNo: 'P13.0008',
        ProjectName: '_TIM - Kurser Q1 2013',
        ProjectManagerID: 48,
        ProjectManagerName: 'Thomas S. Gudmandsen',
        ProjectManagerInitials: 'TSG',
        CustomerID: 15433,
        CustomerName: 'Kursus',
        DepartmentID: 51,
        DepartmentName: '50. TL Consulting',
      },
    },
    {
      Properties: {
        ProjectID: 2474,
        ProjectNo: 'P12.0291',
        ProjectName: '_TIM - Kurser Q4 2012',
        ProjectManagerID: 101,
        ProjectManagerName: 'Pernille Wilken Gaustad',
        ProjectManagerInitials: 'PGA',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 43,
        DepartmentName: '33. Customer Services',
      },
    },
    {
      Properties: {
        ProjectID: 2491,
        ProjectNo: 'P12.0308',
        ProjectName: '_TIM - Kurser Q4 2012',
        ProjectManagerID: 48,
        ProjectManagerName: 'Thomas S. Gudmandsen',
        ProjectManagerInitials: 'TSG',
        CustomerID: 15433,
        CustomerName: 'Kursus',
        DepartmentID: 51,
        DepartmentName: '50. TL Consulting',
      },
    },
    {
      Properties: {
        ProjectID: 8248,
        ProjectNo: 'P15.0008',
        ProjectName: '_TIM - Labels',
        ProjectManagerID: 133,
        ProjectManagerName: 'Maiken Pommer Blok',
        ProjectManagerInitials: 'MPB',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 48,
        DepartmentName: '30. Marketing & Customer Relations',
      },
    },
    {
      Properties: {
        ProjectID: 6021,
        ProjectNo: 'P14.0076',
        ProjectName: '_TIM - LYNC',
        ProjectManagerID: 103,
        ProjectManagerName: 'Søren Øxenhave',
        ProjectManagerInitials: 'SOX',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 50,
        DepartmentName: '43. DK Development',
      },
    },
    {
      Properties: {
        ProjectID: 1462,
        ProjectNo: 'P09.0249',
        ProjectName: '_TIM - Management',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 32,
        DepartmentName: '10. Stab',
      },
    },
    {
      Properties: {
        ProjectID: 9350,
        ProjectNo: 'P15.0111',
        ProjectName: '_TIM - Markedsundersøgelse',
        ProjectManagerID: 159,
        ProjectManagerName: 'Maya Lander Gornitzka',
        ProjectManagerInitials: 'MLG',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 34,
        DepartmentName: '20. DK Sales',
      },
    },
    {
      Properties: {
        ProjectID: 1797,
        ProjectNo: 'P10.0248',
        ProjectName: '_TIM - Marketing (Branding)',
        ProjectManagerID: 53,
        ProjectManagerName: 'Sascha Elizabeth Houlberg Skydsgaard',
        ProjectManagerInitials: 'SES',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 48,
        DepartmentName: '30. Marketing & Customer Relations',
      },
    },
    {
      Properties: {
        ProjectID: 1471,
        ProjectNo: 'P09.0258',
        ProjectName: '_TIM - Marketing (Leadgenererende)',
        ProjectManagerID: 53,
        ProjectManagerName: 'Sascha Elizabeth Houlberg Skydsgaard',
        ProjectManagerInitials: 'SES',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 48,
        DepartmentName: '30. Marketing & Customer Relations',
      },
    },
    {
      Properties: {
        ProjectID: 975,
        ProjectNo: 'P08.0029',
        ProjectName: '_TIM - Marketing 2008',
        ProjectManagerID: 4,
        ProjectManagerName: 'Jakob Mikkelsen',
        ProjectManagerInitials: 'JAM',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 2,
        DepartmentName: '25. SE Sales',
      },
    },
    {
      Properties: {
        ProjectID: 1509,
        ProjectNo: 'P09.0296',
        ProjectName: '_TIM - MUS',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 32,
        DepartmentName: '10. Stab',
      },
    },
    {
      Properties: {
        ProjectID: 10494,
        ProjectNo: 'P15.0256',
        ProjectName: '_TIM - Netherlands office',
        ProjectManagerID: 159,
        ProjectManagerName: 'Maya Lander Gornitzka',
        ProjectManagerInitials: 'MLG',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 34,
        DepartmentName: '20. DK Sales',
      },
    },
    {
      Properties: {
        ProjectID: 1655,
        ProjectNo: 'P10.0106',
        ProjectName: '_TIM - Nyhedsbrev',
        ProjectManagerID: 53,
        ProjectManagerName: 'Sascha Elizabeth Houlberg Skydsgaard',
        ProjectManagerInitials: 'SES',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 32,
        DepartmentName: '10. Stab',
      },
    },
    {
      Properties: {
        ProjectID: 6003,
        ProjectNo: 'P14.0058',
        ProjectName: '_TIM - Nyt tilbudsmateriale',
        ProjectManagerID: 108,
        ProjectManagerName: 'Anette Bangsfelt',
        ProjectManagerInitials: 'ABA',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 48,
        DepartmentName: '30. Marketing & Customer Relations',
      },
    },
    {
      Properties: {
        ProjectID: 3552,
        ProjectNo: 'P13.0003',
        ProjectName: '_TIM - On Premise patching 2013',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 5949,
        ProjectNo: 'P14.0004',
        ProjectName: '_TIM - On Premise Patching 2014',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 8245,
        ProjectNo: 'P15.0005',
        ProjectName: '_TIM - On Premise Patching 2015',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 10461,
        ProjectNo: 'P15.0223',
        ProjectName: '_TIM - Open workshop afholdelse',
        ProjectManagerID: 48,
        ProjectManagerName: 'Thomas S. Gudmandsen',
        ProjectManagerInitials: 'TSG',
        CustomerID: 15433,
        CustomerName: 'Kursus',
        DepartmentID: 51,
        DepartmentName: '50. TL Consulting',
      },
    },
    {
      Properties: {
        ProjectID: 6030,
        ProjectNo: 'P14.0085',
        ProjectName: '_TIM - Partnersamarbejde',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 34,
        DepartmentName: '20. DK Sales',
      },
    },
    {
      Properties: {
        ProjectID: 3655,
        ProjectNo: 'P13.0105',
        ProjectName: '_TIM - Party in TimeLog',
        ProjectManagerID: 114,
        ProjectManagerName: 'Charlotte Schilling',
        ProjectManagerInitials: 'CSC',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 43,
        DepartmentName: '33. Customer Services',
      },
    },
    {
      Properties: {
        ProjectID: 1816,
        ProjectNo: 'P10.0267',
        ProjectName: '_TIM - Patching Fixed Cost 2011',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 3553,
        ProjectNo: 'P13.0004',
        ProjectName: '_TIM - Patching hosted customers 2013',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 5950,
        ProjectNo: 'P14.0005',
        ProjectName: '_TIM - Patching Hosted Customers 2014',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 1817,
        ProjectNo: 'P10.0268',
        ProjectName: '_TIM - Patching One Customer 2011',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 1481,
        ProjectNo: 'P09.0268',
        ProjectName: '_TIM - Patchning Fix cost',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 1482,
        ProjectNo: 'P09.0269',
        ProjectName: '_TIM - Patchning/oprettelse variable',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 2181,
        ProjectNo: 'P11.0347',
        ProjectName: '_TIM - PGA-Opsamlingsprojekt',
        ProjectManagerID: 101,
        ProjectManagerName: 'Pernille Wilken Gaustad',
        ProjectManagerInitials: 'PGA',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 51,
        DepartmentName: '50. TL Consulting',
      },
    },
    {
      Properties: {
        ProjectID: 1082,
        ProjectNo: 'P08.0136',
        ProjectName: '_TIM - Processer i salg og PS',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 30,
        DepartmentName: 'Management',
      },
    },
    {
      Properties: {
        ProjectID: 9338,
        ProjectNo: 'P15.0099',
        ProjectName: '_TIM - Product development',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 51,
        DepartmentName: '50. TL Consulting',
      },
    },
    {
      Properties: {
        ProjectID: 1480,
        ProjectNo: 'P09.0267',
        ProjectName: '_TIM - Production- and development enviroment',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 1818,
        ProjectNo: 'P10.0269',
        ProjectName: '_TIM - Production platform 2011',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 3513,
        ProjectNo: 'P12.0331',
        ProjectName: '_TIM - Production platform 2013',
        ProjectManagerID: 62,
        ProjectManagerName: 'Martin Madsen',
        ProjectManagerInitials: 'MRM',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 5951,
        ProjectNo: 'P14.0006',
        ProjectName: '_TIM - Production platform 2014',
        ProjectManagerID: 7,
        ProjectManagerName: 'Christoffer Hostrup Lanstorp',
        ProjectManagerInitials: 'CHL',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 807,
        ProjectNo: 'P07.0151',
        ProjectName: '_TIM - PS materiale',
        ProjectManagerID: 20,
        ProjectManagerName: 'Peder Wuth Pedersen',
        ProjectManagerInitials: 'PWP',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 23,
        DepartmentName: 'Services',
      },
    },
    {
      Properties: {
        ProjectID: 11811,
        ProjectNo: 'P16.0175',
        ProjectName: '_TIM - Red Heering',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 32,
        DepartmentName: '10. Stab',
      },
    },
    {
      Properties: {
        ProjectID: 1468,
        ProjectNo: 'P09.0255',
        ProjectName: '_TIM - Sales (Add Bizz)',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 30,
        DepartmentName: 'Management',
      },
    },
    {
      Properties: {
        ProjectID: 1463,
        ProjectNo: 'P09.0250',
        ProjectName: '_TIM - Sales (New Bizz)',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 34,
        DepartmentName: '20. DK Sales',
      },
    },
    {
      Properties: {
        ProjectID: 3554,
        ProjectNo: 'P13.0005',
        ProjectName: '_TIM - Sales (New Bizz)',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 35,
        DepartmentName: 'Sales',
      },
    },
    {
      Properties: {
        ProjectID: 2173,
        ProjectNo: 'P11.0339',
        ProjectName: '_TIM - Sales (New Bizz) 2012',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 35,
        DepartmentName: 'Sales',
      },
    },
    {
      Properties: {
        ProjectID: 1492,
        ProjectNo: 'P09.0279',
        ProjectName: '_TIM - Sales (Spec. af udviklingsopgaver)',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 25,
        DepartmentName: '40. R&D',
      },
    },
    {
      Properties: {
        ProjectID: 1725,
        ProjectNo: 'P10.0176',
        ProjectName: '_TIM - Sales Administration',
        ProjectManagerID: 159,
        ProjectManagerName: 'Maya Lander Gornitzka',
        ProjectManagerInitials: 'MLG',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 48,
        DepartmentName: '30. Marketing & Customer Relations',
      },
    },
    {
      Properties: {
        ProjectID: 12008,
        ProjectNo: 'P16.0373',
        ProjectName: '_TIM - Sales LEAN onboarding',
        ProjectManagerID: 159,
        ProjectManagerName: 'Maya Lander Gornitzka',
        ProjectManagerInitials: 'MLG',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 34,
        DepartmentName: '20. DK Sales',
      },
    },
    {
      Properties: {
        ProjectID: 2325,
        ProjectNo: 'P12.0142',
        ProjectName: '_TIM - Sales Management',
        ProjectManagerID: 3,
        ProjectManagerName: 'Søren Lund',
        ProjectManagerInitials: 'SLU',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 32,
        DepartmentName: '10. Stab',
      },
    },
    {
      Properties: {
        ProjectID: 10455,
        ProjectNo: 'P15.0217',
        ProjectName: '_TIM - Sales, New Bizz',
        ProjectManagerID: 159,
        ProjectManagerName: 'Maya Lander Gornitzka',
        ProjectManagerInitials: 'MLG',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 34,
        DepartmentName: '20. DK Sales',
      },
    },
    {
      Properties: {
        ProjectID: 11719,
        ProjectNo: 'P16.0080',
        ProjectName: '_TIM - SCRUM 2016',
        ProjectManagerID: 103,
        ProjectManagerName: 'Søren Øxenhave',
        ProjectManagerInitials: 'SOX',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 50,
        DepartmentName: '43. DK Development',
      },
    },
    {
      Properties: {
        ProjectID: 12141,
        ProjectNo: 'P17.0013',
        ProjectName: '_TIM - SCRUM 2017',
        ProjectManagerID: 103,
        ProjectManagerName: 'Søren Øxenhave',
        ProjectManagerInitials: 'SOX',
        CustomerID: 1,
        CustomerName: 'TimeLog A/S ABC',
        DepartmentID: 50,
        DepartmentName: '43. DK Development',
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/filters/results/poc-table',
      Rel: 'self',
    },
  ],
};
