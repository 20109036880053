import { FC, HTMLAttributes } from 'react';

import styles from './IconButtonGroup.module.scss';

interface IconButtonGroupProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'className' | 'style'> {}

export const IconButtonGroup: FC<IconButtonGroupProps> = (props) => (
  <div className={styles.iconButtonGroup} {...props} />
);

export default IconButtonGroup;
