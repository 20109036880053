import { useQuery } from '@tanstack/react-query';
import { ISearchTaskByNameResult } from 'src/apis/types/resourcePlannerAPI';
import { getFetch } from 'src/utils/fetcher';
import { concatQuerys } from 'src/utils/url';

const SEARCH_TASK_BY_NAME_URL_PATH = '/api/v2/resource-planner/search-task-by-name';
export const SEARCH_TASK_BY_NAME_MSW_STATUS_KEY = 'GetSearchTaskByNameAPI';

export const SEARCH_TASK_BY_NAME_KEY = 'SEARCH_TASK_BY_NAME';

const searchTaskByNameFetch = (
  resourceId: string,
  searchText: string,
): Promise<ISearchTaskByNameResult> =>
  getFetch({
    path: `${SEARCH_TASK_BY_NAME_URL_PATH}${concatQuerys([
      {
        param: 'resourceId',
        value: resourceId,
      },
      {
        param: 'searchText',
        value: searchText,
      },
    ])}`,
    key: SEARCH_TASK_BY_NAME_MSW_STATUS_KEY,
  });

export const useGetSearchTaskByNameAPI = (resourceId: string, searchText: string) => {
  const { data: { model: { properties: { workItems = [] } = {} } = {} } = {}, ...restProps } =
    useQuery(
      [SEARCH_TASK_BY_NAME_KEY, resourceId, searchText],
      () => searchTaskByNameFetch(resourceId, searchText),
      { enabled: searchText !== '' },
    );
  return {
    workItems,
    ...restProps,
  };
};
