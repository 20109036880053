import { createContext, useContext, useReducer } from 'react';
import { useHelpURLInfo } from 'src/apis/usersAPI/meAPI';
import { useGetHelpInfo } from 'src/apis/indexAPI';
import { useGetCurrentLanguage } from 'src/apis/userSettingsAPI';
import PageReducer, { initPageStore, PageProps, PageActionProps } from 'src/reducers/PageReducer';
import { concatQuerys } from 'src/utils/url';

const PageStoreContext = createContext<PageProps>(initPageStore);
const PageDispatchContext = createContext<React.Dispatch<PageActionProps> | undefined>(undefined);

export const PageStoreProviderForTest = PageStoreContext.Provider;

export const usePageDispatch = (): React.Dispatch<PageActionProps> | undefined =>
  useContext(PageDispatchContext);

export const usePageStore = (): PageProps => useContext(PageStoreContext);

export const useGetPageSetting = (): string => {
  const {
    lastPage: { pageSettingUrl },
  } = useContext(PageStoreContext);

  return pageSettingUrl;
};

export const useGetBackToTimeLogPage = (): string => {
  const {
    lastPage: { pageUrl },
  } = useContext(PageStoreContext);

  return pageUrl;
};

export const useGetCurrentPageId = (): string => {
  const {
    currentPage: { pageId },
  } = useContext(PageStoreContext);

  return pageId;
};

export const useGetCurrentPageIdentifier = (): string => {
  const {
    currentPage: { pageIdentifier },
  } = useContext(PageStoreContext);

  return pageIdentifier;
};

export const useGetCurrentPageTitle = (): string => {
  const {
    currentPage: { pageTitle },
  } = useContext(PageStoreContext);

  return pageTitle;
};

export const useGetCurrentHelpPageUrl = (): string => {
  const {
    currentPage: { pageId },
    currentModalPage: { pageId: pageModalId },
  } = useContext(PageStoreContext);

  const {
    helpInfo: { email, initials },
  } = useHelpURLInfo();

  const {
    helpInfo: { version, shortName, edition },
  } = useGetHelpInfo();

  const { longStringLanguage } = useGetCurrentLanguage();

  return `https://help.timelog.com/${concatQuerys([
    { param: 'pageId', value: pageModalId || pageId },
    { param: 'shortName', value: shortName },
    { param: 'initials', value: initials },
    { param: 'email', value: email },
    { param: 'language', value: longStringLanguage },
    { param: 'version', value: version },
    { param: 'edition', value: edition },
  ])}`;
};

interface IPageStoreProps {
  children: JSX.Element | JSX.Element[];
}

export default ({ children }: IPageStoreProps) => {
  const [pageState, pageDispatch] = useReducer(PageReducer, initPageStore);

  return (
    <PageStoreContext.Provider value={pageState}>
      <PageDispatchContext.Provider value={pageDispatch}>{children}</PageDispatchContext.Provider>
    </PageStoreContext.Provider>
  );
};
