import { createContext, useContext, useReducer } from 'react';
import sidePanelReducer, {
  initSidePanelStore,
  SidePanelActionProps,
  SidePanelStoreProps,
} from '../reducer';

interface ISidePanelStoreProps {
  children: JSX.Element | JSX.Element[];
}
const SidePanelStoreContext = createContext<SidePanelStoreProps>(initSidePanelStore);
const SidePanelDispatchContext = createContext<React.Dispatch<SidePanelActionProps> | undefined>(
  undefined,
);
export const useSidePanelDispatch = () => useContext(SidePanelDispatchContext);
export const useSidePanelStore = () => useContext(SidePanelStoreContext);
export const useIsSidePanelOpen = () => {
  const { isSidePanelOpen } = useContext(SidePanelStoreContext);
  return isSidePanelOpen;
};
export const useSidePanelPosition = () => {
  const { sidePanelStage } = useContext(SidePanelStoreContext);
  return sidePanelStage;
};

export default ({ children }: ISidePanelStoreProps) => {
  const [sidePanelState, sidePanelDispatch] = useReducer(sidePanelReducer, initSidePanelStore);

  return (
    <SidePanelStoreContext.Provider value={sidePanelState}>
      <SidePanelDispatchContext.Provider value={sidePanelDispatch}>
        {children}
      </SidePanelDispatchContext.Provider>
    </SidePanelStoreContext.Provider>
  );
};
