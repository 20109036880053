const billablePercentageFullResult = {
  Properties: {
    UserId: 1,
    CurrentMonthToDateBillablePercentage: 41,
    LastMonthBillablePercentage: 5,
    AverageHourlyRate: 45,
    CurrencyName: 'Euro',
    CurrencyAbb: 'EUR',
  },
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/widget-data/billable-percentage?currentDate=2022-03-08',
      Rel: 'self',
    },
  ],
};

export default billablePercentageFullResult;
