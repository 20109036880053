import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IRpCountResponse } from 'src/apis/types/resourcePlannerAPI';

const RP_PLAN_COUNT_URL_PATH = '/api/v2/resource-planner/rp-plan-count';
export const RP_PLAN_COUNT_MSW_STATUS_KEY = 'GetRpPlanCount';

export const RP_PLAN_COUNT_KEY = 'RP_PLAN_COUNT';
const oneSecond = 1000;

const resourcePlannerFetch = (): Promise<IRpCountResponse> =>
  getFetch({ path: RP_PLAN_COUNT_URL_PATH, key: RP_PLAN_COUNT_MSW_STATUS_KEY });

const useGetRpPlanCountQuery = (refetch: boolean) =>
  useQuery([RP_PLAN_COUNT_KEY], resourcePlannerFetch, {
    refetchInterval: refetch ? oneSecond : undefined,
  });

export const useGetRpPlanCount = (refetch: boolean) => {
  const { data, ...restProps } = useGetRpPlanCountQuery(refetch);

  return {
    count: data?.model?.properties.count ?? 0,
    ...restProps,
  };
};
