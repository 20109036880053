import { MouseEventHandler } from 'react';
import { useDayPicker, WeekNumberProps } from 'react-day-picker';
import { getWeek, getYear } from 'date-fns';

export const WeekNumber = ({ number, dates }: WeekNumberProps) => {
  const {
    locale,
    weekStartsOn,
    fromDate,
    onWeekNumberClick,
    classNames: weekNumberClassNames,
    labels: { labelWeekNumber },
  } = useDayPicker();

  if (!onWeekNumberClick) {
    return <span className={weekNumberClassNames.weeknumber}>{number}</span>;
  }

  const handleClick: MouseEventHandler = (e) => {
    onWeekNumberClick(number, dates, e);
  };

  const label = labelWeekNumber(Number(number));

  const yearOfWeek = getYear(dates[0]);
  const fromYear = fromDate && getYear(fromDate);

  const fromDateWeekNumber = fromDate && getWeek(fromDate, { locale, weekStartsOn });
  const disable = !!(fromDateWeekNumber && fromDateWeekNumber > number && yearOfWeek === fromYear);

  return (
    <button
      aria-label={label}
      className={weekNumberClassNames.weeknumber}
      onClick={handleClick}
      type="button"
      disabled={disable}
    >
      {number}
    </button>
  );
};
