import {
  ButtonGhost,
  SearchInput,
  Flex,
  Stack,
  Text,
  Shimmer,
  Icon,
} from 'src/components/ui-components';
import { DateTime } from 'luxon';
import { useState, ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ISearchResource } from 'src/apis/types/resourcePlannerAPI';
import {
  useGetSearchResourceByNameAPI,
  usePostCreateWorkItemRelationship,
} from 'src/apis/resourcePlannerAPI';
import { useDebounce } from 'use-debounce';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { AssignAction } from '../AssignAction';
import AssignItem from '../AssignItem';
import { ScrollContainer } from '../ScrollContainer';
import { AssignButton } from '../AssignButton';
import { AssignmentSelectorFittedProps } from '../../types';

export const ResourceSelector = ({
  itemId,
  itemSourceReferenceId,
  itemType,
  itemTitle,
  ...props
}: AssignmentSelectorFittedProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedInputValue] = useDebounce(searchValue, 100);
  const [selectedStartDate] = useState<DateTime | null>(null);
  const [selectedEndDate] = useState<DateTime | null>(null);
  const [totalHours] = useState('');
  const [selectedResource, setSelectedResource] = useState<ISearchResource>();
  const { t } = useTranslation('resourcePlanner');
  const { isInitialLoading, isError, isSuccess, resources } = useGetSearchResourceByNameAPI(
    itemId,
    debouncedInputValue,
  );
  const searchInputElement = useRef<HTMLInputElement>(null);
  const changeButton = useRef<HTMLButtonElement>(null);

  const reset = () => {
    setSearchValue('');
    setSelectedResource(undefined);
    setModalIsOpen(false);
  };

  const select = (item: ISearchResource) => {
    setSelectedResource(item);

    // this is to bring focus to the view where you can see
    // the chosen task. Otherwise Escape wouldn't work
    setTimeout(() => changeButton.current?.focus(), 100);
  };

  const { mutate: postAssignResourceToTask } = usePostCreateWorkItemRelationship();

  const doAssign = () => {
    if (selectedResource) {
      postAssignResourceToTask({
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        totalHours,
        workItemId: itemId,
        workItemSourceReferenceId: itemSourceReferenceId,
        workItemType: itemType,
        resourceId: selectedResource?.id,
        resourceSourceReferenceId: selectedResource?.sourceReferenceId,
        resourceType: selectedResource?.type,
      });
      reset();
    }
  };

  return (
    <AssignAction
      {...props}
      heading={
        <>
          <Text as="span" inheritSize>
            {t('Assign')}
          </Text>{' '}
          <Text as="span" emphasize inheritSize semibold>
            {itemTitle}
          </Text>{' '}
          {selectedResource && (
            <Text as="span" inheritSize>
              to
            </Text>
          )}
        </>
      }
      initialFocusRef={searchInputElement}
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      onDismiss={reset}
      footerComponent={
        selectedResource && (
          <>
            <ButtonGhost onClick={reset}>{t('Cancel')}</ButtonGhost>
            <ButtonGhost onClick={doAssign}>{t('Assign')}</ButtonGhost>
          </>
        )
      }
    >
      {selectedResource ? (
        <Stack verticalMargin="small">
          <AssignItem
            mainText={selectedResource.fullName}
            secondaryText={selectedResource.position}
            tertiaryText={selectedResource.anotherText}
            id={selectedResource.sourceReferenceId}
            type={selectedResource.type}
          />
          <ButtonGhost onClick={() => setSelectedResource(undefined)} ref={changeButton}>
            {t('ChangePerson')}
          </ButtonGhost>
        </Stack>
      ) : (
        <Stack verticalMargin="medium">
          <SearchInput
            clearInput={() => setSearchValue('')}
            ref={searchInputElement}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
            value={searchValue}
            clearInputText=""
            label={t('SearchByName')}
            hiddenLabel
            placeholder={t('SearchByName')}
            autoComplete="off"
          />
          <Flex>
            <Icon iconName="info" />
            <Text size="tips">{t('SplitSearchHelpText')}</Text>
          </Flex>
          {searchValue && (
            <ResponseHandler
              isLoading={isInitialLoading}
              isError={isError}
              isEmpty={isSuccess && resources.length === 0}
              LoadingComponent={
                <ScrollContainer>
                  <Flex>
                    <Shimmer width="xLarge" isRound isSquare />
                    <Flex.Item fillRest>
                      <Stack verticalMargin="xxSmall">
                        <div>
                          <Shimmer minHeight="body" width="quarter" />
                        </div>
                        <Shimmer minHeight="body" width="thirdQuarter" />
                      </Stack>
                    </Flex.Item>
                  </Flex>
                </ScrollContainer>
              }
              ErrorComponent={
                <ScrollContainer>
                  <Text>{t('SearchByNameErrorMessage')}</Text>
                </ScrollContainer>
              }
              EmptyComponent={
                <ScrollContainer>
                  <Text>{t('SearchByNameNoResultsMessage')}</Text>
                </ScrollContainer>
              }
            >
              <ScrollContainer>
                {resources.map((resource: ISearchResource) => (
                  <AssignButton onClick={() => select(resource)} key={resource.id}>
                    <AssignItem
                      mainText={resource.fullName}
                      secondaryText={resource.position}
                      tertiaryText={resource.anotherText}
                      id={resource.sourceReferenceId}
                      type={resource.type}
                      searchText={searchValue}
                    />
                  </AssignButton>
                ))}
              </ScrollContainer>
            </ResponseHandler>
          )}
        </Stack>
      )}
    </AssignAction>
  );
};
