import { useTranslation } from 'react-i18next';
import { useActive, useCommands } from '@remirror/react';
import { Select } from 'src/components/ui-components/Select';
import { ChangeEvent } from 'react';

export const SelectHeading = () => {
  const commands = useCommands();
  const active = useActive();

  const { t } = useTranslation('richTextEditor');

  const selectOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    switch (value) {
      case 'quote':
        commands.toggleBlockquote();
        break;
      case 'paragraph':
        commands.toggleHeading();
        break;
      case 'h1':
        commands.toggleHeading({ level: 1 });
        break;
      case 'h2':
        commands.toggleHeading({ level: 2 });
        break;
      case 'h3':
        commands.toggleHeading({ level: 3 });
        break;
      default:
        break;
    }
    commands.focus();
  };

  const getValue = () => {
    if (active.heading({ level: 1 })) {
      return 'h1';
    }
    if (active.heading({ level: 2 })) {
      return 'h2';
    }
    if (active.heading({ level: 3 })) {
      return 'h3';
    }
    if (!(active.heading() || active.blockquote() || active.codeBlock())) {
      return 'paragraph';
    }
    if (active.blockquote()) {
      return 'quote';
    }

    return undefined;
  };

  return (
    <Select
      data-automation-id="slctHeading"
      label="Formats"
      hiddenLabel
      onChange={selectOnChange}
      disabled={active.codeBlock() || !commands.toggleHeading.enabled()}
      options={[
        { value: 'h1', name: t('FormatHeading1Label') },
        { value: 'h2', name: t('FormatHeading2Label') },
        { value: 'h3', name: t('FormatHeading3Label') },
        {
          value: 'paragraph',
          name: t('ParagraphLabel'),
        },
        {
          value: 'quote',
          name: t('FormatQuoteLabel'),
        },
      ]}
      name="formats"
      value={getValue()}
    />
  );
};
