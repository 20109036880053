import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IInternalMessage } from 'src/apis/types/internalMessageAPI';

const INTERNAL_MESSAGE_URL_PATH = '/api/v2/settings/frontpage-internal-message';
export const INTERNAL_MESSAGE_MSW_STATUS_KEY = 'internalMessageAPI';

export const INTERNAL_MESSAGE_KEY = 'INTERNAL_MESSAGE';

const getInternalMessage = (): Promise<IInternalMessage> =>
  getFetch({ path: INTERNAL_MESSAGE_URL_PATH, key: INTERNAL_MESSAGE_MSW_STATUS_KEY });

const useGetInternalMessageQuery = () => useQuery([INTERNAL_MESSAGE_KEY], getInternalMessage);

export const useGetInternalMessage = () => {
  const { data, ...restProps } = useGetInternalMessageQuery();
  return {
    internalMessage: data?.properties?.value ?? '',
    isEditable: data?.properties?.isEditable ?? false,
    ...restProps,
  };
};

export default getInternalMessage;
