const externalPercentageResult = {
  Properties: {
    UserId: 1,
    CurrentMonthPercentage: 0,
    LastMonthPercentage: 2,
    LastThreeMonthsPercentage: 3,
  },
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/widget-data/external-percentage?currentDate=2021-10-30',
      Rel: 'self',
    },
  ],
};

export default externalPercentageResult;
