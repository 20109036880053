import { useQueryClient } from '@tanstack/react-query';
import { Flex } from 'src/components/ui-components/Flex';
import { Heading } from 'src/components/ui-components/Heading';
import { IconButton } from 'src/components/ui-components/IconButton';
import { Stack } from 'src/components/ui-components/Stack';
import { useState } from 'react';
import { useGetInternalMessage, useUpdateInternalMessage } from 'src/apis/internalMessageAPI';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { RichTextEditor } from 'src/components/ui-components/RichText';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ToastItem } from 'src/components/ui-components/ToastItem';
import { StaticText } from './components';
import styles from './InternalMessageWidget.module.scss';

export const InternalMessageWidget = () => {
  const { isError, isLoading, internalMessage, isEditable } = useGetInternalMessage();

  const { t } = useTranslation('frontpage');
  const queryClient = useQueryClient();

  const {
    mutate: internalMessageAPIUpdate,
    isLoading: mutationIsLoading,
    isError: mutationIsError,
  } = useUpdateInternalMessage();

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleUpdate = (html: string) => {
    internalMessageAPIUpdate(html, {
      onSuccess: () => {
        queryClient.invalidateQueries(['INTERNAL_MESSAGE']);
        toast.success(<ToastItem message={t('InternalMessageIsSavedFeedback')} />, {
          autoClose: 2000,
          closeButton: false,
        });
      },
    });
  };

  const exitFunction = () => {
    setIsEditing(false);
  };

  return (
    <Stack
      verticalMargin="medium"
      data-automation-id="InternalMessageWidget"
      className={styles.wrapper}
    >
      <Flex horizontalAlignment="justify">
        <Heading level={2} as={3} bold data-automation-id="InternalMessageWidgetHeading">
          {t('InternalMessageWidgetHeading')}
        </Heading>
        {isEditable && (
          <IconButton
            data-automation-id="InternalMessageButtonEdit"
            size="compact"
            iconName={isEditing ? 'cross' : 'edit'}
            tooltipText={
              isEditing
                ? t('InternalMessageWidgetCancelEdit')
                : t('InternalMessageWidgetEditInternalMessage')
            }
            onClick={() => {
              setIsEditing((prev) => !prev);
            }}
          />
        )}
      </Flex>
      <ResponseHandler
        isError={isError || mutationIsError}
        isLoading={isLoading || mutationIsLoading}
      >
        {isEditable && isEditing ? (
          <RichTextEditor
            data-automation-id="InternalMessageTextEditable"
            value={internalMessage}
            saveFunction={handleUpdate}
            exitFunction={exitFunction}
          />
        ) : (
          <StaticText
            isEmpty={internalMessage === '<p style=""></p>' || internalMessage === ''}
            internalMessage={internalMessage}
            standardText={t('InternalMessageWidgetNoMessage')}
          />
        )}
      </ResponseHandler>
    </Stack>
  );
};

export default InternalMessageWidget;
