import { addDays, format, parseISO } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IExchangeRatePeriod, useUpdateAutomationSettings } from 'src/apis/exchangeRateAPI';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from 'src/components/mui-components';
import { monthInNumber } from 'src/utils/date';
import { handleError } from '../../utils/serverResponseHandler';

interface SetupAutomationModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  latestPeriod: IExchangeRatePeriod;
  currentMode: keyof IExchangeRatePeriodPollType;
}

interface IExchangeRatePeriodPollType {
  Automatic: number;
  Manual: number;
}

export const ExchangeRatePeriodPollType: IExchangeRatePeriodPollType = {
  Automatic: 1,
  Manual: 2,
};

const SetupAutomationModal: React.FC<SetupAutomationModalProps> = ({
  isOpen,
  setIsOpen,
  latestPeriod,
  currentMode,
}) => {
  const { mutateAsync: updateAutoSettings } = useUpdateAutomationSettings();
  const [monthPeriod, setMonthPeriod] = useState<string>(monthInNumber[0]);
  const [selectedType, setSelectedType] = useState<number>(ExchangeRatePeriodPollType[currentMode]);
  const latestPeriodEndDate = parseISO(latestPeriod.periodEndDate);
  const nextPeriodStartDate = addDays(latestPeriodEndDate, 1);
  const nextPeriodStartDateMonthYearText = format(nextPeriodStartDate, 'MMMM yyyy');
  const { t } = useTranslation('systemAdminExchangeRate');

  const updateAutoSettingsAction = async () => {
    try {
      const response = await updateAutoSettings(
        {
          matrixMode: selectedType,
          periodLengthInMonth: selectedType === 1 ? Number(monthPeriod) : 0,
        },
        {
          onError: (error: any) => {
            handleError(error, t);
          },
        },
      );
      if (response) {
        return response;
      }
    } catch (error) {
      // no actions required, error handled
    } finally {
      setIsOpen(false);
    }

    return null;
  };

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = (event.target as HTMLInputElement).value;
    setSelectedType(ExchangeRatePeriodPollType[type as keyof IExchangeRatePeriodPollType]);
  };

  const onPeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setMonthPeriod((event.target as HTMLSelectElement).value);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="dialog-automation"
      aria-describedby="dialog-automation-desc"
    >
      <DialogTitle id="dialog-automation">{t('modals.SetupAutomationTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-automation-desc" sx={{ color: '#333' }}>
          {t('modals.SetupAutomationDescription')}
        </DialogContentText>

        <FormControl>
          <RadioGroup
            aria-labelledby="set-up-automation-label"
            name="set-up-automation-radio-group"
            defaultValue="Manual"
          >
            <FormControlLabel
              value="Automatic"
              control={
                <Radio
                  data-automation-id="AutomaticRadioButton"
                  checked={selectedType === 1}
                  onChange={(event) => onChangeValue(event)}
                />
              }
              sx={{
                alignItems: 'start',
                '.MuiRadio-root': {
                  paddingTop: '5px',
                },
              }}
              label={t('modals.SetupAutomationAutomaticModeDescription')}
            />
            <DialogContentText
              id="dialog-manual-description"
              sx={{ marginLeft: '2rem', color: '#333' }}
            >
              {`${t('modals.SetupAutomationUsePeriodsDescriptionA')} `}
              <select
                name="setupAutomation"
                id="setupAutomationId"
                form="autoform"
                onChange={(event) => onPeriodChange(event)}
                disabled={selectedType === 2}
                data-automation-id="PeriodSelectDropdown"
              >
                {monthInNumber.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>{' '}
              {`${t(
                'modals.SetupAutomationUsePeriodsDescriptionB',
              )} ${nextPeriodStartDateMonthYearText}`}
            </DialogContentText>
            <FormControlLabel
              value="Manual"
              control={
                <Radio
                  data-automation-id="ManualRadioButton"
                  checked={selectedType === 2}
                  onChange={(event) => onChangeValue(event)}
                />
              }
              sx={{
                alignItems: 'start',
                '.MuiRadio-root': {
                  paddingTop: '5px',
                },
              }}
              label={t('modals.SetupAutomationManualModeDescription')}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => updateAutoSettingsAction()}
          autoFocus
          data-automation-id="SetupAutomationModalSaveButton"
        >
          {t('modals.SetupAutomationSaveButton')}
        </Button>
        <Button
          onClick={() => setIsOpen(false)}
          data-automation-id="SetupAutomationModalCancelButton"
        >
          {t('modals.CancelButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SetupAutomationModal;
