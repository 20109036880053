import { useEffect, SetStateAction, Dispatch } from 'react';
import { useIsMainSearchOpen } from 'src/stores/MainSearchStore';

export const useResetInputEvent = (setSearchValue: Dispatch<SetStateAction<string>>) => {
  const mainSearchIsOpen = useIsMainSearchOpen();

  useEffect(() => {
    if (!mainSearchIsOpen) {
      setSearchValue('');
    }
  }, [mainSearchIsOpen, setSearchValue]);
};
