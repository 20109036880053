import { useTranslation } from 'react-i18next';
import { Icon } from 'src/components/ui-components';
import LayoutSettings from 'src/components/layout/LayoutSettings';
import LegacyPage from 'src/components/layout/LegacyPage';
import { usePageSettingsMenu } from 'src/apis/menusAPI/pageSettingsAPI';
import VerticalNavigationSearchBar from 'src/components/layout/VerticalNavigationSearchBar';

const icons = {
  Customers: <Icon iconName="customer" />,
  Invoices: <Icon iconName="finance" />,
  Approval: <Icon iconName="like" />,
  Employees: <Icon iconName="employee" />,
  Projects: <Icon iconName="project" />,
  Reports: <Icon iconName="report" />,
  Register: <Icon iconName="timeAndExp" />,
  Create: <Icon iconName="circlePlus" />,
};

export const PageSetting = () => {
  const { pageSettingMenu, error, isLoading } = usePageSettingsMenu();
  const { t } = useTranslation('pageSettings');
  return (
    <LayoutSettings
      navigation={
        <VerticalNavigationSearchBar
          icons={icons}
          t={t}
          menu={pageSettingMenu}
          error={error}
          isLoading={isLoading}
        />
      }
      title={t('HeaderTitle')}
      isHelpVisible={false}
    >
      <LegacyPage data-automation-id="LegacyPage" />
    </LayoutSettings>
  );
};
