import { RefObject } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { eventKeyDown, eventKeyUp, eventKeyEnter } from 'src/constants/keyboardKey';

const highlightSelectedElement = (element: Element) =>
  element.setAttribute('aria-selected', 'true');

const scrollToSelectedElement = (element: Element) =>
  element.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'nearest',
  });

export const blurSelectElement = (element: Element) =>
  element?.setAttribute('aria-selected', 'false');

export const reloadKeyboardSelectedElement = (
  selectedElement: Element,
  inputElement: RefObject<HTMLInputElement>,
) => {
  if (selectedElement) {
    highlightSelectedElement(selectedElement);
    scrollToSelectedElement(selectedElement);
    inputElement.current?.setAttribute('aria-activedescendant', selectedElement.id);
  } else {
    inputElement.current?.removeAttribute('aria-activedescendant');
  }
};

interface KeyboardActionProps {
  event: any;
  setSelectItemIndex: React.Dispatch<React.SetStateAction<number>>;
  selectItemIndex: number;
  onItemSelect: () => void;
  focusableElements: Element[];
  navigate: NavigateFunction;
}

export const keyboardAction = ({
  event,
  setSelectItemIndex,
  selectItemIndex,
  onItemSelect,
  navigate,
  focusableElements,
}: KeyboardActionProps): boolean => {
  const focusableElementsTagList = focusableElements?.map((el) => el.tagName);
  const firstButtonIndex = focusableElementsTagList.indexOf('BUTTON');
  const lastButtonIndex = focusableElementsTagList.lastIndexOf('BUTTON');
  const focusableLength = focusableElements.length - 1;
  const currentSelectedItem = focusableElements[selectItemIndex] as HTMLElement;
  const { key } = event;
  if (key === eventKeyUp && event.ctrlKey) {
    if (selectItemIndex === -1 || selectItemIndex === 0) {
      setSelectItemIndex(lastButtonIndex);
    } else {
      const previousButtonIndex = focusableElementsTagList.lastIndexOf(
        'BUTTON',
        selectItemIndex - 1,
      );
      setSelectItemIndex(previousButtonIndex);
    }
  } else if (key === eventKeyDown && event.ctrlKey) {
    if (selectItemIndex === lastButtonIndex || selectItemIndex > lastButtonIndex) {
      setSelectItemIndex(firstButtonIndex);
    } else {
      const nextButtonIndex = focusableElementsTagList.indexOf('BUTTON', selectItemIndex + 1);
      setSelectItemIndex(nextButtonIndex);
    }
  } else if (key === eventKeyUp) {
    event.preventDefault();
    if (selectItemIndex === -1 || selectItemIndex === 0) {
      setSelectItemIndex(focusableLength);
    } else {
      setSelectItemIndex((prev) => prev - 1);
    }
  } else if (key === eventKeyDown) {
    event.preventDefault();
    if (selectItemIndex === focusableLength) {
      setSelectItemIndex(0);
    } else {
      setSelectItemIndex((prev) => prev + 1);
    }
  } else if (key === eventKeyEnter) {
    event.preventDefault();
    if (currentSelectedItem?.nodeName === 'A') {
      navigate(currentSelectedItem?.getAttribute('href') || '');
      setSelectItemIndex(-1);
      onItemSelect();
    } else if (currentSelectedItem?.nodeName === 'BUTTON') {
      currentSelectedItem.click();
      return false;
    }
  } else if (!event.ctrlKey) setSelectItemIndex(-1);

  return true;
};
