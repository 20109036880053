export default {
  Properties: {
    PageDefaultView: 'Weekly',
    UseStopwatchTimeTracking: 'False',
    ShowTaskNumberColumnInTimesheet: 'True',
    FastTrackSearchDefault: '2',
    AutoMarkTaskAsFavourite: 'False',
    AutoShowCommentBoxInTimesheet: 'False',
    TimeTrackingDefaultPeriod: 'ThisWeek',
    TimeTrackingDefaultCategory: '1',
    CollapseWeekends: 'True',
    ExpandMilestonesInTimesheet: 'False',
    ShowMilestonesInTimesheet: 'True',
    SortOrderInTimesheet: '1',
    ShowCustomerColumnInDetailedTimesheet: 'False',
    AutoShowCommentBoxInFastTrack: 'False',
    ShowWeekNumberInTimesheet: 'True',
    DefaultViewInMatrixTimesheet: '1',
  },
  Links: [{ Href: 'http://localhost/tlp/api/v2/page-settings/TimeTrackingBase', Rel: 'self' }],
};
