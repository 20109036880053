import { useQueryClient } from '@tanstack/react-query';
import { MAIN_MENU_KEY } from 'src/apis/menusAPI/mainAPI';
import { PAGE_SETTINGS_KEY } from 'src/apis/pageSettingsAPI';
import { INDEX_KEY } from 'src/apis/indexAPI';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isConfigurationPage } from 'src/utils/url';

export default () => {
  const qc = useQueryClient();
  const { pathname } = useLocation();
  const [beenVisitConfigurationPage, setBeenVisitConfigurationPage] = useState(false);
  useEffect(() => {
    const currentPageIsConfigurationPage = isConfigurationPage(pathname);
    const firstTimeVisitConfigurationPage =
      currentPageIsConfigurationPage && !beenVisitConfigurationPage;
    const backFromConfigurationPage = beenVisitConfigurationPage && !currentPageIsConfigurationPage;

    if (firstTimeVisitConfigurationPage) {
      setBeenVisitConfigurationPage(true);
    } else if (backFromConfigurationPage) {
      setBeenVisitConfigurationPage(false);
      qc.invalidateQueries([MAIN_MENU_KEY, PAGE_SETTINGS_KEY, INDEX_KEY]);
    }
  }, [qc, pathname, beenVisitConfigurationPage]);
  return null;
};
