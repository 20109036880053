import { forwardRef } from 'react';
import { Button as ButtonMui, ButtonProps } from '@mui/material';

type ButtonRef = HTMLButtonElement;

// There is a bug with css being added as required prop when building
// ui lib bundle. Hopefully moving mui to web-app will fix this, so
// we can remove this interface.
export interface ButtonPropsWithoutCss extends Omit<ButtonProps, 'css'> {}

export const Button = forwardRef<ButtonRef, ButtonPropsWithoutCss>(({ ...rest }, ref) => (
  <ButtonMui ref={ref} {...rest} />
));
