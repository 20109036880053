import { DateText } from 'src/components/ui-components/DateText';
import { Flex } from 'src/components/ui-components/Flex';
import { Heading } from 'src/components/ui-components/Heading';
import { Icon } from 'src/components/ui-components/Icon';
import { Link } from 'src/components/ui-components/Link';
import { Stack } from 'src/components/ui-components/Stack';
import { Text } from 'src/components/ui-components/Text';
import { IMilestonesEnitities } from 'src/apis/types/milestonesAPI';
import styles from './FeedItems.module.scss';

interface IMilestoneFeedItemProps {
  items?: IMilestonesEnitities[];
}

export default ({ items }: IMilestoneFeedItemProps) => (
  <Stack verticalMargin="xLarge" data-automation-id="MilestonesResults">
    {items?.map(({ name, projectNameFormatted, projectId, milestoneId, dueDate, isOverdue }) => (
      <Flex
        verticalAlignment="top"
        noGap
        key={milestoneId}
        data-automation-id="MilestonesMilestone"
      >
        <Flex.Item>
          <Icon iconName="milestone" tone="warning" />
        </Flex.Item>
        <div className={styles.itemContent}>
          <Stack verticalMargin="small">
            <Stack verticalMargin="small">
              <Heading
                level={3}
                medium
                data-automation-id={`MilestonesMilestoneName${milestoneId}`}
              >
                <Link
                  href={`/ProjectManagement/Plan/Index/${projectId}`}
                  data-automation-id={`MilestonesMilestoneLink${milestoneId}`}
                >
                  {name}
                </Link>
              </Heading>
              <Text dimmed size="tips" data-automation-id={`MilestonesProjectName${milestoneId}`}>
                <Flex as="span" verticalAlignment="top" gap="xxSmall">
                  <Icon iconName="project" size="small" />
                  {projectNameFormatted}
                </Flex>
              </Text>

              <Text
                dimmed={!isOverdue}
                size="tips"
                tone={isOverdue ? 'error' : undefined}
                data-automation-id={`MilestonesDueDate${milestoneId}`}
              >
                <Flex as="span" verticalAlignment="top" gap="xxSmall">
                  <Icon iconName="calendar" size="small" />

                  <DateText value={new Date(dueDate)} />
                </Flex>
              </Text>
            </Stack>
          </Stack>
        </div>
      </Flex>
    ))}
  </Stack>
);
