import { useRef } from 'react';
import { SidePanel } from 'src/components/layout/SidePanel';
import { FilterStoreProvider } from 'src/stores/FilterStore';
import { IFilterCategoryProperties } from 'src/apis/types/filterListAPI';
import { initFilterStore } from 'src/reducers/FilterReducer';
import { IFilterProps, TFilterID } from 'src/reducers/FilterReducer/FilterReducer';
import MainContainer from './components/MainContainer';
import SideBar from './components/SideBar';

interface IFilterLayout {
  filterList?: Array<Partial<IFilterCategoryProperties>>;
  selectedFilterList?: Record<TFilterID, IFilterProps>;
  children: JSX.Element;
  viewOptions?: JSX.Element;
}

const FilterLayout = ({
  filterList = [],
  selectedFilterList = {},
  children,
  viewOptions,
}: IFilterLayout) => {
  const activateSidePanelButtonRef = useRef<HTMLButtonElement>(null);
  return (
    <FilterStoreProvider
      value={{
        ...initFilterStore,
        filterList,
        selectedFilterList,
        filterQueryObj: selectedFilterList,
      }}
    >
      <SidePanel
        sideBar={<SideBar activateSidePanelButtonRef={activateSidePanelButtonRef} />}
        activateSidePanelButtonRef={activateSidePanelButtonRef}
      >
        <MainContainer
          viewOptions={viewOptions}
          activateSidePanelButtonRef={activateSidePanelButtonRef}
        >
          {children}
        </MainContainer>
      </SidePanel>
    </FilterStoreProvider>
  );
};

export default FilterLayout;
