import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postFetch } from 'src/utils/fetcher';
import { formatUrl } from 'src/utils/url';
import { MIGRATION_STATUS_KEY } from '../get/getMigrationStatusAPI';

const POST_COMPLETE_EMPLOYEE_MIGRATION_URL_PATH =
  '/api/v2/resource-planner/complete-employee-migration';
export const POST_COMPLETE_EMPLOYEE_MIGRATION_MSW_STATUS_KEY = 'PostCompleteEmployeeMigration';

export default () => {
  const path = formatUrl(POST_COMPLETE_EMPLOYEE_MIGRATION_URL_PATH);
  const completeEmployeeMigration = () =>
    postFetch({
      path,
      key: POST_COMPLETE_EMPLOYEE_MIGRATION_MSW_STATUS_KEY,
    });
  const qc = useQueryClient();

  return useMutation(completeEmployeeMigration, {
    onSuccess: () => {
      qc.invalidateQueries([MIGRATION_STATUS_KEY]);
    },
  });
};
