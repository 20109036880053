export default {
  model: {
    properties: {
      workItems: [
        {
          id: '1',
          taskName: 'Prework >> Prototype work',
          customerName: 'TimeLog A/S',
          projectName: '_TIM - Resource Planner',
        },
        {
          id: '2',
          taskName: 'Prework >> Prototype work',
          customerName: 'TimeLog A/S',
          projectName: '_TIM - Resource Planner',
        },
        {
          id: '3',
          taskName: 'Prework >> Prototype work',
          customerName: 'TimeLog A/S',
          projectName: '_TIM - Resource Planner',
        },
        {
          id: '4',
          taskName: 'Prework >> Prototype work',
          customerName: 'TimeLog A/S',
          projectName: '_TIM - Resource Planner',
        },
        {
          id: '5',
          taskName: 'Prework >> Prototype work',
          customerName: 'TimeLog A/S',
          projectName: '_TIM - Resource Planner',
        },
        {
          id: '6',
          taskName: 'Prework >> Prototype work',
          customerName: 'TimeLog A/S',
          projectName: '_TIM - Resource Planner',
        },
      ],
    },
  },
};
