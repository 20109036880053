import indexControllerFullResult from '../indexFullResult';

type TIndexProperties = keyof typeof indexControllerFullResult.Properties;

interface DisableIndexControllerKeyProps {
  propertyKeys?: TIndexProperties[];
  relKeys?: string[];
  actionKeys?: string[];
}

export const disableIndexControllerKey = ({
  propertyKeys = [],
  relKeys = [],
  actionKeys = [],
}: DisableIndexControllerKeyProps) => {
  const filteredActionResult = indexControllerFullResult.Links?.map(
    ({ Actions, ...restProps }) => ({
      ...restProps,
      Actions: Actions?.filter(({ Name }) => !(actionKeys.indexOf(Name) >= 0)),
    }),
  );

  const originalProperties = indexControllerFullResult.Properties;

  propertyKeys.forEach((key) => {
    delete originalProperties[key];
  });

  return {
    Properties: originalProperties,
    Links: [
      ...filteredActionResult.filter(({ Rel }) => {
        if (relKeys?.indexOf(Rel) >= 0) {
          return false;
        }
        return true;
      }),
    ],
  };
};
