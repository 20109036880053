import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { DayPickerBase } from 'react-day-picker';

export const preselectOptionsLogic = (
  currentTarget: EventTarget & HTMLButtonElement,
  rangeFromDate: Date | undefined,
  locale: DayPickerBase['locale'],
  weekStartsOn: DayPickerBase['weekStartsOn'],
) => {
  let dateFrom: Date;
  let period: Date;
  const { periodStartFrom, periodAmount } = currentTarget.dataset;
  const amount = parseInt(periodAmount || '', 10) || 0;
  const localeOptions = {
    locale,
    weekStartsOn,
  };

  dateFrom = rangeFromDate || new Date();

  switch (currentTarget.value) {
    case 'day': {
      period = addDays(dateFrom, amount - 1);
      break;
    }
    case 'week': {
      if (periodStartFrom === 'next') {
        dateFrom = startOfWeek(addWeeks(new Date(), 1), localeOptions);
      } else if (periodStartFrom === 'last') {
        dateFrom = startOfWeek(addWeeks(new Date(), -amount), localeOptions);
      } else if (periodStartFrom === 'this') {
        dateFrom = startOfWeek(new Date(), localeOptions);
      }

      period = addDays(addWeeks(dateFrom, amount), -1);
      break;
    }
    case 'month': {
      if (periodStartFrom === 'next') {
        dateFrom = startOfMonth(addMonths(new Date(), 1));
      } else if (periodStartFrom === 'last') {
        dateFrom = startOfMonth(addMonths(new Date(), -amount));
      } else if (periodStartFrom === 'this') {
        dateFrom = startOfMonth(new Date());
      }

      period = addDays(addMonths(dateFrom, amount), -1);
      break;
    }
    case 'year': {
      if (periodStartFrom === 'next') {
        dateFrom = startOfYear(addYears(new Date(), 1));
      } else if (periodStartFrom === 'last') {
        dateFrom = startOfYear(addYears(new Date(), -amount));
      } else if (periodStartFrom === 'this') {
        dateFrom = startOfYear(new Date());
      }

      period = addDays(addYears(dateFrom, amount), -1);
      break;
    }

    default: {
      period = new Date();
      break;
    }
  }

  return { dateFrom, period };
};
