import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { formatUrl } from 'src/utils/url';
import { parseMenu } from 'src/utils/menu';
import { IMenus, IMenusProperties } from 'src/apis/types/menusAPI';
import { useTranslation } from 'react-i18next';

const SYSTEM_ADMINISTRATION_MENU_URL_PATH = '/api/v2/menus/system-administration';
export const SYSTEM_ADMINISTRATION_MENU_MSW_STATUS_KEY = 'systemAdministrationMenuAPI';

export const SYSTEM_ADMINISTRATION_MENU_KEY = 'SYSTEM_ADMINISTRATION_MENU';

const getSystemAdministrationMenu = (): Promise<IMenus> =>
  getFetch({
    path: SYSTEM_ADMINISTRATION_MENU_URL_PATH,
    key: SYSTEM_ADMINISTRATION_MENU_MSW_STATUS_KEY,
  });

const getMyTimeLogAccountUrl = (systemAdminMenu: IMenusProperties[] = []) => {
  const myTimeLogAccountName = 'my timelog account';
  const { pageUrl = '' } =
    systemAdminMenu?.find(({ name }) => name.toLowerCase() === myTimeLogAccountName)
      ?.subMenus?.[0] || {};
  return formatUrl(pageUrl);
};

export const getMyTimeLogAccountUrlForTest = getMyTimeLogAccountUrl;

const getDefaultSystemUrl = (systemAdminMenu: IMenusProperties[] = []): string => {
  const [firstElement] = systemAdminMenu;
  const { pageUrl = '' } = firstElement || {};
  return formatUrl(pageUrl);
};

export const getDefaultSystemUrlForTest = getDefaultSystemUrl;

export const useSystemAdministrationMenu = () => {
  const { data, ...restProps } = useQuery(
    [SYSTEM_ADMINISTRATION_MENU_KEY],
    getSystemAdministrationMenu,
  );
  const { t } = useTranslation('systemAdministrationNavigation');
  const systemAdminMenu = parseMenu(data, t);

  return {
    systemAdminMenu,
    defaultSystemUrl: getDefaultSystemUrl(systemAdminMenu),
    myTimeLogAccountUrl: getMyTimeLogAccountUrl(systemAdminMenu),
    isSystemAdministrationMenuEmpty: systemAdminMenu.length <= 0,
    ...restProps,
  };
};
