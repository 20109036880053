import { HTMLAttributes, ReactNode } from 'react';
import { Count, CountProps } from '../Count';
import styles from './Badge.module.scss';

interface BadgeProps extends CountProps, HTMLAttributes<HTMLElement> {
  children: ReactNode;
}

export const Badge = ({ children, count = 0, tone }: BadgeProps) => (
  <span className={styles.wrapper}>
    {children}
    {count > 0 && <Count className={styles.count} count={count} tone={tone} />}
  </span>
);
