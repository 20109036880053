type TSidePanelStage = 'LEFT' | 'RIGHT' | 'FLOAT';

export interface SidePanelStoreProps {
  isSidePanelOpen: boolean;
  isSidePanelPinned: boolean;
  sidePanelStage: TSidePanelStage;
}

export type SidePanelActionProps =
  | { type: 'OPEN_PANEL' }
  | { type: 'CLOSE_PANEL' }
  | { type: 'TOGGLE_PANEL' }
  | { type: 'UNPIN_PANEL' }
  | { type: 'PIN_PANEL' }
  | { type: 'TOGGLE_PIN' }
  | { type: 'SWITCH_POSITION' }
  | { type: 'POSITION_LEFT' }
  | { type: 'POSITION_RIGHT' }
  | { type: 'INIT_VALUE'; payload: SidePanelStoreProps };

const sidePanelReducer = (
  state: SidePanelStoreProps,
  action: SidePanelActionProps,
): SidePanelStoreProps => {
  switch (action.type) {
    case 'INIT_VALUE':
      return action.payload;
    case 'OPEN_PANEL':
      return {
        ...state,
        isSidePanelOpen: true,
      };
    case 'CLOSE_PANEL':
      return {
        ...state,
        isSidePanelOpen: false,
      };
    case 'TOGGLE_PANEL':
      return {
        ...state,
        isSidePanelOpen: !state.isSidePanelOpen,
      };
    case 'SWITCH_POSITION':
      return {
        ...state,
        sidePanelStage: state.sidePanelStage === 'LEFT' ? 'RIGHT' : 'LEFT',
      };
    case 'POSITION_LEFT':
      return {
        ...state,
        sidePanelStage: 'LEFT',
      };
    case 'POSITION_RIGHT':
      return {
        ...state,
        sidePanelStage: 'RIGHT',
      };
    case 'UNPIN_PANEL':
      return {
        ...state,
        isSidePanelPinned: false,
      };
    case 'PIN_PANEL':
      return {
        ...state,
        isSidePanelPinned: true,
      };
    case 'TOGGLE_PIN':
      return {
        ...state,
        isSidePanelPinned: !state.isSidePanelPinned,
      };
    default:
      return state;
  }
};

export const initSidePanelStore: SidePanelStoreProps = {
  isSidePanelPinned: true,
  isSidePanelOpen: false,
  sidePanelStage: 'RIGHT',
};
export default sidePanelReducer;
