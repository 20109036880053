import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { BOOKMARKS_KEY } from 'src/apis/bookmarksAPI';

export const useIframeEvent = () => {
  const qc = useQueryClient();
  useEffect(() => {
    const handleIframeEvent = (event: MessageEvent) => {
      const {
        data: { eventId },
      } = event;
      if (eventId === 'updateBookmark') {
        qc.invalidateQueries([BOOKMARKS_KEY]);
      }
    };

    window?.addEventListener('message', handleIframeEvent as EventListener);

    return () => {
      window?.removeEventListener('message', handleIframeEvent as EventListener);
    };
  }, [qc]);
};
