export default {
  Properties: {
    UserId: 1234,
    ID: '01825bbc-65eb-451e-8849-81caa340fad0',
    FirstName: 'FirstName_Employee1',
    LastName: 'LastName',
    FullName: 'FirstName_Employee1 LastName',
    Initials: 'Employee1',
    IsActive: true,
    UserType: 'Employee',
    Email: 'bugfix@timelog.dk',
    ProfilePicture: 'http://localhost:3000/icon-192.png',
  },
  Links: [
    {
      Href: 'https://app8.timelog.com/localarena/api/v2/users/me',
      Rel: 'self',
    },
  ],
};
