import { useTranslation } from 'react-i18next';
import { useUpdateResetExchangeRate } from 'src/apis/exchangeRateAPI';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'src/components/mui-components';
import { handleError } from '../../utils/serverResponseHandler';

interface ResetExchangeRateModalProps {
  periodId: number;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResetExchangeRateModal: React.FC<ResetExchangeRateModalProps> = ({
  periodId,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation('systemAdminExchangeRate');
  const { mutateAsync: updateReset, isLoading: isResetLoading } = useUpdateResetExchangeRate();

  const updateResetExchangeRateAction = async () => {
    try {
      const response = await updateReset(periodId, {
        onError: (error: any) => {
          handleError(error, t);
        },
      });
      if (response) {
        return response;
      }
    } catch (error) {
      // no actions required, error handled
    } finally {
      setIsOpen(false);
    }
    return null;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="dialog-reset-exchange-rate"
      aria-describedby="dialog-reset-exchange-rate-desc"
    >
      <DialogTitle id="dialog-reset-exchange-rate">
        {t('modals.ResetExchangeRatesTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-reset-exchange-rate-desc">
          {t('modals.ResetExchangeRatesDescription')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => updateResetExchangeRateAction()}
          autoFocus
          data-automation-id="ResetExchangeRateModalSaveButton"
          disabled={isResetLoading}
        >
          Save
        </Button>
        <Button
          onClick={() => setIsOpen(false)}
          data-automation-id="ResetExchangeRateModalCancelButton"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetExchangeRateModal;
