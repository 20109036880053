import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { parseMenu } from 'src/utils/menu';
import { useTranslation } from 'react-i18next';
import { IMenus, IMenusPropertiesWithPagePath } from 'src/apis/types/menusAPI';

const CREATE_MENU_URL_PATH = '/api/v2/menus/create';
export const CREATE_MENU_MSW_STATUS_KEY = 'createMenuAPI';

export const CREATE_MENU_KEY = 'CREATE_MENU';

const getCreateMenu = (): Promise<IMenus> =>
  getFetch({ path: CREATE_MENU_URL_PATH, key: CREATE_MENU_MSW_STATUS_KEY });

export const useCreateMenu = () => {
  const { data, ...restProps } = useQuery([CREATE_MENU_KEY], getCreateMenu);
  const { t } = useTranslation('quickCreateNavigation');
  const quickCreateMenu: IMenusPropertiesWithPagePath[] = parseMenu(data, t);
  return {
    quickCreateMenu,
    isQuickCreateMenuEmpty: quickCreateMenu.length <= 0,
    ...restProps,
  };
};
