import { IconButton } from 'src/components/ui-components/IconButton';
import {
  ToastContainer as ToastifyToastContainer,
  cssTransition,
  CloseButtonProps,
  TypeOptions,
} from 'react-toastify';
import { userHasAskedToReduceMotion } from 'src/utils/accessibility';

import styles from './ToastContainer.module.scss';

const disableAnimation = cssTransition({
  enter: styles.DisableAnimation,
  exit: styles.DisableAnimation,
  collapse: false,
});

const bounce = cssTransition({
  enter: styles.ToastItemAnimateIn,
  exit: styles.ToastItemAnimateOut,
});

// Exclude 'info' as we do not have info status
type ExcludeInfo<T> = T extends 'info' ? never : T;
type TypeOmitInfo = ExcludeInfo<TypeOptions>;

const ToastCloseButton = ({
  closeToast,
  type,
}: Partial<CloseButtonProps> & { type?: TypeOmitInfo }) => (
  <IconButton
    border
    round
    size="tiny"
    iconName="cross"
    onClick={closeToast}
    tooltipText="Close"
    className={styles.CloseButton}
    tone={type}
  />
);

export const ToastContainer = () => (
  <ToastifyToastContainer
    position="bottom-right"
    autoClose={false}
    hideProgressBar
    newestOnTop
    closeOnClick={false}
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    closeButton={<ToastCloseButton />}
    icon={false}
    className={styles.ToastContainer}
    toastClassName={styles.ToastItem}
    progressClassName={styles.ProgressBar}
    transition={userHasAskedToReduceMotion ? disableAnimation : bounce}
  />
);
