export default {
  Properties: {
    CompanyName: 'Your company',
    NumberCultureID: 1030,
    TimeFormat: 1,
    TimeRoundingFormat: 0,
    BillableHourIsEditable: false,
    BillableHourIsEditableOnFixedPrice: false,
    ShowBillableCheckbox: true,
    StopwatchTimeTracking: true,
    SystemCurrencyID: 9,
    SystemCurrencyRate: 100.0,
    SystemCurrencyABB: 'EUR',
    IsAbsenceActive: true,
    RoundingOfBillableTime: false,
    Privileges: { RegistrationAllTasks: true, ExpenseTravelRegistrationAllProjects: false },
    SiteSettings: {
      TreatAllTimeRegistrationAsRecurringTask: false,
      TaskNumber: false,
      ShowOdometer: false,
      OdometerIsRequired: false,
      CanUseTravelTimeTask: true,
      FileAttachmentInExpense: true,
      ShowUserDefinedComment: false,
      UserDefinedCommentName: 'AdditionalTextFieldLabel',
      UserDefinedCommentDataType: 'Text',
      UseTimeRegistrationFinancialDataService: false,
    },
  },
  Links: [{ Href: 'http://localhost/tlp/api/v1/user-setting', Rel: 'self' }],
};
