export default {
  properties: {
    language: {
      lcid: 2057,
      shortString: 'en',
    },
    showSignUpButton: true,
  },
  links: [
    {
      href: 'http://localhost/tlp/api/v2/user-settings',
      rel: 'self',
    },
  ],
};
