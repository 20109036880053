import { TDateFormats } from '../types';

const regexDateFirst = /^(0[1-9]|[12][0-9]|3[01])(-|\.|\/)(0[1-9]|1[0-2])(-|\.|\/)\d{4}$/;
const regexMonthFirst = /^(0[1-9]|1[0-2])(-|\.|\/)(0[1-9]|[12][0-9]|3[01])(-|\.|\/)\d{4}$/;
const regexYearFirst = /^\d{4}(-|\.|\/)(0[1-9]|1[0-2])(-|\.|\/)(0[1-9]|[12][0-9]|3[01])$/;

export const isCorrectDateFormat = (value: string, dateFormat: TDateFormats): boolean => {
  const dateFormatHasDayFirst = dateFormat.startsWith('d');
  const dateFormatHasMonthFirst = dateFormat.startsWith('M');
  const dateFormatHasYearFirst = dateFormat.startsWith('y');
  let isCorrectFormat = false;

  if (dateFormatHasDayFirst) {
    isCorrectFormat = regexDateFirst.test(value);
  } else if (dateFormatHasMonthFirst) {
    isCorrectFormat = regexMonthFirst.test(value);
  } else if (dateFormatHasYearFirst) {
    isCorrectFormat = regexYearFirst.test(value);
  } else {
    return false;
  }

  return isCorrectFormat;
};
