import { IResourcePlannerItem } from '../../../apis/types/resourcePlannerAPI';
import {
  RPEmployeeViewInitialExpandedRowsStateKey,
  RPProjectViewInitialExpandedRowsStateKey,
  RPViewOptionsStateKey,
} from '../localStorageKeys';

export interface ExpandedTableRowIds {
  [index: string]: boolean;
}

export type TCollapseExpandState = {
  tableRowId: string;
  originalId: string;
  isExpanded: boolean;
};

export const getExpandedRowIds = (storageKey: string) => {
  let rows: TCollapseExpandState[];

  try {
    rows = JSON.parse(localStorage.getItem(storageKey) || '[]');
  } catch (e) {
    rows = [];
  }

  if (!Array.isArray(rows)) {
    rows = [];
  }

  return [...rows];
};

export const setExpandedRowIds = (
  storageKey: string,
  rows: TCollapseExpandState[],
): TCollapseExpandState[] => {
  try {
    localStorage.setItem(storageKey, JSON.stringify(rows));

    return rows;
  } catch (e) {
    return [];
  }
};

export const getExpandedTableRowIds = (storageKey: string): ExpandedTableRowIds => {
  const rows = getExpandedRowIds(storageKey);
  const result: ExpandedTableRowIds = {};

  rows.forEach((row: TCollapseExpandState) => {
    if (row && row.tableRowId) result[row.tableRowId] = row.isExpanded;
  });

  return result;
};

export const getExpandedRowOriginalIds = (storageKey: string) => {
  try {
    return getExpandedRowIds(storageKey)
      .filter((row: TCollapseExpandState) => row.isExpanded)
      .map((row) => row.originalId)
      .join(',');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    return undefined;
  }
};

export const findRowById = (
  rows: IResourcePlannerItem[] | undefined,
  id: string,
): IResourcePlannerItem | undefined => {
  let result: IResourcePlannerItem | undefined;

  rows?.some((r) => {
    if (r.id === id) {
      result = r;
      return true;
    }

    if (Array.isArray(r.children)) {
      result = findRowById(r.children, id);
      return result;
    }

    return result;
  });

  return result;
};

export const getStorageKey = (): string | undefined => {
  try {
    const currentView = localStorage.getItem(RPViewOptionsStateKey);
    const currentViewParsed = currentView ? JSON.parse(currentView) : {};

    return currentViewParsed['grouped-by'] === 'group-by-work-item'
      ? RPProjectViewInitialExpandedRowsStateKey
      : RPEmployeeViewInitialExpandedRowsStateKey;
  } catch (e) {
    return undefined;
  }
};
