import { useQuery } from '@tanstack/react-query';
import { ISearchResourceByNameResult } from 'src/apis/types/resourcePlannerAPI';
import { getFetch } from 'src/utils/fetcher';
import { concatQuerys } from 'src/utils/url';

const SEARCH_RESOURCE_BY_NAME_URL_PATH = '/api/v2/resource-planner/search-resource-by-name';
export const SEARCH_RESOURCE_BY_NAME_MSW_STATUS_KEY = 'GetSearchResourceByNameAPI';

export const SEARCH_RESOURCE_BY_NAME_KEY = 'SEARCH_RESOURCE_BY_NAME';

const searchResourceByNameFetch = (
  taskId: string,
  searchText: string,
): Promise<ISearchResourceByNameResult> =>
  getFetch({
    path: `${SEARCH_RESOURCE_BY_NAME_URL_PATH}${concatQuerys([
      {
        param: 'workItemId',
        value: taskId,
      },
      {
        param: 'searchText',
        value: searchText,
      },
    ])}`,
    key: SEARCH_RESOURCE_BY_NAME_MSW_STATUS_KEY,
  });

export const useGetSearchResourceByNameAPI = (taskId: string, searchText: string) => {
  const {
    data: {
      model: { properties: { resources = [], competences = [], departments = [] } = {} } = {},
    } = {},
    ...restProps
  } = useQuery(
    [SEARCH_RESOURCE_BY_NAME_KEY, taskId, searchText],
    () => searchResourceByNameFetch(taskId, searchText),
    { enabled: searchText !== '' },
  );
  return {
    resources,
    competences,
    departments,
    ...restProps,
  };
};
