import { HTMLAttributes, ReactNode, ElementType } from 'react';

import styles from './VisuallyHidden.module.scss';

type VisuallyHiddenProps<TElement extends keyof JSX.IntrinsicElements> =
  JSX.IntrinsicElements[TElement] & {
    /** Content */
    children: ReactNode;
    /** Tag (default is `<span>`) */
    as?: TElement | ElementType; // ElementType is needed here so we can set 'tag' as the default value
  };

export function VisuallyHidden<TElement extends keyof JSX.IntrinsicElements>({
  as: As = 'span',
  ...props
}: VisuallyHiddenProps<TElement> & HTMLAttributes<HTMLElement>): JSX.Element {
  // We explicitly have to cast `As` here otherwise typescript
  // won't accept it as being a constructor on the next line
  const Tag = As as ElementType;

  return <Tag className={styles.visuallyHidden} {...props} />;
}
