import { useFilterDispatch, useMatchSelectedFilterKey } from 'src/stores/FilterStore';
import { IChildInputComponent, IOnChangeAction, IOnChangeInput } from '../../InputContainerTypes';

export const useOnChangeHandler = ({
  contentUrl,
  fieldLabel,
  filterType,
  childFilters = [],
  parentFilters = [],
  activateInputContainerRef,
  isInclude,
}: Omit<
  IChildInputComponent<
    'SingleSelect' | 'SingleSelectTypeSearch' | 'MultiSelectTypeSearch' | 'MultiSelect'
  >,
  'filterId'
>) => {
  const dispatch = useFilterDispatch();
  const matchChild = useMatchSelectedFilterKey(childFilters);

  return (event: Array<IOnChangeInput> | IOnChangeInput, { action }: IOnChangeAction) => {
    if (dispatch) {
      if (action === 'select-option' || action === 'remove-value' || action === 'pop-value') {
        dispatch({
          type: 'ADD_OR_UPDATE_FILTER',
          payload: {
            [fieldLabel]: {
              label: fieldLabel,
              values: Array.isArray(event) ? event : [event],
              filterType,
              contentUrl,
              childFilters,
              parentFilters,
              isInclude,
            },
          },
        });

        matchChild?.forEach((filterID) => {
          dispatch({ type: 'RESET_FILTER_ID', payload: { filterID } });
        });

        if (filterType.startsWith('Single')) {
          dispatch({ type: 'DEACTIVATE_CONTAINER_ID' });
          dispatch({ type: 'DEACTIVATE_PANEL_ID' });
        }

        if (activateInputContainerRef?.current && filterType.startsWith('Single')) {
          activateInputContainerRef.current.focus();
        }
      } else if (action === 'clear') {
        dispatch({ type: 'REMOVE_FILTER', payload: { filterID: fieldLabel } });
      }
    }
  };
};

export default useOnChangeHandler;
