import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getPathAndEntityId } from 'src/utils/url';
import { postFetch } from 'src/utils/fetcher';
import { BOOKMARKS_KEY } from '../get';

const BOOKMARKS_URL_PATH = '/api/v2/bookmarks';

export const POST_BOOKMARKS_MSW_STATUS_KEY = 'postBookmark';

export const POST_BOOKMARKS_KEY = 'POST_BOOKMARKS';

interface IPostBookmarkProps {
  pageId: string;
  url: string;
}

export default ({ pageId, url }: IPostBookmarkProps) => {
  const postBookmark = () => {
    const { entityId } = getPathAndEntityId(url);
    return postFetch({
      path: BOOKMARKS_URL_PATH,
      key: POST_BOOKMARKS_MSW_STATUS_KEY,
      body: JSON.stringify({
        PageId: pageId,
        Url: url,
        EntityId: entityId,
      }),
    });
  };
  const qc = useQueryClient();

  return useMutation(postBookmark, {
    onSuccess: () => {
      qc.invalidateQueries([BOOKMARKS_KEY]);
    },
  });
};
