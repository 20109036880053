export const convertShortName = {
  da: 'danish',
  de: 'deutsch',
  en: 'english',
  fi: 'finnish',
  nl: 'dutch',
  nb: 'norwegian',
  ru: 'russian',
  sv: 'swedish',
  fr: 'french',
};
