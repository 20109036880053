import { formatTime } from 'src/utils/time';

export const totalRowValueRenderer = (
  value: number | string | undefined,
  unitType: string,
  locale: string,
  isRenderUnitType: boolean = true,
) =>
  `${formatTime(value || '0', locale)}${
    isRenderUnitType && unitType && unitType === 'percentages' ? ' %' : ''
  }`;
