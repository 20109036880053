import { Text } from 'src/components/ui-components/Text';
import { Spinner } from 'src/components/ui-components/Spinner';

interface IResponseHandler {
  children: JSX.Element | null;
  isError?: boolean;
  isLoading?: boolean;
  isEmpty?: boolean;
  ErrorComponent?: JSX.Element;
  LoadingComponent?: JSX.Element;
  EmptyComponent?: JSX.Element;
}

const ResponseHandler = ({
  children,
  isEmpty,
  isError,
  isLoading,
  ErrorComponent,
  LoadingComponent,
  EmptyComponent,
}: IResponseHandler) => {
  if (isLoading) {
    return LoadingComponent || <Spinner data-automation-id="DefaultSuspenseLoading" />;
  }
  if (isError) {
    return ErrorComponent || <Text data-automation-id="DefaultSuspenseError">Network Error</Text>;
  }
  if (isEmpty) {
    return EmptyComponent || <Text data-automation-id="DefaultSuspenseEmpty">Data is empty</Text>;
  }
  return children;
};

export default ResponseHandler;
