import { createContext, useContext, useReducer } from 'react';
import { TFilterType } from 'src/apis/types/filterListAPI';
import FilterReducer, {
  initFilterStore,
  IFilterStoreProps,
  TFilterActionProps,
} from 'src/reducers/FilterReducer';

interface IFilterPanelStoreProps {
  children: JSX.Element | JSX.Element[];
  value?: IFilterStoreProps;
}
const FilterStoreContext = createContext<IFilterStoreProps>(initFilterStore);
const FilterDispatchContext = createContext<React.Dispatch<TFilterActionProps> | undefined>(
  undefined,
);
export const useFilterDispatch = () => useContext(FilterDispatchContext);
export const useFilterStore = () => useContext(FilterStoreContext);
export const useIsRequiredQuery = () => {
  const { isRequiredQuery } = useContext(FilterStoreContext);
  return isRequiredQuery;
};
export const useGetFilterValuesById = (fieldLabel: string, filterType: TFilterType) => {
  const { selectedFilterList } = useContext(FilterStoreContext);

  switch (filterType) {
    case 'SingleSelect':
    case 'SingleSelectTypeSearch':
      return selectedFilterList?.[fieldLabel]?.values?.[0];
    case 'MultiSelect':
    case 'MultiSelectTypeSearch':
      return selectedFilterList?.[fieldLabel]?.values;
    default:
      return selectedFilterList?.[fieldLabel]?.values?.[0]?.value;
  }
};
export const useFullFilterList = () => useContext(FilterStoreContext).filterList;
export const useMatchSelectedFilterKey = (childFilterList: Array<string>) => {
  const { selectedFilterList } = useContext(FilterStoreContext);
  return Object.keys(selectedFilterList).filter((key) => childFilterList.includes(key));
};
export const useGetParentQueryString = (parentFilterList: Array<string>) => {
  const { selectedFilterList } = useContext(FilterStoreContext);
  const queryValue = parentFilterList
    .map((key) => {
      if (selectedFilterList[key]?.values?.length > 0) {
        return `${key}Ids=${selectedFilterList[key]?.values
          ?.map(({ value }) => value)
          .join(',')}&is${key}Inclusive=${selectedFilterList[key]?.isInclude ? 'true' : 'false'}`;
      }
      return undefined;
    })
    .filter((value) => !!value)
    .join('&');
  return queryValue ? `&${queryValue}` : '';
};
export const useFakeSelectedFilterResult = (noRequired = 3) => {
  const { selectedFilterList } = useContext(FilterStoreContext);
  const selectedItemCount = Object.keys(selectedFilterList);

  return {
    isLoading: selectedItemCount.length <= noRequired && selectedItemCount.length > 0,
    isError: false,
    isEmpty: selectedItemCount.length <= 0,
  };
};
export const useSelectedFilterListInArray = () => {
  const { selectedFilterList } = useContext(FilterStoreContext);
  return Object.keys(selectedFilterList).map((key) => ({
    id: key,
    items: selectedFilterList[key].values.map(({ label }) => ({ name: label || '' })),
    ...selectedFilterList[key],
  }));
};
export const useActivePanelID = () => {
  const { activePanelID } = useContext(FilterStoreContext);
  return activePanelID;
};
export const useActiveContainerID = () => {
  const { activeContainerID } = useContext(FilterStoreContext);
  return activeContainerID;
};

export default ({ children, value = initFilterStore }: IFilterPanelStoreProps) => {
  const [filterPanelState, filterPanelDispatch] = useReducer(FilterReducer, value);

  return (
    <FilterStoreContext.Provider value={filterPanelState}>
      <FilterDispatchContext.Provider value={filterPanelDispatch}>
        {children}
      </FilterDispatchContext.Provider>
    </FilterStoreContext.Provider>
  );
};
