import { useMutation, useQueryClient } from '@tanstack/react-query';
import { formatUrl } from 'src/utils/url';
import { deleteFetch } from 'src/utils/fetcher';
import { GROUP_BY_RESOURCE_KEY } from '../get/getGroupByResourceAPI';
import { GROUP_BY_PROJECT_KEY } from '../get/getGroupByProjectAPI';

const DELETE_WORK_ITEM_RELATIONSHIP_URL_PATH = '/api/v2/resource-planner/work-item-relationship';
export const DELETE_WORK_ITEM_RELATIONSHIP_MSW_STATUS_KEY = 'deleteRelationship';
export const DELETE_WORK_ITEM_RELATIONSHIP_KEY = 'DELETE_RELATIONSHIP';

export default () => {
  const deleteWorkItemRelationship = ({
    workItemId,
    workItemSourceReferenceId,
    workItemType,
    resourceId,
    resourceSourceReferenceId,
    resourceType,
  }: {
    workItemId: string | undefined;
    workItemSourceReferenceId: string | undefined;
    workItemType: string | undefined;
    resourceId: string | undefined;
    resourceSourceReferenceId: string | undefined;
    resourceType: string | undefined;
  }) =>
    deleteFetch({
      path: formatUrl(DELETE_WORK_ITEM_RELATIONSHIP_URL_PATH),
      key: DELETE_WORK_ITEM_RELATIONSHIP_MSW_STATUS_KEY,
      body: JSON.stringify({
        workItemId,
        workItemSourceReferenceId,
        workItemType,
        resourceId,
        resourceSourceReferenceId,
        resourceType,
      }),
    });
  const qc = useQueryClient();

  return useMutation(deleteWorkItemRelationship, {
    onSuccess: () => {
      qc.invalidateQueries([GROUP_BY_RESOURCE_KEY]);
      qc.invalidateQueries([GROUP_BY_PROJECT_KEY]);
    },
  });
};
