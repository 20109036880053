import { useGetFirstName } from 'src/apis/usersAPI/meAPI';
import { Text, Shimmer, Heading, Flex } from 'src/components/ui-components';
import { useGreeting } from './helpers/useGreeting';
import styles from './NameArea.module.scss';

export default () => {
  const { firstName, isLoading } = useGetFirstName();

  return (
    <Heading level={1}>
      <Flex as="span" wrap>
        {useGreeting(isLoading)}
        {isLoading ? (
          <Shimmer minHeight="display" isInlineBlock className={styles.nameShimmer} />
        ) : (
          <Text bold size="display" as="span" data-automation-id="DashboardFirstName" ellipsis>
            {firstName}
          </Text>
        )}
      </Flex>
    </Heading>
  );
};
