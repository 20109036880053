import {
  useGetBillablePercentageForHomeWidget,
  GET_BILLABLE_PERCENTAGE_KEY,
} from 'src/apis/billablePercentageAPI';
import WidgetWithoutChart from '../WidgetWithoutChart';
import WidgetTipsButton from '../WidgetTipsButton';

const BillablePercentageWidget = () => {
  const {
    isError: isBillablePercentageError,
    isLoading: isBillablePercentageLoading,
    informationList: billablePercentageInformationList,
  } = useGetBillablePercentageForHomeWidget();

  return (
    <WidgetWithoutChart
      queryKey={GET_BILLABLE_PERCENTAGE_KEY}
      relId="BillablePercentage"
      tips={<WidgetTipsButton widgetKey="BillablePercentage" />}
      informationList={billablePercentageInformationList}
      isError={isBillablePercentageError}
      isLoading={isBillablePercentageLoading}
    />
  );
};

export default BillablePercentageWidget;
