// Validation for number
export const validateNumberInput = (input: string, decimalErrorMessage: string) => {
  const isWholeNumber = /^[0-9][0-9]*$/.test(input);
  const isDecimalNumber = /^[0-9][0-9]*[,,.][0-9]?[0-9]?[0-9]?[0-9]$/.test(input);
  const isShortDecimal = /^[,,.][0-9]?[0-9]$/.test(input);
  const inputFormatted = input.toString();

  if (inputFormatted === '') {
    return { status: 'success', value: '' };
  }

  if (isWholeNumber) {
    return { status: 'success', value: inputFormatted };
  }

  if (isDecimalNumber) {
    return { status: 'success', value: inputFormatted.replaceAll(',', '.') };
  }

  if (isShortDecimal) {
    return {
      status: 'success',
      value: 0 + inputFormatted.replaceAll(',', '.'),
    };
  }

  return { status: 'error', value: decimalErrorMessage };
};

// Format number based on locale
export const formatNumber = (
  time: string | number,
  locale: string,
  maximumFractionDigits: number = 4,
) => {
  const parsedTime = typeof time === 'string' ? parseFloat(time) : time;
  const decimalFormat = parsedTime.toLocaleString(locale, {
    minimumFractionDigits: 4,
    maximumFractionDigits,
  });

  return decimalFormat;
};

// Convert number in locale to standard number with dot
export const convertNumber = (num: string, locale: string): number => {
  const { format } = new Intl.NumberFormat(locale);
  const decimalSign = /^0(.)1$/.exec(format(0.1))?.[1] || '0';
  return +num.replace(new RegExp(`[^${decimalSign}\\d]`, 'g'), '').replace(decimalSign, '.');
};
