import { FC } from 'react';

import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  ReferenceLine,
  Cell,
  LabelList,
} from 'recharts';
import { StackOffsetType } from 'recharts/types/util/types';
import { ContentType } from 'recharts/types/component/Label';
import { chartColors } from './config';
import styles from './Chart.module.scss';

export interface BarChartProps {
  /** Data to show bar chart { name: 'Page A', [dataKey]: 400 } */
  data: any[];
  /** Data props */
  barProps: {
    dataKey: string;
    stackId?: string;
    yAxisId?: string;
    background?: object;
    minPointSize?: number;
    labelList?: boolean;
  }[];
  /** To set the xAxis key name */
  xAxisKey?: string;
  /** Configuration for xAxis (https://recharts.org/en-US/api/XAxis) */
  xAxisConfigs?: object[];
  /** To set the yAxis key name */
  yAxisKey?: string;
  /** Configuration for yAxis (https://recharts.org/en-US/api/YAxis) */
  yAxisConfigs?: object[];
  /** Background Grid line */
  cartesianGrid?: boolean;
  /** Hover tooltip */
  tooltip?: boolean;
  /** Configuration for custom tooltip */
  tooltipConfigs?: object;
  /** To show legend */
  legend?: boolean;
  /** Configuration for Legend (https://recharts.org/en-US/api/Legend) */
  legendConfigs?: object;
  /** To show brush bar */
  brush?: boolean;
  /** Event handling */
  customizedEvent?: (item: object, i: number) => void;
  /** Event handling only */
  activeIndex?: number;
  /** Customize label content */
  labelListContent?: ContentType;
  /** Set how the bar is show */
  stackOffset?: StackOffsetType;
  /** Show line in graph (https://recharts.org/en-US/api/ReferenceLine) */
  referenceLine?: object;
  /** Bar size */
  barSize?: number;
  /** Edit margin of Chart Surface */
  surfaceMargin?: object;
}

export const BarChartComponent: FC<BarChartProps> = ({
  data,
  xAxisKey,
  xAxisConfigs = [],
  yAxisKey,
  yAxisConfigs = [],
  barProps,
  cartesianGrid = false,
  tooltip = false,
  tooltipConfigs = {},
  legend = false,
  legendConfigs = {},
  brush = false,
  customizedEvent,
  activeIndex,
  labelListContent,
  stackOffset,
  referenceLine,
  barSize,
  surfaceMargin,
}) => {
  const bars = barProps.map((props: any, index: number) => {
    let color = '';
    if (barProps.length >= 2) {
      color = chartColors[index][index % chartColors.length];
    } else {
      color = chartColors[0][index % chartColors.length];
    }
    if (customizedEvent) {
      return (
        <Bar
          key={props.dataKey}
          dataKey={props.dataKey || undefined}
          stackId={props.stackId || undefined}
          fill={color}
          onClick={customizedEvent}
          minPointSize={props.minPointSize || undefined}
          background={props.background}
        >
          {data.map((entry, i) => (
            <Cell
              cursor="pointer"
              fill={i === activeIndex ? styles.activeCell : styles.inactiveCell}
              key={`cell-${entry.name}`}
            />
          ))}
          {labelListContent && props.labelList && (
            <LabelList dataKey="name" content={labelListContent} />
          )}
        </Bar>
      );
    }
    return (
      <Bar
        key={props.dataKey}
        dataKey={props.dataKey || undefined}
        stackId={props.stackId || undefined}
        yAxisId={props.yAxisId || undefined}
        fill={color}
        minPointSize={props.minPointSize || undefined}
        background={props.background}
      >
        {labelListContent && props.labelList && (
          <LabelList dataKey="name" content={labelListContent} />
        )}
      </Bar>
    );
  });

  return (
    <ResponsiveContainer>
      <BarChart
        data={data}
        stackOffset={stackOffset}
        barSize={barSize || undefined}
        margin={surfaceMargin}
      >
        {xAxisKey !== undefined &&
          (xAxisConfigs.length > 0 ? (
            xAxisConfigs.map((props: any, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <XAxis {...props} key={`${xAxisKey}-${index}`} />
            ))
          ) : (
            <XAxis dataKey={xAxisKey || undefined} />
          ))}
        {yAxisKey !== undefined &&
          (yAxisConfigs.length > 0 ? (
            yAxisConfigs.map((props: any, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <YAxis {...props} key={`${yAxisKey}-${index}`} dataKey={yAxisKey || undefined} />
            ))
          ) : (
            <YAxis dataKey={yAxisKey || undefined} />
          ))}
        {cartesianGrid && <CartesianGrid strokeDasharray="3 3" />}
        {tooltip && <Tooltip {...tooltipConfigs} />}
        {legend && <Legend {...legendConfigs} />}
        {referenceLine && <ReferenceLine {...referenceLine} />}
        {brush && <Brush dataKey="name" height={30} stroke={styles.brushStroke} />}
        {bars}
      </BarChart>
    </ResponsiveContainer>
  );
};
