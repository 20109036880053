import { toCamel } from 'src/utils/object';

export const searchSomeResult = {
  Properties: {
    Customers: [
      {
        Id: 123,
        Name: 'CustomerName (1123)',
        ImageUrl: 'https://i.pravatar.cc/40',
        PageUrl: 'http://localhost/tlp/CRM/Customer/Edit/671?order=0',
      },
      {
        Id: 14,
        Name: 'CustomerName (1456)',
        ImageUrl: 'https://i.pravatar.cc/41',
        PageUrl: 'http://localhost/tlp/CRM/Customer/Edit/671?order=0',
      },
    ],
    Projects: [
      {
        Id: 123,
        Name: 'ProjectName (1123)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/ProjectManagement/Plan/Task/1',
      },
      {
        Id: 14,
        Name: 'ProjectName (1456)',
        ImageUrl: null,
        PageUrl: 'http://localhost/tlp/ProjectManagement/Plan/Task/1',
      },
    ],
  },
  Links: [
    {
      Href: 'https://app8.timelog.com/localarena/api/v2/search?searchtext=123',
      Rel: 'self',
    },
  ],
};

export const searchSomeResultCameCase = toCamel(searchSomeResult);

export default searchSomeResult;
