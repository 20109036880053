import { FC } from 'react';
import classNames from 'classnames';

import styles from './Spinner.module.scss';

export interface SpinnerProps {
  small?: boolean;
  message?: string;
}

export const Spinner: FC<SpinnerProps> = ({ small = true, message = 'Loading', ...props }) => (
  <div className={classNames(styles.wrapper, { [styles.small]: small })} {...props}>
    <div className={styles.item} aria-hidden="true" />
    <div className={`${styles.item} ${styles.second}`} aria-hidden="true" />
    <p className={styles.message} aria-live="polite" aria-busy="true">
      {message}
    </p>
  </div>
);
