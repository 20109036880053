import { HTMLAttributes, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { ButtonSecondary } from 'src/components/ui-components/Button';
import { Flex } from 'src/components/ui-components/Flex';
import { Text } from 'src/components/ui-components/Text';
import { RichTextStatic } from 'src/components/ui-components/RichText';
import styles from './StaticText.module.scss';

interface StaticTextProps extends HTMLAttributes<HTMLDivElement> {
  isEmpty: boolean;
  standardText: string;
  internalMessage: string;
}

export const StaticText = ({
  isEmpty,
  standardText,
  internalMessage,
  className,
  ...props
}: StaticTextProps) => {
  const [showAll, setShowAll] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);
  const richTextRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('frontpage');

  useEffect(() => {
    if (richTextRef.current) {
      if (richTextRef.current?.scrollHeight > parseInt(styles.richTextMaxHeight, 10)) {
        setHasOverflow(true);
      } else {
        setHasOverflow(false);
      }
    }
  }, [internalMessage, richTextRef, hasOverflow]);

  return isEmpty ? (
    <Text data-automation-id="InternalMessageEmptyText">{standardText}</Text>
  ) : (
    <>
      <div
        ref={richTextRef}
        id="InternalMessageTextStatic"
        className={cx(styles.staticText, { [styles.showAll]: showAll }, className)}
      >
        <RichTextStatic
          html={internalMessage}
          data-automation-id="InternalMessageTextStatic"
          {...props}
        />
      </div>
      {hasOverflow && (
        <Flex horizontalAlignment="right">
          <ButtonSecondary
            data-automation-id="InternalMessageExtendButton"
            size="compact"
            onClick={() => setShowAll((prev) => !prev)}
            aria-controls="InternalMessageTextStatic"
            aria-expanded={showAll}
          >
            {showAll
              ? t('InternalMessageWidgetCollapseContentButtonText')
              : t('InternalMessageWidgetExpandContentButtonText')}
          </ButtonSecondary>
        </Flex>
      )}
    </>
  );
};
