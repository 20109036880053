import { useQuery } from '@tanstack/react-query';
import {
  IPagesDesignSevenAPI,
  IPageActionLink,
  IPageActionModal,
} from 'src/apis/types/pageDesignSevenAPI';
import { useTranslation } from 'react-i18next';
import {
  IContextMenuLink,
  IContextMenuModal,
} from 'src/components/ui-components/ContextMenu/ContextMenuType';
import { getFetch } from 'src/utils/fetcher';
import { formatUrl, getPathAndEntityId, replaceURLEntityID, urlCompare } from 'src/utils/url';
import { translationAnyText } from 'src/utils/translation';
import screenHeaderModalItem from 'src/constants/screenHeaderModalItem';

export const PAGES_DESIGN_SEVEN_URL_PATH = '/api/v2/pages/design-seven';
export const PAGES_DESIGN_SEVEN_MSW_STATUS_KEY = 'pagesDesignSevenAPI';

export const PAGES_DESIGN_SEVEN_KEY = 'PAGES_DESIGN_SEVEN';

const pageUrlAndPathNameCompare = (pageUrl: string, currentPathname: string): boolean => {
  const pagePath = formatUrl(pageUrl);
  const pageUrlRegex = /\/:\S+$/;
  const currentPathnameRegex = /\/\d+$/;
  if (pageUrlRegex.test(pagePath) && currentPathnameRegex.test(currentPathname)) {
    return pagePath.replace(/\/:\S+$/, '') === currentPathname.replace(/\/\d+$/, '');
  }
  return pagePath === currentPathname;
};

export const pageUrlAndPathNameCompareForTest = pageUrlAndPathNameCompare;

const fetchDesignSeven = (): Promise<IPagesDesignSevenAPI> =>
  getFetch({
    path: PAGES_DESIGN_SEVEN_URL_PATH,
    key: PAGES_DESIGN_SEVEN_MSW_STATUS_KEY,
  });

const useGetDesignSevenQuery = () =>
  useQuery([PAGES_DESIGN_SEVEN_KEY], fetchDesignSeven, { refetchOnMount: true });

export const useVerifyDesignSevenPage = (currentPageIdentifier: string) => {
  const { data, ...restProps } = useGetDesignSevenQuery();

  return {
    isDesignSeven: !!data?.entities?.find(
      ({ properties: { pageIdentifier = '' } = {} }) => currentPageIdentifier === pageIdentifier,
    ),
    ...restProps,
  };
};

const usePageActionMapping = (
  pageAction: Array<IPageActionLink | IPageActionModal>,
  entityId?: string,
): Array<IContextMenuLink | IContextMenuModal> => {
  const { t } = useTranslation('screenHeaderAction');
  return pageAction?.map((item) => {
    const { type, name, pageActionId } = item;
    const modalItem = screenHeaderModalItem[pageActionId];
    const defaultModalItem = screenHeaderModalItem.wrongId;
    const translatedName = translationAnyText(t, name);

    switch (type) {
      case 'Link':
        return {
          type: 'link',
          url: replaceURLEntityID(formatUrl(item.url), entityId),
          children: translatedName,
          key: `context-menu-link-${pageActionId}`,
          'data-automation-id': `ContextMenuLink${pageActionId}`,
        } as IContextMenuLink;
      case 'Modal':
        return {
          type: 'modal',
          size: 'large',
          headingText: translatedName,
          closeText: translationAnyText(t, 'close'),
          children: translatedName,
          key: `context-menu-modal-${pageActionId}`,
          'data-automation-id': `ContextMenuModal${pageActionId}`,
          ...defaultModalItem,
          ...modalItem,
        } as IContextMenuModal;
      default:
        return item;
    }
  });
};

export const usePageActionMappingForTest = usePageActionMapping;

export const useGetPageActions = (originPageUrl: string) => {
  const { data, ...restProps } = useGetDesignSevenQuery();
  const { entityId, urlPath } = getPathAndEntityId(originPageUrl);

  const result = data?.entities?.find(({ properties: { pageUrl = '' } = {} }) =>
    urlCompare({ currentUrl: pageUrl, originPath: urlPath }),
  );
  const pageAction = usePageActionMapping(result?.properties?.pageActions || [], entityId);

  return {
    pageAction,
    ...restProps,
  };
};

export const useGetDesignSevenPages = () => {
  const { data, ...restProps } = useGetDesignSevenQuery();

  return {
    designSevenPageList:
      data?.entities?.map(({ properties: { pageUrl = '', pageIdentifier = '' } = {} }) => ({
        pageUrl,
        pageIdentifier,
      })) || [],
    ...restProps,
  };
};
