import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { ITlpCountResponse } from 'src/apis/types/resourcePlannerAPI';

const TLP_EMPLOYEE_COUNT_URL_PATH = '/api/v2/resource-planner/tlp-active-employee-count';
export const TLP_EMPLOYEE_COUNT_MSW_STATUS_KEY = 'GetTlpEmployeeCount';

export const TLP_EMPLOYEE_COUNT_KEY = 'TLP_EMPLOYEE_COUNT';

const resourcePlannerFetch = (): Promise<ITlpCountResponse> =>
  getFetch({ path: TLP_EMPLOYEE_COUNT_URL_PATH, key: TLP_EMPLOYEE_COUNT_MSW_STATUS_KEY });

const useGetTlpEmployeeCountQuery = () =>
  useQuery([TLP_EMPLOYEE_COUNT_KEY], resourcePlannerFetch, { refetchOnMount: false });

export const useGetTlpEmployeeCount = () => {
  const { data, ...restProps } = useGetTlpEmployeeCountQuery();

  return {
    count: data?.properties.count ?? 0,
    ...restProps,
  };
};
