import { customerSegmentationObject } from './customerSegmentationObject';

export const userSettingsGenerator = (language: string) => ({
  properties: {
    language: {
      lcid: 2057,
      shortString: language,
    },
    displaySignUpButton: false,
    customerSegmentation: customerSegmentationObject,
  },
  links: [
    {
      href: 'http://localhost/tlp/api/v2/user-settings',
      rel: 'self',
    },
  ],
});
