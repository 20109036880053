export default {
  Properties: {
    TimeTrackerId: 62,
    UserId: 1,
    Date: '2021-07-15T00:00:00',
    StartTime: '2021-07-15T12:41:29.1566667',
    EntityType: 'Task',
    EntityId: 3,
    Task: {
      TaskId: 3,
      Id: 'fd1b7433-8e39-4378-9f11-589574ef5737',
      Name: "Workshop 3 (Employee 1's SuperTraining)",
      No: '4',
      IsFixedPrice: false,
      IsDefaultBillable: true,
      AdditionalTextIsRequired: false,
      ParentFullName: '',
      ProjectId: 1,
      Project: {
        ProjectId: 1,
        Id: '02624193-784c-4569-9cd1-50b764eee1a6',
        Name: 'Easy implementation of TimeLog Project',
        No: '0001',
        ExpenseIsBillable: true,
        CustomerId: 667,
        Customer: {
          CustomerId: 667,
          Id: 'eb566456-1532-e611-80e1-005056b220e2',
          Name: 'Your company',
          No: '99999999',
          DefaultMileageDistance: 0,
          CustomerStatusId: 166,
        },
      },
      ParentTaskId: null,
      IsTravelTimeTask: false,
    },
    AbsenceCode: null,
    TimeRegistrationId: null,
    TimeRegistration: null,
    Comment: '',
    Minute: 61,
    BillableMinute: 0,
    IsBillable: true,
    TimeTrackerStatus: 'ActiveTimeTracking',
    Id: 'c8460b41-3043-4c13-b3c8-d5028d231051',
    MonthlyPeriod: '',
    AdditionalTextField: '',
    Factor: 0,
  },
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/time-tracker/active?$expand=task,timeRegistration,absenceCode',
      Rel: 'self',
    },
  ],
  Actions: [
    {
      Name: 'stop',
      Title: 'Stop Time Tracker',
      Method: 'Post',
      Href: 'http://localhost/tlp/api/v2/time-tracker/stop/62',
      Type: 'multipart/form-data',
    },
  ],
};
