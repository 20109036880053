export default {
  Entities: [
    {
      Properties: {
        Name: 'ProjectPlanAmountNew',
        FeatureIdentifier: 'ProjectPlanAmountNew',
        FeatureStage: 'Beta',
        ReleaseNumber: '',
      },
    },
  ],
  Links: [{ Href: 'http://localhost/timelog/api/v2/user-preview-features', Rel: 'self' }],
};
