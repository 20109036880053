export const generateBookmark = ({ Url }: any) => {
  const id = Math.floor(Math.random() * 1000);
  return {
    Properties: {
      Id: id,
      Name: 'Submit/print personal expenses',
      Url,
    },
    Actions: [
      {
        Name: 'create',
        Title: 'Create bookmark',
        Method: 'Post',
        Href: 'http://localhost/timelog/api/v2/bookmarks',
        Type: 'application/json',
      },
      {
        Name: 'delete',
        Title: 'Delete bookmark',
        Method: 'Delete',
        Href: `http://localhost/timelog/api/v2/bookmarks/${id}`,
        Type: 'application/json',
      },
    ],
  };
};
