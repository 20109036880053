export const RPProjectViewInitialExpandedRowsStateKey =
  'resource-planner-project-view-row-expand-state';

export const RPEmployeeViewInitialExpandedRowsStateKey =
  'resource-planner-employee-view-row-expand-state';

export const RPViewOptionsStateKey = 'resource-planner-view-options';

export const RPSlectedFilterListStateKey = 'resource-planner-selected-filter-list';

export const RPinvalidateOldQueryOnViewOptionChange = 'resource-planner-invalidate-old-query';
